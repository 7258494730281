<div *ngIf="control" class="mb-3">
    <div class="form-group input-group row mb-0">
      <label [class.text-danger]="control.invalid&&(control.dirty||control.touched)" [for]="idName"
            class="col-xl-3 col-md-4 mx-1"><span *ngIf="controlIsRequired(control)" class="text-danger">*</span>
            {{labelPlaceholder | translate| titlecase}}
            <a *ngIf="validators && prepareTooltips(validators).length > 0" [ngbTooltip]="titleTemplate"
                placement="top">
                <i class="ft-alert-circle"></i>
            </a>

            <ng-template #titleTemplate>
                <ng-container *ngFor="let toolTip of prepareTooltips(validators)">
                    <span>{{ toolTip| translate }}</span><br>
                </ng-container>
            </ng-template>

        </label>
      <!--  [ngClass]="ngControl?.valid ? 'ng-valid' : 'ng-invalid'"-->
      <input
        (blur)="inputBlur()"
        (change)="onInputChange($event)"
        (click)="openPopOver(popover)"
        (focus)="openPopOver(popover)"
        [disabled]="true"
        [ngModel]="dateString | date:inputDatetimeFormat"
        class="form-control col-xl-8 col-md-7"
        style="height: 38px; border-bottom-left-radius: 8px;border-top-left-radius: 8px;"
        [id]="idName"
      />
  
      <div class="input-group-append">
        <button
          #popover="ngbPopover"
          [disabled]="disabled"
          [ngbPopover]="calendarContent"
          class="btn btn-outline-secondary"
          type="button"
          [id]="idName"
        >
          <i class="ft-calendar"></i>
        </button>
      </div>
    </div>
  </div>
  
  <ng-template #calendarContent>
    <div>
      <div *ngIf="!showTimePickerToggle">
        <ngb-datepicker #dp (ngModelChange)="onDateChange($event)"
                        [minDate]="getMinDate()"
                        [ngModel]="datetime"
                        id="dp"
                        name="datepicker"></ngb-datepicker>
        <button
          (click)="toggleDateTimeState($event)"
          class="btn btn-block btn-outline-secondary"
          type="button"
          id="toggle-picker"
        >
          <i class="ft-clock"></i>
        </button>
      </div>
      <div *ngIf="showTimePickerToggle">
        <button
          (click)="toggleDateTimeState($event)"
          [ngbPopover]="calendarContent"
          class="btn btn-block btn-outline-secondary"
          type="button"
          id="togglePicker"
        >
          <i class="ft-calendar"></i>
        </button>
        <div class="mt-auto">
          <ngb-timepicker #tp (ngModelChange)="onTimeChange($event)" [hourStep]="hourStep" [minuteStep]="minuteStep"
                          [ngModel]="datetime" [secondStep]="secondStep" [seconds]="seconds" name="timepicker">
          </ngb-timepicker>
        </div>
      </div>
    </div>
  </ng-template>
  