<section class="cd-vertical-timeline" style="padding-top: 30px;padding-bottom: 30px;" [ngClass]="{'loaded': loaded}">
  <div class="timeline">
    <div class="events-wrapper">
      <div class="events" #eventsWrapper [style.height.px]="eventsWrapperHeight">
        <ol>
          <li *ngFor="let item of timelineElements; let index = index">
            <a #timelineEvents>
              <br>
              <strong>{{item.date|date:dateFormatTop}}: {{item.line}}</strong>&ensp;{{item.route}}

            </a>
            <span></span>
          </li>
        </ol>
      </div>
    </div>
    <ul class="cd-timeline-navigation h-100">
      <li>
        <a href="#" (click)="onScrollClick($event, false)" class="prev"
          [ngClass]="{'inactive':prevLinkInactive}">Prev</a>
      </li>
      <li>
        <a href="#" (click)="onScrollClick($event, true)" class="next"
          [ngClass]="{'inactive':nextLinkInactive}">Next</a>
      </li>
    </ul>
  </div>

</section>