<div ngbDropdown #myDrop1="ngbDropdown">
 <div class="d-flex" id="dropdownBasic1" ngbDropdownToggle (click)="openCalendar()"
 style="cursor: pointer;">
 <div class="flex-shrink-0 mx-2">
     <i class="ft-calendar ft-calendar font-large-2"></i>
 </div>
 <div class="flex-grow-1 ms-3">
     <h6 class="font-meduim-3">Selected Date</h6>
     <p class="font-small-2">{{ formatDate(pickedDate) }}</p>
 </div>
</div>
 <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
    <div *ngIf="showCalendar">
      <ngb-datepicker #dp1 ngModel class="bg-white border" (ngModelChange)="onDateChange($event)"
      [displayMonths]="1" [dayTemplate]="t"  [markDisabled]="isDisabled">
      </ngb-datepicker>
  
      <ng-template #t let-date="date" let-focused="focused">
        <span class="custom-day" [class.focused]="focused" [class.range]="isFrom(date) || isTo(date) ||  isHovered(date)"
          [class.faded]="isHovered(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
          {{ date.day }}
        </span>
      </ng-template>
    </div>
 </div>
</div>
  