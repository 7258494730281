<div class="modal-header">
  <h4 class="modal-title">{{ "Add_Rental" | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-content ">
  <form [formGroup]="rentForm" (ngSubmit)="saveForm()">
    <div class="mt2">
      <div class="bg-light-secondary px-2 w-100 text-center">
        <p>{{"General_Information"| translate}}</p>
      </div>
    </div>
    <div class="bg-grey bg-lighten-4 rounded p-3 mb-3 mt-3">
      <!-- Client -->
      <div class="row">
        <div class="col-8">
          <div class="row mb-1">
            <div class="col-3">
              <label for="client" class="col-form-label text-bold-600">{{ "Client" | translate }}</label>
            </div>
            <div class="col-5">
              <input type="text" id="client" class="form-control" formControlName="client"
                [ngClass]="{ 'is-invalid': submitted && r.client.invalid, 'is-valid': submitted && !r.client.invalid }" />
              <div *ngIf="submitted && r.client.errors?.required" class="invalid-feedback">
                {{ "Client" | translate }} {{ "REQUIRED" | translate }}
              </div>
            </div>
          </div>
     
          <div class="row mb-1">
            <div class="col-3">
              <label for="rentDate" class="col-form-label text-bold-600">{{ "Date_Time" | translate }}</label>
            </div>
            <div class="col-9">
              <div class="row">
                <!-- Date Picker -->
                <div class="col-md-6">
                  <div class="input-group">
                    <input class="form-control" id="rentDate" formControlName="rentDate" placeholder="yyyy-mm-dd"
                      ngbDatepicker #d="ngbDatepicker" (dateSelect)="onDateSelect($event)"
                      [ngClass]="{ 'is-invalid': submitted && rentForm.get('rentDate').invalid, 'is-valid': submitted && !rentForm.get('rentDate').invalid }">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                  </div>
                  <div *ngIf="submitted && rentForm.get('rentDate').errors?.required" class="invalid-feedback">
                    {{ "DATE_TIME" | translate }} {{ "REQUIRED" | translate }}
                  </div>
                </div>
          
                <!-- Time Picker -->
                <div class="col-md-6">
                  <ngb-timepicker [spinners]="false" formControlName="rentTime" class="mt-0"
                    [ngClass]="{ 'is-invalid': submitted && rentForm.get('rentTime').invalid, 'is-valid': submitted && !rentForm.get('rentTime').invalid }">
                  </ngb-timepicker>
                  <div *ngIf="submitted && rentForm.get('rentTime').errors?.required" class="invalid-feedback">
                    {{ "TIME_REQUIRED" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- Number of Passengers -->
          <div class="row mb-1">
            <div class="col-3">
              <label for="nbrOfPassenger" class="col-form-label text-bold-600">{{ "Passenger_Nbr" | translate }}</label>
            </div>
            <div class="col-5">
              <input type="number" id="nbrOfPassenger" class="form-control" formControlName="nbrOfPassenger"
                [ngClass]="{ 'is-invalid': submitted && r.nbrOfPassenger.invalid, 'is-valid': submitted && !r.nbrOfPassenger.invalid }" />
              <div *ngIf="submitted && r.nbrOfPassenger.errors?.required" class="invalid-feedback">
                {{ "Passenger_Nbr" | translate }} {{ "REQUIRED" | translate }}
              </div>
            </div>
          </div>
          <!-- Number of Buses -->
          <div class="row">
            <div class="col-3">
              <label for="nbrOfBus" class="col-form-label text-bold-600">{{ "Bus_Nbr" | translate }}</label>
            </div>
            <div class="col-5">
              <input type="number" id="nbrOfBus" class="form-control" formControlName="nbrOfBus"
                [ngClass]="{ 'is-invalid': submitted && r.nbrOfBus.invalid, 'is-valid': submitted && !r.nbrOfBus.invalid }" />
              <div *ngIf="submitted && r.nbrOfBus.errors?.required" class="invalid-feedback">
                {{ "Bus_Nbr" | translate }} {{ "REQUIRED" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="ml-5 col-3">
          <!-- Total Distance and Duration -->
          <div class="row mt-4">
            <span class=" col-6 badge bg-info bg-darken-1 text-bold-600 p-2 mb-4">
              {{getTotalDistanceAllBlocks()}} km
            </span>
          </div>
          <div class="row">
            <span class=" col-6 badge bg-success bg-darken-1 text-bold-600 p-2">
              {{getTotalDurationAllBlocksInHoursAndMinutes()}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="mt2">
      <div class="px-2 w-50 bg-light-secondary px-2 w-100 text-center">
        <p>{{"Trips"| translate}}</p>
      </div>
    </div>
    <!-- Blocks (Itineraries) -->
    <ng-container formArrayName="blocks">
      <ng-container *ngFor="let block of blocksFormArray.controls; let blockIndex = index" [formGroupName]="blockIndex">
        <div class="card border-info border-1">
          <div class="card-header">
            <div class="card-title">
              {{"Trip"| translate}} {{blockIndex + 1}}
            </div> 
            <div class="row">
              <div class="col-3">
                <div class="input-group">
                  <input class="form-control bg-white" [formControlName]="'tripDate'" placeholder="yyyy-mm-dd"
                    ngbDatepicker #tripD="ngbDatepicker" (dateSelect)="updateTripDateTime(blockIndex)"
                    [ngClass]="{ 'is-invalid': submitted && getBlockFormGroup(blockIndex).get('tripDate').invalid, 
                                 'is-valid': submitted && !getBlockFormGroup(blockIndex).get('tripDate').invalid }"
                    [minDate]="rentForm.get('rentDate').value"
                    [dateFilter]="blockIndex === 0 ? isFirstBlockDateValid : null">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="tripD.toggle()" type="button">
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </div>
              
              <div class="col-2">
                <ngb-timepicker [spinners]="false" [formControlName]="'tripTime'" class="mt-2"
                  (ngModelChange)="updateTripDateTime(blockIndex)"
                  [timeValidator]="blockIndex === 0 ? isFirstBlockTimeValid : null"></ngb-timepicker>
              </div>
          
            

              
         
              <div class="col-2">
                <input type="number" id="nbrOfNights" class="form-control bg-white"
                  [formControlName]="'nbrOfNights'" [ngClass]="{ 'is-invalid': submitted && getBlockFormGroup(blockIndex).get('nbrOfNights').invalid, 
            'is-valid': submitted && !getBlockFormGroup(blockIndex).get('nbrOfNights').invalid }"
                  [attr.placeholder]="'Nbr_Of_Nights' | translate" />
                <div *ngIf="submitted && getBlockFormGroup(blockIndex).get('nbrOfNights').errors?.required"
                  class="invalid-feedback">
                  {{ "Nbr_Of_Nights" | translate }} {{ "REQUIRED" | translate }}
                </div>
              </div>

              

              <div class="col-1 mb-3 badge bg-info bg-darken-1 text-bold-600 p-2 mr-3">
                <span class="">
                  {{ getTotalDistance(blockIndex) }} km
                </span>
              </div>
              <div class="col-1 mb-3 badge bg-success bg-darken-1 text-bold-600 p-2 mr-3">
                <span class="">
                  {{ getTotalDurationInHoursAndMinutes(blockIndex) }}
                </span>
              </div>
              <div class="col-1 mb-3 p-2 badge bg-primary">
                <span class="mr-1">{{"Empty"| translate}}</span>
                <input type="checkbox" class="bg-white" formControlName="empty" />
              </div>
           
            </div>


          </div>
          <div class="card-content">
           

                <div class="bg-grey bg-lighten-4 rounded p-3 mb-3 mt-4">
                  <div class="row">
                    <span class="col-1 text-bold-600 ml-3"># {{ 'Order' | translate }}</span>
                    <span class="col-2 text-bold-600 ml-5">{{ 'Station' | translate }}</span>
                    <span class="col-2 text-bold-600 mr-2">{{ 'Distance(prec)' | translate }}</span>
                    <span class="col-2 text-bold-600 mr-2">{{ 'Duration' | translate }}</span>
                    <span class="col-2 text-bold-600 mr-2">{{ 'Stopping_Duration' | translate }}</span>
                    <span class="col-1 text-bold-600 ml-3">{{ 'Actions' | translate }}</span>
                  </div>
                </div>

                <section id="dragndrop">
                  <section id="spill-lists">
                    <div class="row">
                      <div class="col-12">
                        <div class="card-content">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-12 col-12 mb-3 mb-md-0">
                                <ul class="list-group" [dragula]="'DRAGULA_FACTS_' + blockIndex"
                                  [dragulaModel]="blocks[blockIndex].rents">
                                  <li class="list-group-item draggable"
                                    *ngFor="let item of blocks[blockIndex].rents; let i = index">
                                    <div class="media">
                                      <div class="media-body">
                                        <div class="row">
                                          <span class="mt-0 ml-3 col-1">{{ item.order }}</span>
                                          <span class="col-3">
                                            <div
                                              *ngIf="blocks[blockIndex].showSelectList && !item.name && i === blocks[blockIndex].rents.length - 1">
                                              <select class="form-control"
                                                (change)="addStation($event.target.value, blockIndex)">
                                                <option value="">none</option>
                                                <option *ngFor="let station of stations" [value]="station.name">{{
                                                  station.name }}</option>
                                              </select>
                                            </div>
                                            <div *ngIf="item.name">
                                              {{ item.name }}
                                            </div>
                                            <span *ngIf="!item.name" class="mt-2 text-center cursor-pointer"
                                              (click)="AddNewStation()"><i
                                                class="fa-solid fa-plus mr-1"></i>{{"Add_Station"|translate}}</span>
                                          </span>
                                          <span class="col-2"> {{
                                            getItineraryStations(blockIndex).at(i)?.get('distance')?.value }} km</span>
                                          <span class="col-2">{{
                                            getItineraryStations(blockIndex).at(i)?.get('duration')?.value }} min </span>
                                          <span class="col-2">



                                            <input type="number" id="nbrOfNights" class="form-control "
                                              [formControl]="getItineraryStations(blockIndex).at(i).get('stoppingTime')"
                                              placeholder="Durée de stationnement (min)"
                                              [ngClass]="{ 'is-invalid': submitted && getItineraryStations(blockIndex).at(i).get('stoppingTime').invalid, 
                                                          'is-valid': submitted && !getItineraryStations(blockIndex).at(i).get('stoppingTime').invalid }" />
                                            <div
                                              *ngIf="submitted && getItineraryStations(blockIndex).at(i).get('stoppingTime').errors?.required"
                                              class="invalid-feedback">
                                              {{ "Stopping_Duration" | translate }} {{ "REQUIRED" | translate }}<span>min</span>
                                            </div>
                                          </span>
                                          <span class="col-1 mt-1 ml-5">
                                            <a class="danger p-0" (click)="removeStation(i, blockIndex)">
                                              <i class="ft-x font-medium-3"></i>
                                            </a>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </section>

               

                <div class="px-2 py-1 d-flex align-content-center flex-wrap">
                  <button type="button" class="btn bg-light-secondary px-2 w-100"
                    (click)="toggleSelectList(blockIndex)">
                    <em class="ft-plus-circle mx-1"></em>{{ 'Add_Station' | translate }}
                  </button>
                </div>

                <div class="mr-2 ml-2" *ngIf="submitted && getItineraryStations(blockIndex).length < 2">
                  <div class="text-danger">
                    <p>{{"TWO_STATIONS_REQUIRED"| translate}}</p>
                  </div>
                </div>

                <div class="mb-3 p-1" style="height: 400px;">
                  <app-itineray-map (routeCalculated)="onRouteCalculated($event, blockIndex)"></app-itineray-map>
                </div>
             

              <div *ngIf="blockIndex > 0" class="px-2 py-1 d-flex align-content-center flex-wrap">
                <button type="button" class="btn btn-danger px-2 w-100" (click)="removeBlock(blockIndex)">
                  <em class="ft-minus-circle mx-1"></em>{{ 'Remove_Itinerary' | translate }}
                </button>
              </div>


            <!-- </div> -->
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="px-2 py-1 d-flex align-content-center flex-wrap">
      <button type="button" class="btn bg-info text-white px-2 w-100" (click)="addBlock()">
        <em class="ft-plus-circle mx-1"></em>{{ 'Add_Trip' | translate }}
      </button>
    </div>

    <div class="modal-footer row col-md-12 d-flex justify-content-end">
      <button type="submit" class="btn btn-info">{{"SAVE" | translate}}</button>
      <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">{{ "Annuler" | translate
        }}</button>
    </div>
  </form>
</div>