import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
// @ts-ignore
import L from 'leaflet';
import {HttpClient} from '@angular/common/http';
import {RealTimeTrackingService} from '../services/real-time-tracking.service';
import {RealTimeData} from '../vehicles-list-tracking/vehicles-list-tracking.component';
import {SaeService} from '../services/sae.service';
import {th} from 'date-fns/locale';
import { Vehicle } from 'app/modules/maintenance-management/work-request/models/work-request';
import { OPN_BASE_URL } from 'app/shared/global/var';
import { CrudService } from 'app/shared/services/crud.service';

@Component({
    selector: 'app-real-time-map',
    templateUrl: './real-time-map.component.html',
    styleUrls: ['./real-time-map.component.scss']
})
export class RealTimeMapComponent implements OnInit, OnDestroy {
    private map = L.map;
    private markers: any[] = [];
    private vehicleMarker: any[] = [];
    private routeLine: any;
    routeLines: L.Polyline[] = []; // Array to store all route lines
    osrmRouteLines: L.Polyline[] = []; // Array to store all route lines
    lastLocation: { lat: number; lon: number };
    vehiclesCoordinates: any[] = [];

    @Input() stations: any[] = [];
    @Input() idBoitier: string;
    @Input() isHistoric = false;
    @Input() startDate: string;
    @Input() endDate: string;
    @Input() vehicle: Vehicle;
    @Input() isTracking = false;
    @Input() trip: any;
    @Input() itinerary: any;
    @Input() dateDepart: string;
    @Input() dateArrivee: string;


    constructor(
        private _elementRef: ElementRef,
        private http: HttpClient,
       // private realTimeTrackingService: RealTimeTrackingService,
        private saeService: SaeService,
        private crudService: CrudService
    ) {
    }

    ngOnInit(): void {
        this.initMap(); // Initialize the map
        this.markers = []; // Initialize the markers array
        if (this.stations && this.stations.length > 0) {
            this.stations.sort((a, b) => a.stationOrder - b.stationOrder);
            for (const station of this.stations) {
                this.addMarkers([station.station.lat, station.station.lon], station.station.name);
            }
            this.map.fitBounds(this.markers.map(marker => marker.getLatLng()));
            this.map.setView(this.markers[0].getLatLng(), 12);
            this.getShape();
        }
        if (this.trip) {
            this.vehicle = this.trip.bus;
            if (this.trip.status === 3) {
                this.dateDepart = this.trip.rtDeparture ? this.trip.rtDeparture : this.trip.plannedDeparture;
                this.dateDepart = this.trip.rtDeparture ? this.trip.rtDeparture : this.trip.plannedDeparture;
                this.dateArrivee = this.trip.rtArrival ? this.trip.rtArrival : this.trip.estimatedArrival;
                this.getArchiveData();
                this.getUpdatedData(this.trip.id);
            } else if (this.trip.status !== 1 && this.trip.status !== 4) {
                if (this.dateDepart && this.dateArrivee && this.vehicle) {
                    this.getArchiveData();
                } else {
                    this.dateDepart = this.trip.rtDeparture ? this.trip.rtDeparture : this.trip.plannedDeparture;
                    this.dateArrivee = this.trip.rtArrival ? this.trip.rtArrival : this.trip.estimatedArrival;
                    this.getArchiveData();
                }
            }
        } else {
            if (this.dateDepart && this.dateArrivee && this.vehicle) {
                this.getArchiveData();
            } else {
                this.dateDepart = this.trip.rtDeparture ? this.trip.rtDeparture : this.trip.plannedDeparture;
                this.dateArrivee = this.trip.rtArrival ? this.trip.rtArrival : this.trip.estimatedArrival;
                this.getArchiveData();
            }
        }
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * Clears the map by removing all markers and the route line.
     */
    ngOnDestroy(): void {
        this.markers.forEach((marker) => this.map.removeLayer(marker));
        this.routeLines.forEach((line) => this.map.removeLayer(line));
        this.osrmRouteLines.forEach((line) => this.map.removeLayer(line));
        this.clearMap();
       // this.saeService.closeConnection();
    }

    /**
     * Initializes the map and sets its view to a default center and zoom level.
     * Adds a tile layer to the map and draws the route using OSRM.
     */
    initMap(): void {
        this.clearMap();
        const el = this._elementRef.nativeElement.querySelector('.tracking-map');
        this.map = L.map(el, {
            center: [34.551117, 9.369019],
            zoom: 10,
        });
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(this.map);

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 300);

    }

    /**
     * Adds a marker to the map at the specified latitude and longitude.
     * The marker is customized with a bus stop icon and a popup displaying the station name.
     *
     * @param {any} latlng - The latitude and longitude of the marker.
     * @param {string} stationName - The name of the station to display in the popup.
     */
    addMarkers(latlng: any, stationName: string): void {
        const customIcon = L.icon({
            iconUrl: './assets/img/leaflet/bus-stop.png',
            iconSize: [25, 41],
            text: stationName
        });
        const marker = L.marker(latlng, {icon: customIcon}).addTo(this.map);
        // Add a popup to the marker and open it
        marker.bindPopup(stationName);
        // ON click on the marker, open the popup
        marker.on('click', () => {
            marker.openPopup();
        });
        this.markers.push(marker);
        // zoom the map to the marker
    }

    /**
     * Draws a route on the map using the OSRM API.
     * Fetches the route data from OSRM and draws a polyline on the map.
     * Adjusts the map bounds to fit the route.
     *
     * @param {Object[]} stations - An array of station objects with latitude and longitude properties.
     * @param color
     * @returns {Promise<void>} A promise that resolves when the route is drawn.
     */
    async drawRouteOSRM(stations: { lat: number; lon: number }[], color = '#A9A9A9'): Promise<void> {
        if (stations.length < 2) {
            return;
        }

        const coordinates = stations
            .map((station) => `${station.lon},${station.lat}`)
            .join(';');
        const url = `https://router.project-osrm.org/route/v1/driving/${coordinates}?overview=full&geometries=geojson`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.code !== 'Ok') {
                console.error('OSRM request failed:', data);
                return;
            }
            const route = data.routes[0];

            const routeCoordinates = route.geometry.coordinates.map((coord: any[]) => [
                coord[1],
                coord[0],
            ]);

            const newRouteLine = L.polyline(routeCoordinates, {
                color: color,
                weight: 5,
            }).addTo(this.map);
            this.osrmRouteLines.push(newRouteLine);
            // Fit the map bounds to the route line
            const group = new L.featureGroup(this.osrmRouteLines);
            this.map.fitBounds(group.getBounds());
        } catch (error) {
            console.error('Error fetching OSRM route:', error);
        }
    }

    /**
     * Clears the map by removing all markers and the route line.
     * Resets the markers array and sets the route line to null.
     */
    clearMap(): void {
        this.markers.forEach((marker) => this.map.removeLayer(marker));
        this.markers = [];
        if (this.routeLine) {
            this.map.removeLayer(this.routeLine);
            this.routeLine = null;
        }
    }

    /**
     * Fetches archived data for a specific vehicle within a date range.
     * Converts the departure and arrival dates to ISO strings and makes an HTTP GET request to retrieve the data.
     * Draws the route on the map, adds markers for the last location and trip start, and fetches updated data.
     */
    getArchiveData(): void {
        this.dateDepart = new Date(this.dateDepart).toISOString().slice(0, 19).replace('T', ' ');
        this.dateArrivee = new Date(this.dateArrivee).toISOString().slice(0, 19).replace('T', ' ');
        this.http.get(OPN_BASE_URL + `/archive2/${this.vehicle.idBoitier}/dataRange`, {
            params: {
                'startDate': this.dateDepart,
                'endDate': this.dateArrivee
            }
        }).subscribe((res: any) => {
            if (res.length > 2) {
                // Draw the route
                for (let i = 0; i < res.length - 1; i++) {  // Fix: Stop before the last element
                    const routeLine = L.polyline([[res[i].lat, res[i].lon], [res[i + 1].lat, res[i + 1].lon]], {
                        color: '#1e90ff',
                        weight: 5,
                    }).addTo(this.map);
                    this.routeLines.push(routeLine);
                }

                // Add the last location marker
                this.lastLocation = {lat: res[res.length - 1].lat, lon: res[res.length - 1].lon};
                this.addVehiclesMarkers([this.lastLocation.lat, this.lastLocation.lon], this.vehicle);

                // Add the trip start marker
                this.addTripStartMarker([res[0].lat, res[0].lon]);
                this.map.setView(this.lastLocation, 15);

                // Fit bounds to the whole route
                this.map.fitBounds([this.lastLocation, [res[0].lat, res[0].lon]]);


                // Fetch updated data
              //  this.getUpdatedData(this.vehicle.idBoitier);
            } else {
                // add the last location marker
                this.lastLocation = {lat: res[0].lat, lon: res[0].lon};
                this.addVehiclesMarkers([this.lastLocation.lat, this.lastLocation.lon], this.vehicle);
            }
        });
    }

    /**
     * Adds a marker for a vehicle to the map.
     * The marker is customized with an icon based on the vehicle type (bus or car).
     *
     * @param {any} latlng - The latitude and longitude of the marker.
     * @param {Vehicle} vehicle - The vehicle object containing details for the marker.
     */
    addVehiclesMarkers(latlng: any, vehicle: Vehicle): void {
        const customIcon = L.icon({
            iconUrl: vehicle.family.bus ? './assets/img/markers/bus.png' : './assets/img/markers/car.png',
            iconSize: [25, 41],
            text: vehicle.vehicleNumber
        });
        const marker = L.marker(latlng, {icon: customIcon}).addTo(this.map);
        this.vehicleMarker.push(marker);
        this.vehicleMarker.push(marker);
        this.map.fitBounds([latlng]);
    }

    /**
     * Adds a marker to the map indicating the start of a trip.
     * The marker is customized with a "go" icon.
     *
     * @param {any} latlng - The latitude and longitude of the marker.
     */
    addTripStartMarker(latlng: any): void {
        const customIcon = L.icon({
            iconUrl: './assets/img/markers/go.png',
            iconSize: [25, 25],
        });
        const marker = L.marker(latlng, {icon: customIcon}).addTo(this.map);
        this.markers.push(marker);
    }

    /**
     * Connects to the real-time tracking service and subscribes to messages.
     * Handles incoming messages by updating the vehicle marker on the map.
     *
     * @param {number} idTrip - The ID of the trip to track in real-time.
     */
    getUpdatedData(idTrip: number): void {
        this.saeService.connect(idTrip);
        this.saeService.message$.subscribe((message) => {
            this.handleMessage(message);
        });
    }

    /**
     * Handles incoming real-time tracking messages.
     * Updates the vehicle marker on the map if the message is for the current vehicle.
     *
     * @param {any} message - The incoming message containing real-time tracking data.
     */
    handleMessage(message: any): void {
        if (this.trip.id === parseInt(message.id, 10)) {
            this.updateVehicleMarker(this.vehicle, message);
        }
    }

    /**
     * Updates the vehicle marker on the map with new real-time data.
     * Removes the previous marker, creates a new marker with the updated location, and draws the route.
     *
     * @param {Vehicle} vehicle - The vehicle object containing details for the marker.
     * @param {RealTimeData} rtData - The real-time data containing the new location.
     */
    updateVehicleMarker(vehicle: Vehicle, rtData: RealTimeData): void {
        const lat = rtData.lat;
        const lon = rtData.lon;
        const newLatLng = [lat, lon];

        // Remove all previous vehicle markers
        if (this.vehicleMarker.length > 0) {
            this.vehicleMarker.forEach((m) => this.map.removeLayer(m));
        }
        // Create a custom icon for the vehicle
        const customIcon = L.icon({
            iconUrl: vehicle.family.bus ? './assets/img/markers/bus.png' : './assets/img/markers/car.png',
            iconSize: [25, 41], // Size of the icon
            iconAnchor: [12, 41], // Anchor point of the icon
            popupAnchor: [0, -41] // Popup anchor point
        });

        // Create a new marker with the custom icon
        const marker = L.marker(newLatLng, {icon: customIcon}).addTo(this.map);

        // Update the markers array
        if (this.vehicleMarker.length > 0) {
            this.vehicleMarker[this.vehicleMarker.length - 1].remove();
        }
        this.vehicleMarker = [marker]; // Reset the markers array with the new marker

        // Set the map view to the new marker location
         this.map.setView(newLatLng, 15);

        // Draw the route if there are previous locations
        this.vehiclesCoordinates.push(newLatLng);
        if (this.lastLocation) {
            // Draw the route from stored vehicle coordinates to the new location
            const color = rtData.isAlert ? 'red' : '#1e90ff';
            const routeLine = L.polyline([this.lastLocation, newLatLng],
                {color: color,
                weight: 5}).addTo(this.map);
            this.routeLines.push(routeLine);
            // this.map.fitBounds(routeLine.getBounds());
        }

        // Update the last location
        this.lastLocation = {lat, lon};
    }


    getShape(): void {
        this.crudService.getAll(OPN_BASE_URL + `/shape/all/${this.itinerary.id}`).subscribe((res: any) => {
            const shape = res;
            const coordinates = shape.map((coord: any) => {
                return [coord.lon, coord.lat]; // Changed to an array [lat, lon]
            });

            const newRouteLine = L.polyline(coordinates, {
                color: '#A9A9A9',
                weight: 5,
            }).addTo(this.map);
            this.osrmRouteLines.push(newRouteLine);
            // Fit the map bounds to the route line
            const group = new L.featureGroup(this.osrmRouteLines);
        });
    }





}
