import {Component, ViewContainerRef, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { Subscription } from 'rxjs';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { filter } from 'rxjs/operators';
import {CrudService} from './shared/services/crud.service';
import {OPN_BASE_URL} from './shared/global/var';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

    subscription: Subscription;
    profileConfig: any;
    acronym: string;
    logoUrl: string;

    constructor(private router: Router,
                private crudService: CrudService,
                private titleService: Title) {
    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                window.scrollTo(0, 0);
                this.getCompanySettings();
            });

        // Initial load
       // this.getCompanySettings();
    }

    ngAfterViewInit() {
        // This will be called after the view is initialized
        this.getCompanySettings();
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getCompanySettings() {
        this.crudService.getAll<any>(OPN_BASE_URL + '/company-settings/all').subscribe(async (res: any) => {
            const settingsMap = res.map((item: any) => ({
                configKey: item.configKey,
                configValue: JSON.parse(item.configValue)
            }));

            this.profileConfig = settingsMap.find(item => item.configKey === 'profile-config');
            this.acronym = this.profileConfig.configValue.acronym ? this.profileConfig.configValue.acronym : '';
            this.logoUrl = this.profileConfig.configValue.logo;
            if (!this.logoUrl || this.logoUrl === '') {
                this.logoUrl = '/assets/img/bus-svgrepo-com.png';
            }

            this.updateFavicon(this.logoUrl);
            this.updateTitle(this.router.url.split('?')[0]);
        });
    }

    updateFavicon(logoUrl: string) {
        const favicon: HTMLLinkElement | null = document.querySelector('#app-favicon');
        if (favicon) {
            favicon.href = logoUrl;
        }
    }

    updateTitle(currentPageTitle: string) {
        // remove first slash
        currentPageTitle = currentPageTitle.replace('/', '');
        // replace all - with space
        currentPageTitle = currentPageTitle.replace(/-/g, ' ');
        // capitalize first letter of each word
        currentPageTitle = currentPageTitle.replace(/\b\w/g, l => l.toUpperCase());
        const title = `${currentPageTitle} - ${this.acronym}`;
        this.titleService.setTitle(title);
    }



}
