<div class="modal-header">
  <h4 class="modal-title">{{ getCardTitle() | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-content">
  <form [formGroup]="itineraryForm" (ngSubmit)="saveForm()">
    <div class="bg-grey bg-lighten-4 rounded p-3 mb-3 mt-3">
      <div class="row">
        <div class="col-md-2">
          <label for="validationTooltip01" class="col-form-label text-bold-600">{{ "Itinerary_Name" | translate
            }}</label>
        </div>
        <div class="col-3">
          <input type="text" id="validationTooltip01" class="form-control" formControlName="itineraryLib"
            [ngClass]="{ 'is-invalid': submitted && i.itineraryLib.invalid, 'is-valid': submitted && !i.itineraryLib.invalid }" />
          <div *ngIf="submitted && i.itineraryLib.errors?.required" class="invalid-feedback">
            {{ "Itinerary_Lib" | translate }} {{ "REQUIRED" | translate }}
          </div>
        </div>
        <span class="col-2 badge bg-info bg-darken-1 text-bold-600 p-2 mr-1">
          {{ totalDistance }}km
        </span>
        <span class="col-2 badge bg-success bg-darken-1 text-bold-600 p-2 mr-1">
          {{ totalDuration }} min
        </span>

        <div class="ml-5 col-2">
          <div class="controls d-flex align-items-center">
            <label class="switch mr-2">
              <input class="form-check-input" type="checkbox" id="directionToogle" formControlName="direction"
                (change)="onDirectionChange($event.target.checked)">
              <span class="slider round"></span>
            </label>
            <label class="form-check-label" for="directionCheckbox">
              <ng-container *ngIf="itineraryForm.get('direction').value === 1; else inactiveLabel">
                {{ "Aller" | translate }}
              </ng-container>
              <ng-template #inactiveLabel>
                {{ "Retour" | translate }}
              </ng-template>
            </label>

          </div>
        </div>
      </div>
      <div class="mt-2 checkbox-position row">
        <input class="ml-2 col-auto" type="checkbox" id="backItinerary" formControlName="generateReturnItinerary">
        <span class="ml-2">{{"Generate_Return_Itinerary" | translate}}</span>
      </div>
    </div>

    <div class="bg-grey bg-lighten-4 rounded p-3 mb-3 mt-4">
      <div class="row">
        <span class="col-1 text-bold-600 ml-5"># {{ 'Order' | translate }}</span>
        <span class="col-2 text-bold-600">{{ 'Station' | translate }}</span>
        <span class="col-2 text-bold-600 mr-2">{{ 'Distance(prec)' | translate }}</span>
        <span class="col-2 text-bold-600 mr-2">{{ 'Duration' | translate }}</span>
        <span class="col-2 text-bold-600 mr-2">{{ 'Stopping_Duration' | translate }}</span>
        <span class="col-1 text-bold-600">{{ 'Actions' | translate }}</span>
      </div>
    </div>

    <section id="dragndrop">
      <section id="spill-lists">
        <div class="row">
          <div class="col-12">
            <div class="card-content">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 col-12 mb-3 mb-md-0">
                    <ul class="list-group" id="multiple-list-group-a" [dragula]="'DRAGULA_FACTS'"
                      [(dragulaModel)]="group1">
                      <li class="list-group-item draggable" *ngFor="let item of group1; let i = index">
                        <div class="media">
                          <div class="media-body">
                            <div class="row">
                              <span class="mt-0 ml-5 col-1">{{ item.order }}</span>
                              <span class="col-3">
                                <div *ngIf="showSelectList && !item.name && i === group1.length - 1">
                                  <select class="form-control" (change)="addStation($event.target.value)">
                                    <option value="">none</option>
                                    <option *ngFor="let station of stations" [value]="station.name">{{ station.name }}
                                    </option>
                                  </select>
                                </div>
                                <div *ngIf="item.name">
                                  {{ item.name }}
                                </div>
                              </span>
                              <span class="col-2">{{ itineraryStations.at(i).get('distance').value }} km</span>
                              <span class="col-2">{{ itineraryStations.at(i).get('duration').value }} min</span>
                              <span class="col-2">

                                <input type="number" class="form-control" id="validationTooltip05"
                                  [formControl]="itineraryStations.at(i).get('stoppingTime')"
                                  [ngClass]="{ 'is-invalid': submitted && itineraryStations.at(i).get('stoppingTime').invalid, 'is-valid': submitted && !itineraryStations.at(i).get('stoppingTime').invalid }"
                                  placeholder="Durée de stationnement (min)">
                                <div *ngIf="submitted && itineraryStations.at(i).get('stoppingTime').errors?.required"
                                  class="invalid-feedback">
                                  {{ "Stopping-Time" | translate }} {{ "REQUIRED" | translate }}
                                </div>



                              </span>
                              <span class="col-1">
                                <a class="danger p-0" (click)="removeStation(i)">
                                  <i class="ft-x font-medium-3"></i>
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>


    <div class="mr-2 ml-2" *ngIf="group1.length < 2">
      <div class="alert alert-light text-center">
        <p>{{"TWO_STATIONS_REQUIRED"| translate}}</p>
      </div>
    </div>
    <div class="px-2 py-1 d-flex align-content-center flex-wrap">
      <button type="button" class="btn bg-light-secondary px-2 w-100" (click)="toggleSelectList()">
        <em class="ft-plus-circle mx-1"></em>{{ 'Add_Station' | translate }}
      </button>
    </div>
    <div class="mb-3 p-1" style="height: 400px;">
      <app-itineray-map #mapComponent (shapeCalculated)="onShapeCalculated($event)"
        (returnShapeCalculated)="onReturnShapeCalculated($event)"
        (routeCalculated)="onRouteCalculated($event)"></app-itineray-map>
    </div>

    <div class="modal-footer row col-md-12 d-flex justify-content-end">
      <button type="submit" class="btn btn-info">Save</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">{{ "Annuler" |
        translate
        }}</button>
    </div>
  </form>
</div>