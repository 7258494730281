<div class="modal-dialog-centered" appRefresh>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title"> {{ getCardTitle() | translate }}</h4>
    </div>
    <div class="modal-body">
      <div class="card-body">
        <form [formGroup]="serviceGroupForm" (ngSubmit)="saveForm()">
          <div class="form-group">
            <label for="validationTooltip02">{{
              "SERVICE_GROUP_NAME" | translate
              }}</label>
            <input type="text" id="validationTooltip02" class="form-control" formControlName="name" [ngClass]="{
                      'is-invalid':
                        submitted &&
                        s.name.invalid,
                      'is-valid':
                        submitted &&
                        !s.name.invalid
                    }" />
            <div *ngIf="
                      submitted &&
                      s.name.errors?.required
                    " class="invalid-feedback">
              {{ "SERVICE_GROUP_NAME" | translate }}
              {{ "REQUIRED" | translate }}
            </div>
          </div>
          <fieldset class="form-group">
            <label for="validationTooltip02">{{ "flatRateHours" | translate }}</label>
            <div class="input-group">
              <input type="number" class="form-control" style="border-radius: 7px" aria-describedby="basic-addon2"
                formControlName="flatRateHours" [ngClass]="{
                        'is-invalid': submitted && s.flatRateHours.invalid,
                        'is-valid': submitted && !s.flatRateHours.invalid
                      }" />
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2">Heures</span>
              </div>
            </div>
            <div *ngIf="submitted && s.flatRateHours.errors?.required" class="invalid-feedback">
              {{ "FLAT_RATE_HOURS_REQUIRED" | translate }}
            </div>
          </fieldset>
          <div class="form-group">
            <label for="validationTooltip03">{{
              "TRIPS" | translate
              }}</label>
            <ng-select [items]="trips" bindLabel="code" bindValue="id" [multiple]="true" [closeOnSelect]="false"
              placeholder="{{'SELECT_TRIP' | translate}}" id="validationTooltip03" formControlName="trips" [ngClass]="{
                          'is-invalid':
                            submitted &&
                            s.trips.invalid,
                          'is-valid':
                            submitted &&
                            !s.trips.invalid
                        }">
            </ng-select>
            <div *ngIf="
                    submitted &&
                    s.trips.errors?.required
                  " class="invalid-feedback">
              {{ "TRIP" | translate }}
              {{ "REQUIRED" | translate }}
            </div>

          </div>
          <div class="table-responsive mt-5 mr-4">
            <table class="table">
              <thead>
                <h6 class="underline">{{"LIST_OF_SELECTED_TRIPS" | translate}}</h6>
                <tr>
                  <th scope="col">{{"LIGNE" | translate}}</th>
                  <th scope="col"></th>
                  <th scope="col">{{"TRIPS" | translate}}</th>
                  <th scope="col">{{"DEPART" | translate}}</th>
                  <th scope="col">{{"ARRIVAL(est)" | translate}}</th>
                </tr>
              </thead>
              <tbody *ngIf="getSelectedTrips().length > 0">

                <ng-container>
                  <tr *ngFor="let row of getSelectedTrips()">
                    <th scope="row">
                      <span class="badge badge-light">
                        {{ row?.routeNum }}
                      </span>
                    </th>
                    <td>{{ row?.routeName }}</td>
                    <td>
                      <span class="badge"
                        [ngClass]="{'badge-danger': row.code.endsWith('R'), 'badge-success': row.code.endsWith('A')}">
                        {{ row?.code }}
                      </span>
                    </td>
                    <td>{{ row.departure | slice:0:5 }}</td>
                    <td>{{ row.arrival | slice:0:5 }}</td>
                  </tr>
                </ng-container>
              </tbody>

            </table>
            <div *ngIf="missingAssociatedTrips.length > 0">
              <p>Some associated trips are missing. Do you want to select them.</p>
              <ul>
                <li *ngFor="let trip of missingAssociatedTrips">
                  {{ trip.code }}
                  <button type="button" class=" ml-2 btn btn-primary btn-sm" (click)="addTripToSelected(trip.id)">
                    <em class="ft-plus mr-1">Add</em>
                  </button>
                </li>
              </ul>
            </div>

            <div *ngIf="getSelectedTrips().length ===0" class="text-center ">
              <i class="icon-ban font-large-2"></i>
              <p class="font-medium-1  warning darken-1">
                {{"NO_SELECTED_TRIP"|translate}}
              </p>

            </div>

          </div>
          <div class="modal-footer">

            <button type="submit" class="btn btn-info mt-3 mr-2">
              <em class="ft-check-square mr-1"></em>{{ "SAVE" | translate }}
            </button>
            <button type="button" class="btn btn-secondary mt-3" (click)="cancelForm()">
              <em class="ft-x mr-1"></em>{{ "CANCEL" | translate }}
            </button>
          </div>
        </form>
      </div>

    </div>

  </div>
</div>