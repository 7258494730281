<div class="row modal-header">

  <h4 class="modal-title col-6"> {{ isEditMode ? ('Edit_Intervention' | translate) : ('New_Intervention' | translate) }}
  </h4>
  <button type="button" class="close mr-2" aria-label="Close " (click)="activeModal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>



</div>

<div class="modal-body ">
  <form [formGroup]="operatorForm" (ngSubmit)="save()">
    <div class="row ">
      <div class="col-6">
        <span class="text-bold-600 ">{{"Intervention_Date"| translate}}</span>
        <br>

        <div class=" d-flex align-items-center mr-4">
          <div class="input-group col-7 mr-2">
            <input class="form-control" id="date" formControlName="date" placeholder="yyyy-mm-dd" ngbDatepicker
              #d="ngbDatepicker" (dateSelect)="onDateSelect($event)"
              [ngClass]="{ 'is-invalid': submitted && o.date.invalid, 'is-valid': submitted && o.date.invalid }">

            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                <i class="fa fa-calendar"></i>
              </button>
            </div>
            <div *ngIf="submitted && o.date.errors?.required" class="invalid-feedback">
              {{ "Intervention_Date" | translate }} {{ "REQUIRED" | translate }}
            </div>
          </div>
          <ngb-timepicker [spinners]="false" formControlName="time" class=" mb-2"
            [ngClass]="{ 'is-invalid': submitted && o.time.invalid, 'is-valid': submitted && o.time.invalid }">
          </ngb-timepicker>


        </div>

      </div>
      <div class="col-3">
        <span class="text-bold-600 ">{{"Intervention_Supervisor" | translate}}</span>
        <br>
        <input type="text" id="supervisor" formControlName="supervisor"
          [ngClass]="{ 'is-invalid': submitted && o.supervisor.invalid, 'is-valid': submitted && o.supervisor.invalid }"
          class="form-control mt-1">

        <div *ngIf="submitted && o.supervisor.errors?.required" class="invalid-feedback">
          {{ "Intervention_Supervisor" | translate }} {{ "REQUIRED" | translate }}
        </div>
      </div>

      <div class="col-2">
        <span class="text-bold-600 ">{{"Intervention_Duration" | translate}}</span>
        <br>
        <input type="number" id="duration" formControlName="duration"
          [ngClass]="{ 'is-invalid': submitted && o.duration.invalid, 'is-valid': submitted && o.duration.invalid }"
          class="form-control mt-1">

        <div *ngIf="submitted && o.duration.errors?.required" class="invalid-feedback">
          {{ "Intervention_Duration" | translate }} {{ "REQUIRED" | translate }}
        </div>
      </div>
    </div>
    <div class="row m-1">
      <span class="text-bold-600 ">{{"Diagnostic" | translate}}</span>
      <br>
      <input type="text" id="diagnostic" formControlName="diagnostic" class="form-control mt-1">

    </div>
    <span class="text-bold-600 mt-2 ">{{"Operator"| translate}}</span>
    <div class="row border border-light m-1">
      <div class="table-responsive p-2">
        <table class="table">
          <thead>
            <tr>
              <th class="col-3">{{ 'Mat_Operator'|translate }}</th>
              <th class="col-3">{{ 'Name_Operator'|translate }}</th>
              <th class="col-3">{{ 'Total_Work_Duration'|translate }}</th>
              <th class="col-2">{{ 'Work_Log'|translate }}
              </th>
              <th scope="col-1"></th>




            </tr>
          </thead>
          <tbody *ngFor="let operator of selectedOperators">
            <tr class="cursor-pointer ">
              <td class="col-3">
                <span>{{ operator.matricule }}</span>
              </td>
              <td class="col-3">
                <span>
                  {{ operator.firstName }} {{ operator.lastName }}
                </span>
              </td>

              <td class="col-3">
                {{ operator.totalWorkDuration }}
              </td>
              <td class="col-2">
                <span (click)="addWorkDiary(operator)"><i class="fa-regular fa-clock text-info"></i></span>
              </td>

              <td> <span class="col-1" (click)="removeOperator(operator)"><i class="fa fa-trash text-danger"
                    aria-hidden="true"></i>
                </span></td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="showOperatorForm" class="p-2">
          <div class="d-flex align-items-center">
            <ng-select [items]="operator" bindLabel="firstName" bindValue="id" formControlName="selectedOperator"
              [clearable]="false" [placeholder]="'Select_Operator' | translate" [searchable]="true" class="flex-grow-1">
            </ng-select>
            <div class="ml-2">
              <button class="btn btn-success mr-2" (click)="saveOperator()"
                [disabled]="!operatorForm.get('selectedOperator')?.value">
                {{ 'Add' | translate }}
              </button>
              <button class="btn btn-danger" (click)="cancelAddOperator()">{{ 'Cancel' | translate }}</button>
            </div>
          </div>
        </div>

        <div class=" px-2 py-1 d-flex align-content-center flex-wrap text-white">
          <button type="button" class="btn bg-info px-2 w-100" (click)="addOperator()"  [appHasPermission]="['update_work_order']"  >
            <em class="ft-plus-circle mx-1 white"></em> <span class="text-white">{{ 'Add_Operator' | translate }}</span>
          </button>
        </div>

        <div *ngIf="operatorError" class=" px-2 py-1 d-flex align-content-center flex-wrap text-danger">
          {{ 'At_least_one_operator_required' | translate }}
        </div>
      </div>




    </div>

    <span class="text-bold-600 mt-2 ">{{"Oil_Change"| translate}}</span>
    <div class="row border border-light m-1">
      <div class="row p-2">
        <div class="col-6 ">
          <div class="d-flex align-items-center">
            <span class="text-nowrap me-2 fw-bold ml-2 mr-2">{{"Oil_Quantity" | translate}}</span>
            <input type="number" id="oilQuantity" formControlName="oilQuantity"
              class="form-control w-auto flex-grow-1 me-2 mr-2">
            <span class="text-nowrap">L</span>
          </div>
        </div>
        <div class="col-6 ">
          <div class="d-flex align-items-center">
            <span class="text-nowrap me-2 fw-bold mr-2">{{"Index_Kilometers" | translate}}</span>
            <input type="number" id="indexKilometers" formControlName="indexKilometers"
              class="form-control w-auto flex-grow-1 me-2 mr-2">
            <span class="text-nowrap">km</span>
          </div>
        </div>
      </div>

    </div>

    <span class="text-bold-600 mt-2 ">{{"Description" | translate}}</span>
    <div class="d-flex align-items-center">
      <input type="text" id="observation" formControlName="observation"
        class="form-control w-auto col-10 flex-grow-1 me-2 mr-2">
    </div>
  </form>
</div>

<div class="modal-footer  ">

  <button type="button" class="btn btn-info" (click)="save()">{{"Save" | translate}}</button>
  <button type="button" class="btn btn-danger" (click)="closeModal()">{{"CLOSE"|translate}}</button>
</div>