<div class="mb-3">
  <div class="form-group row mb-0">
    <label [class.text-danger]="control?.invalid && (control?.dirty || control?.touched)"
           [for]="idName"
           class="col-xl-3 col-md-4 mx-1">
      <span *ngIf="controlIsRequired(control)" class="text-danger">*</span>
      {{labelPlaceholder | titlecase}}
      <a *ngIf="validators && prepareTooltips(validators).length > 0"
        [ngbTooltip]="titleTemplate"
        placement="top">
    <i class="ft-alert-circle"></i>
</a>

<ng-template #titleTemplate>
    <ng-container *ngFor="let toolTip of prepareTooltips(validators)">
        <span>{{ toolTip }}</span><br>
    </ng-container>
</ng-template>

    </label>
    <div class="col-xl-8 col-md-7 px-0">
      <nz-select
      (ngModelChange)="log($event);ngModelChange.emit($event)"
      [disabled]="disabled"
      [formControl]="control"
      [id]="idName"
      [nzAllowClear]="true"
      [nzMode]="multiple?'multiple':'default'"
      nzPlaceHolder="{{ 'PLEASE_SELECT' | translate }}"
      nzShowSearch
      nzSize="large"
      style="width: 100%;"
      nzValue="bottomRight"
    >
      <nz-option *ngFor="let item of data;let i=index"
                 [nzLabel]="prepareLabelFieldFunction?prepareLabelFieldFunction(item):(labelField?item[labelField]:item)"
                 [nzValue]="values[i]">
      </nz-option>
    </nz-select>
    </div>
  </div>
  <app-error-handling [control]="control"></app-error-handling>
</div>
