<div class="card">
  <div class="card-header">
    <i class="ft-settings mr-2 align-middle"></i>
    <span class="text-bold-600">{{"Work_Diary_Of" | translate}} {{operator.firstName}} {{ operator.lastName}}</span>

    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <hr />
  <div *ngFor="let group of groupedWorkDiary; let i = index" class="p-2">
    <div (click)="toggleCollapse(i)" class="cursor-pointer">
      <span class="d-flex align-items-center text-dark font-weight-bold">
        <i class="fa" [ngClass]="{'fa-chevron-down': !group.isCollapsed, 'fa-chevron-right': group.isCollapsed}"></i>
        <span class="ml-2">{{ getDayOfWeek(group.date) }} {{ group.date }} </span>
      </span>
    </div>
    <div [ngbCollapse]="group.isCollapsed">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{ 'Session' | translate }}</th>
                <th scope="col">{{ 'From' | translate }}</th>
                <th scope="col">{{ 'To' | translate }}</th>
                <th scope="col">{{ 'Duration' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of group.instances" class="cursor-pointer">
                <td>
                  <span>{{item.sessionName}}</span>
                </td>
                <td>
                  <span>{{item.startTime}}</span>
                </td>
                <td>
                  {{item.endTime}}
                </td>
                <td>
                  <span>{{item.formattedDuration}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="px-2 py-1 d-flex align-content-center flex-wrap text-white">
    <button type="button" class="btn bg-info px-2 w-100" (click)="addWorkDiary()">
      <em class="ft-plus-circle mx-1 white"></em> <span class="text-white">{{ 'Add_Work_Diary' | translate }}</span>
    </button>
  </div>
</div>


<div class="card " *ngIf="isFormVisible">
  <div class="card-header">
    <i class="ft-settings mr-2 align-middle"></i>
    <span class="text-bold-600">{{"Work_Diary_Of" | translate}}{{operator.firstName}} {{ operator.lastName}}</span>
  </div>
  <hr />
  <div class="card-body ">


    <div class="card-body">
      <form [formGroup]="workDiaryForm" (ngSubmit)="save()">
        <div class="row">
          <span class="text-bold-600">{{ "Date" | translate }}</span>
        </div>
        <div class="row">
          <div class="input-group col-11 mr-2">
            <input class="form-control" id="date" formControlName="date" placeholder="yyyy-mm-dd" ngbDatepicker
              #d="ngbDatepicker" (dateSelect)="onDateSelect($event)" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                <i class="fa fa-calendar"></i>
              </button>
            </div>
          </div>
        </div>

        <div formArrayName="entries">
          <div *ngFor="let entry of entries.controls; let i = index" [formGroupName]="i">
            <div class="row mt-3">
              <span class="col-11 alert alert-light">{{ timeSlots[i].session }}</span>
            </div>
            <div class="row">
              <div class="col-6">
                <span class="text-bold-600">{{ "Start_Time" | translate }}</span>
                <input type="time" formControlName="startTime" class="form-control mt-1" />
                <small class="text-danger" *ngIf="entry.get('startTime').errors?.timeOutOfRange">
                  Start time must be between {{timeSlots[i].fromHour}} and {{timeSlots[i].toHour}}.
                </small>
              </div>
              <div class="col-6">
                <span class="text-bold-600">{{ "End_Time" | translate }}</span>
                <input type="time" formControlName="endTime" class="form-control mt-1" />
                <small class="text-danger" *ngIf="entry.get('endTime').errors?.timeOutOfRange">
                  End time must be between {{timeSlots[i].fromHour}} and {{timeSlots[i].toHour}}.
                </small>
              </div>
            </div>
          </div>
        </div>



        <div class="row mt-3">
          <div class="col-12">
            <button type="submit" class="btn btn-primary" [disabled]="!workDiaryForm.valid || !hasValidEntries()">
              {{ "Save" | translate }}
            </button>
            <button type="button" class="btn btn-secondary ml-2" (click)="closeModal()">
              {{ "Cancel" | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>