import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotyService } from '../services/noty.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
 private processingErrors: BehaviorSubject<Set<string>> = new BehaviorSubject(new Set());

  constructor(private notyService: NotyService) {}
 intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Vérifie si c'est une erreur 403
        if (error.status === 403) {
          console.log('Intercepted 403 error:', { 
            status: error.status, 
            url: error.url,
            timestamp: new Date().toISOString() // Ajoute un timestamp pour distinguer les erreurs
          });
          this.handle403Error(error.url);
        }
        
        return throwError(() => error);
      })
    );
  }
 

  private async handle403Error(url: string): Promise<void> {
    const currentErrors = this.processingErrors.getValue();

    if (currentErrors.has(url)) {
      return;
    }

    currentErrors.add(url);
    this.processingErrors.next(currentErrors);
    console.log('Started processing URL:', url);

    try {
      const resourceInfo = this.extractResourceInfo(url);
      const message = `Vous n'avez pas les droits d'accès à la liste des ${resourceInfo.resource}`;
      this.notyService.displayNotification(message, 'error');
    } catch (e) {
      console.error('Error in handle403Error:', e);
      this.notyService.displayNotification("Vous n'avez pas les droits d'accès à cette ressource", 'error');
    } finally {
      // Délai de 3 secondes avant de marquer l'URL comme traitée
      setTimeout(() => {
        const updatedErrors = this.processingErrors.getValue();
        updatedErrors.delete(url);
        this.processingErrors.next(updatedErrors);
        console.log('Finished processing URL:', url);
      }, 3000); // Délai de 3 secondes
    }
  }

  private extractResourceInfo(url: string): { resource: string } {
    try {
      const apiPath = url.split('/api/')[1];
      if (!apiPath) throw new Error('Invalid API path');
      const segments = apiPath.split('/').filter(Boolean);
      let resourceSegment = '';
      for (let i = segments.length - 1; i >= 0; i--) {
        if (segments[i] && segments[i] !== 'list') {
          resourceSegment = segments[i];
          break;
        }
      }
      const resource = this.formatResourceName(resourceSegment);
      return { resource };
    } catch (error) {
      console.error('Error in extractResourceInfo:', error);
      return { resource: 'ressources' };
    }
  }

  private formatResourceName(name: string): string {
    const frenchTerms: { [key: string]: string } = {
      'vehicles': 'véhicules',
      'stations': 'stations',
      'users': 'utilisateurs'
    };
    const cleanName = name.toLowerCase();
    return frenchTerms[cleanName] || name.replace(/-|_/g, ' ').toLowerCase();
  }
}
