<div style="height:850px">
    <div class="modal-header">
        <h4 class="modal-title">{{"TRIPS_DETAILS"|translate}}</h4>
    </div>
    <div class="modal-body">
        <div class="border rounded p-2 d-flex align-items-center row">
            <div class="badge badge-success col-lg-3 col-sm-auto mr-2 p-2">{{data.lineDirection}}</div>
            <div class="badge badge-light col-lg-5 col-sm-auto mr-2 p-2">{{data.plannedDeparture}} {{data.tripRoute}}
            </div>
            <div class="badge badge-success col-lg-1 col-sm-auto text-center mr-2 p-2">{{data.lineNumber}}</div>
            <div class="col-sm-auto col-lg-2">
           
    <div class="status-pill"
      [ngClass]="{'bg-secondary': data.status == '1', 'bg-info': data.status == '2', 'bg-warning': data.status == '3', 'bg-danger': data.status == '4', 'bg-success': data.status == '5' , 'bg-grey': data.status =='6'}">
    </div>
    {{ data.status == 1 ? ("NOT_ASSIGNED" | translate)  : (data.status == 2 ? ("ASSIGNED" | translate) : (data.status == 3 ? ("IN_PROGRESS" | translate) : (data.status == 4 ?
    ("CANCELLED" | translate) : (data.status == 5 ? ("COMPLETED" | translate) : (data.status == 6 ? ("REPORTED" | translate) : ""))))) }}

            </div>
        </div>

        <ul ngbNav #basicnav="ngbNav" class="nav-tabs mt-3">
            <li class="col-sm-auto col-lg-3" [ngbNavItem]="1" [formGroup]="assignmenetForm">
                <a class="text-dark text-bold-600 text-center" ngbNavLink>{{"TRIPS_INFO"|translate}}</a>
                <ng-template ngbNavContent>
                    <div class="row ml-3 mt-3 col-md-12 d-flex justify-content-end">
                        <button type="button" class="btn btn-light round mr-3" [disabled]="data.status === 4 && isEditDisabled()" (click)="editMission()"   [appHasPermission]="['update_trip']"> 
                            {{"EDIT"|translate}}
                            <span class="badge badge-pill badge-danger"> </span>&nbsp;
                            <i class="fas fa-pen mr-1"></i>
                        </button>
                    </div>
                    <div class="row">
                        <div
                            class="col d-flex align-items-center bg-grey bg-lighten-4 rounded p-3 mr-3 ml-lg-5 ml-sm-2 mb-3 mt-3">
                            <img src="./assets/img/icons/driver.png" class="mr-4 w-auto">
                            <div class="point-list-none" formGroupName="driver">
                                <span class="btn btn-secondary dropdown-toggle border-1 bg-transparent text-dark w-100"
                                    *ngIf="!isEditing && data.driver">
                                    {{data.driver.firstName}} {{data.driver.lastName}}
                                </span>
                                <div *ngIf="isEditing">
                                    <ng-select id="drivers" formControlName="id" [items]="groupedDrivers"
                                        [groupBy]="groupBy" bindLabel="name" bindValue="id" [searchable]="true"
                                        placeholder="Select a driver" (change)="onDriverChange($event)">

                                    </ng-select>
                                </div>
                                <div *ngIf="overlappingDriverMission" class="text-danger mt-2">
                                    {{"DRIVER_ALREADY_ASSIGNED" | translate}}: {{ overlappingDriverMission }}
                                    <br>
                                    <div class="row d-flex align-items-center text-dark">
                                        <input class="col-auto" type="checkbox" id="removeDriverCheckbox"
                                            (change)="toggleRemoveDriver()">
                                        <label for="removeDriverCheckbox" class="ml-2 col">
                                            {{"FORCE_ASSIGNMENT" | translate}}: {{"MISSION_WILL_NO_LONGER_BE_ASSIGNED" |
                                            translate}}
                                        </label>
                                    </div>
                                </div>
                                <div *ngIf="selectedDriverOnLeave && !overlappingDriverMission"
                                    class="text-danger mt-2">
                                    {{"DRIVER_ON_REST" | translate}}.
                                    <br>
                                    <div class="row d-flex align-items-center text-dark">
                                        <input class="col-auto" type="checkbox" id="forceAssignDriverCheckbox"
                                            (change)="toggleForceAssignDriver()">
                                        <label for="forceAssignDriverCheckbox" class="ml-2 col">
                                            {{"FORCE_ASSIGNMENT" | translate}}.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col d-flex align-items-center bg-grey bg-lighten-4 rounded p-3 mr-3 ml-lg-5 ml-sm-2 mb-3 mt-3">
                            <img src="./assets/img/icons/cashier.png" class="mr-4 w-auto">
                            <div class="point-list-none" formGroupName="reciever">
                                <span class="btn btn-secondary dropdown-toggle border-1 bg-transparent text-dark w-100"
                                    *ngIf="!isEditing && data.reciever">
                                    {{data.reciever.firstName}} {{data.reciever.lastName}}
                                </span>
                                <div *ngIf="isEditing">
                                    <ng-select id="receivers" formControlName="id" [items]="groupedReceivers"
                                        [groupBy]="groupBy" bindLabel="name" bindValue="id" [searchable]="true"
                                        placeholder="Select a receiver" (change)="onReceiverChange($event)">

                                    </ng-select>
                                </div>
                                <div *ngIf="overlappingReceiverMission" class="text-danger mt-2">
                                    {{"RECEIVER_ALREADY_ASSIGNED" | translate}}: {{ overlappingReceiverMission }}
                                    <br>
                                    <div class="row d-flex align-items-center text-dark">
                                        <input class="col-auto" type="checkbox" id="removeReceiverCheckbox"
                                            (change)="toggleRemoveReceiver()">
                                        <label for="removeReceiverCheckbox" class="ml-2 col">
                                            {{"FORCE_ASSIGNMENT" | translate}}: {{"MISSION_WILL_NO_LONGER_BE_ASSIGNED" |
                                            translate}}
                                        </label>
                                    </div>
                                </div>
                                <div *ngIf="selectedReceiverOnLeave && !overlappingReceiverMission"
                                    class="text-danger mt-2">
                                    {{"RECEIVER_ON_REST" | translate}}.
                                    <br>
                                    <div class="row d-flex align-items-center text-dark">
                                        <input class="col-auto" type="checkbox" id="forceAssignReceiverCheckbox"
                                            (change)="toggleForceAssignReceiver()">
                                        <label for="forceAssignReceiverCheckbox" class="ml-2 col">
                                            {{"FORCE_ASSIGNMENT" | translate}}.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="w-100"></div>
                    <div class="row">
                        <div
                            class="col d-flex align-items-center bg-grey bg-lighten-4 rounded p-3 mr-3 ml-lg-5 ml-sm-2 mb-3 mt-3 non-modifiable ">
                            <img src="./assets/img/icons/clock.png" class="mr-4" alt="">
                            <div class="col-md-9 col-12">
                                <div class="d-flex align-items-center">
                                    <ngb-timepicker formControlName="plannedDeparture"></ngb-timepicker>
                                    <button class="btn btn-info  mt-3" style="opacity: 0;">&nbsp;</button>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col d-flex align-items-center bg-grey bg-lighten-4 rounded p-3 mr-3 ml-lg-5 ml-sm-2 mb-3 mt-3">
                            <img src="./assets/img/icons/bus.png" class="mr-4" alt="">
                            <div class="point-list-none w-100" formGroupName="bus">
                                <span class="btn btn-secondary dropdown-toggle border-1 bg-transparent text-dark"
                                    *ngIf="!isEditing && data.bus">
                                    {{ data.bus.vehicleNumber }}
                                </span>
                                <div *ngIf="isEditing">
                                    <ng-select [items]="busList" bindLabel="vehicleNumber" bindValue="id"
                                        formControlName="id" [placeholder]="'' | translate" [searchable]="true"
                                        (change)="onBusChange($event)">
                                    </ng-select>
                                    <div *ngIf="overlappingBusMission" class="text-danger mt-2">
                                        {{"BUS_ALREADY_ASSIGNED" | translate}}: {{ overlappingBusMission }}
                                        <br>
                                        <div class="row d-flex align-items-center text-dark">
                                            <input class="col-auto" type="checkbox" id="removeBusCheckbox"
                                                (change)="toggleRemoveBus()">
                                            <label for="removeBusCheckbox" class="col">
                                                {{"FORCE_ASSIGNMENT" | translate}}:
                                                {{"MISSION_WILL_NO_LONGER_BE_ASSIGNED" | translate}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="data.status === 4">
                        <div class="col d-flex align-items-center bg-grey bg-lighten-4 rounded p-2 mr-3 ml-lg-5 ml-sm-2 mb-3 mt-3">
                            <div class="point-list-none w-100" style="text-align: center;">
                                <h4 class="text-danger"  for="cancelReason">{{"CANCEL_REASON" | translate}}</h4>
                                <p id="cancelReason">{{data.cancelReason}} </p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isEditing" class="row ml-1 col-md-12 d-flex justify-content-end">
                        <button type="submit" [disabled]="isSaveDisabled()" class="btn btn-info mr-2"
                            (click)="saveForm()">{{"SAVE"|translate}}</button>
                        <button type="button" class="btn btn-danger"
                            (click)="cancelEdit()">{{"CANCEL"|translate}}</button>
                    </div>
                </ng-template>
            </li>
            <li class="col-sm-auto col-lg-2" [ngbNavItem]="2">
                <a class="text-dark text-bold-600 text-center" ngbNavLink>{{"PROGRESSION"|translate}}</a>
                <ng-template ngbNavContent>
                    <horizontal-timeline [timelineElements]="timeline" [progression]="data.progression"
                        [showContent]="true"></horizontal-timeline>
                    <app-trip-progress [trip]="data" [itinerary]="itinerary"></app-trip-progress>
                </ng-template>
            </li>
            <li class="col-sm-auto col-lg-3" [ngbNavItem]="3">
                <a class="text-dark text-bold-600 text-center" ngbNavLink>{{"DEVIATIONS" | translate}}</a>
                <ng-template ngbNavContent>
                    <app-trip-deviation [trip]="data" [itinerary]="itinerary"></app-trip-deviation>
                </ng-template>
            </li>
            <li class="col-sm-auto col-lg-2" [ngbNavItem]="4">
                <a class="text-dark text-bold-600 text-center" ngbNavLink>{{"MAP" | translate}}</a>
                <ng-template ngbNavContent>
                    <app-real-time-map [stations]="stations" [itinerary]="itinerary" [trip]="data" ></app-real-time-map>
                </ng-template>
            </li>
            <li class="col-sm-auto col-lg-2" [ngbNavItem]="5">
                <a ngbNavLink class="text-dark text-bold-600 text-center">{{" HISTORY " | translate}}</a>
                <ng-template ngbNavContent class="p-5">
                    <div class="mission-log-scrollable">
                        <div *ngFor="let log of missionLogs" class="status-update-container">
                            <div class="status-icon avatar avatar-lg">
                                <span class="avatar-content">{{ getResponsableInitials(log.responsableName) }}</span>
                            </div>
                            <div class="status-content">
                                <p>
                                    <strong>{{ log.responsableName.toLowerCase() === 'system' ? 'Le système' : log.responsableName }}</strong>
                                    {{ getActionDescription(log) }}
                                    <span class="status-date-time">{{ formatDateTime(log.timestamp) }}</span>
                                </p>
                                <!-- Only show old and new values for updates -->
                                <ng-container>
                                    <p>
                                        <span class="status-old">{{ log.oldValue || 'Aucun' }}</span>
                                        <i class="status-arrow">➔</i>
                                        <span class="status-new">{{ log.newValue || 'Aucun' }}</span>
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                  </ng-template>
            </li>

        </ul>

        <div [ngbNavOutlet]="basicnav" class="border rounded mt-2 nav-content"></div>
        <div class="modal-footer mt-1">
            <section id="confirm-option">
                <button *ngIf="!isEditing" type="button" class="btn btn-info"
                    (click)="ConfirmText()"  [appHasPermission]="['cancel_trip']">{{"CANCEL_TRIP"|translate}}</button>
            </section>
            <!-- <button type="button" class="btn btn-danger"
            (click)="openReportModal(data)">Reporter</button> -->
            <button *ngIf="data.status === 1 || data.status === 2" type="button" class="btn btn-danger"
                (click)="openReportModal(data)"  [appHasPermission]="['postpone_trip']" >{{"REPORT" | translate}}</button>
            <button type="button" class="btn btn-danger" (click)="closeModal()"   >{{"CLOSE"|translate}}</button>
        </div>
    </div>
</div>

