import { Injectable } from '@angular/core';
import {WebSocketSubject} from 'rxjs/internal-compatibility';
import {Subject} from 'rxjs';
import { SAE } from 'app/shared/global/var';

@Injectable({
  providedIn: 'root'
})
export class SaeService {

  private websocket: WebSocketSubject<any>;
  public message$: Subject<any> = new Subject();


  constructor() { }

  connect(idTrip: number): void {
      const protocol = window.location.protocol.replace('http', 'ws');
      const host = window.location.host;
      const wsUrl = `${protocol}//${host}/websocket${SAE}/${idTrip}`;
      this.websocket = new WebSocketSubject(wsUrl);

    this.websocket.subscribe(
        (message) => this.onMessage(message),
        (err) => this.onError(err),
        () => this.onClose()
    );
  }

  sendMessage(message: string): void {
    if (this.websocket) {
      this.websocket.next(message);
    }
  }

  closeConnection(): void {
    if (this.websocket) {
      this.websocket.complete();
    }
  }

  private onMessage(message: any): void {
    this.message$.next(message); // Emit the message to the component
  }

  private onError(err: any): void {
    console.error('WebSocket error:', err);
  }

  private onClose(): void {
    console.log('WebSocket connection closed');
  }
}
