<div class="modal-header">
  <h4 class="modal-title">{{"SERVICE_GROUP_ASSIGNMENT"|translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-content" appRefresh>

  <form [formGroup]="serviceGroupForm" (ngSubmit)="saveForm()">

    <div class="p-2 m-2">
      <div class="card-content">
        <ng-container *ngIf="viewState === 'form'">
          <div class="row mb-2">
            <ng-template #popoverContent>
              <div class="col">
                <div class="row mb-1">
                  <div class="badge badge-secondary col-4 ">
                    <img src="./assets/img/icons/driver.png" class="mx-1 icon-small">
                  </div>
                  <span class="col-8">{{"Not_Assigned" | translate}}</span>
                </div>
                <div class="row mb-1">
                  <div class="badge badge-secondary col-4">
                    <i class="fa-solid fa-check "></i>
                    <img src="./assets/img/icons/driver.png" class="mx-1 icon-small">
                  </div>
                  <span class="col-8">{{"Assigned" | translate}}</span>
                </div>
                <div class="row mb-1">
                  <div class="badge bg-success col-4">
                    <i class="fa-solid fa-check"></i>
                    <img src="./assets/img/icons/driver.png" class="mx-1 icon-small">
                  </div>
                  <span class="col-8">{{"Assignation_OK" | translate}}</span>
                </div>
                <div class="row mb-1">
                  <div class="badge bg-danger col-4">
                    <i class="fa-solid fa-x"></i>
                    <img src="./assets/img/icons/driver.png" class="mx-1 icon-small">
                    <input class="col-auto" type="checkbox" disabled>
                  </div>
                  <span class="col-8">{{"Driver_Already_Affected"| translate}}</span>
                </div>

                <div class="row mb-1">
                  <div class="badge bg-warning col-4">
                    <i class="fa-solid fa-x"></i>
                    <img src="./assets/img/icons/driver.png" class="mx-1 icon-small">
                    <input class="col-auto" type="checkbox" disabled>
                  </div>
                  <span class="col-8">{{"Driver_On_Leave"| translate}}</span>
                </div>
              </div>
            </ng-template>
            <button type="button" class="icon-button" [ngbPopover]="popoverContent">
              <i class="fas fa-info-circle"></i>
            </button>
          </div>
          <div class="row mt-3 ">
            <div class=" ml-4 col-md-6 point-list-none">

              <ng-select [items]="serviceGroups" bindLabel="name" bindValue="id" [searchable]="true"
                placeholder="Select a Group" (change)="handleServiceGroupSelection($event)">

              </ng-select>
            </div>
            <div class="ml-5 col-md-3 col-xl-3 col-lg-3 col-12 d-flex justify-content-start">
              <app-date-range-filter (dateRangeSelected)="handleDateRangeSelection($event)"></app-date-range-filter>
            </div>
          </div>
          <div class="border border-1 p-2 m-2" *ngIf="selectedServiceId">
            <div *ngFor="let group of groupedInstances; let i = index">


              <div (click)="toggleCollapse(i)">
                <span class="d-flex align-items-center text-dark font-weight-bold">
                  <i class="fa"
                    [ngClass]="{'fa-chevron-down': !group.isCollapsed, 'fa-chevron-right': group.isCollapsed}"></i>
                  <span class="ml-2">{{ getDayOfWeek(group.date) }} {{ group.date }} ({{ group.instances.length }}
                    voyages)</span>
                </span>
              </div>
              <div [ngbCollapse]="group.isCollapsed">
                <div class="card-body">
                  <div class="bg-grey bg-lighten-4 rounded p-3 mb-3">
                    <div class="row">

                      <span class="col-1 text-bold-600">#{{ 'LINE' | translate }}</span>
                      <span class="col-2 text-bold-600">{{ 'TRIP' | translate }}</span>
                      <span class="col-2 text-bold-600">{{ 'DEPARTURE' | translate }}</span>
                      <span class="col-2 text-bold-600">{{ 'DRIVER-NUM' | translate }}</span>
                      <span class="col-2 text-bold-600">{{ 'BUS-NUM' | translate }}</span>
                      <span class="col-2 text-bold-600">{{ 'RECEIVER-NUM' | translate }}</span>
                    </div>
                  </div>

                  <div class="row" *ngFor="let trip of group.instances">
                    <!-- Le contenu existant pour chaque voyage -->
                    <div class="col-md-12 col-12 mb-3 mb-md-0">
                      <ul class="list-group">
                        <li class="list-group-item">

                          <div class="row">
                            <span class=" col-1">{{ trip.lineNumber }}</span>
                            <span class="col-2">{{ trip.lineDirection }}</span>
                            <span class=" col-2">{{ trip.plannedDeparture }}</span>

                            <span class="col-2">
                              {{ trip.driver?.matricule ? trip.driver.matricule : '-' }}
                              <i *ngIf="trip.driver?.matricule && !trip.removedDriver"
                                class="fa fa-trash text-danger ml-2" (click)="removeDriver(trip)"></i>
                              <i *ngIf="trip.removedDriver" class="fa fa-undo text-success ml-2"
                                (click)="undoRemoveDriver(trip)"></i>
                            </span>

                            <!-- Bus -->
                            <span class="col-2">
                              {{ trip.bus?.vehicleNumber ? trip.bus.vehicleNumber : '-' }}
                              <i *ngIf="trip.bus?.vehicleNumber && !trip.removedBus"
                                class="fa fa-trash text-danger ml-2" (click)="removeBus(trip)"></i>
                              <i *ngIf="trip.removedBus" class="fa fa-undo text-success ml-2"
                                (click)="undoRemoveBus(trip)"></i>
                            </span>

                            <!-- Receiver -->
                            <span class="col-2">
                              {{ trip.reciever?.matricule ? trip.reciever.matricule : '-' }}
                              <i *ngIf="trip.reciever?.matricule && !trip.removedReceiver"
                                class="fa fa-trash text-danger ml-2" (click)="removeReceiver(trip)"></i>
                              <i *ngIf="trip.removedReceiver" class="fa fa-undo text-success ml-2"
                                (click)="undoRemoveReceiver(trip)"></i>
                            </span>
                          </div>

                        </li>
                      </ul>
                    </div>
                    <!-- ... -->
                  </div>
                </div>
              </div>

            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="viewState === 'assignment'">
          <div *ngIf="isLoading" class="text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div *ngIf="!isLoading">
            <div *ngFor="let group of groupedInstances; let i = index">

              <div (click)="toggleCollapse(i)">
                <span class="d-flex align-items-center text-dark font-weight-bold">
                  <i class="fa"
                    [ngClass]="{'fa-chevron-down': !group.isCollapsed, 'fa-chevron-right': group.isCollapsed}"></i>
                  <span class="ml-2">{{ getDayOfWeek(group.date) }} {{ group.date }} ({{
                    group.instances.length }} {{ 'TRIP' | translate }})</span>
                </span>
              </div>
              <div [ngbCollapse]="group.isCollapsed">
                <div class="card-body">
                  <div class="row m-0">
                    <div *ngFor="let trip of group.instances" class="col-md-3 p-1">
                      <div class="mission-item-w border border-1 bg-lighten-4 rounded">
                        <div class="row m-0">
                          <div class="col-4 p-1 d-flex align-content-center flex-wrap">
                            <span class="badge badge-pill bg-success mr-3">{{ trip.lineDirection
                              }}</span>
                          </div>
                          <div class="col-8 p-1 d-flex align-content-center flex-wrap">
                            <div class="text-bold-600 text-secondary">{{ trip.plannedDeparture |
                              slice:11:16 }}</div>
                            <div class="text-secondary">{{ trip.tripRoute }}</div>
                          </div>
                        </div>
                        <div class="row m-0 bg-dark rounded-bottom">
                          <div class="col p-1">
                            <!-- Driver Badge -->
                            <div class="badge badge-secondary p-1 mr-1" [ngClass]="getBadgeClass(trip, 'driver')">
                              <i *ngIf="!trip.driverConflict  && trip.driver" class="fa-solid fa-check"></i>
                              <i *ngIf="trip.driverConflict" class="fa-solid fa-x"></i>
                              <img src="./assets/img/icons/driver.png" class="mx-1 icon-small">
                              <div class="ml-1 row">
                                <input *ngIf="trip.driverConflict" class="col-auto" type="checkbox" id="export-driver"
                                  [checked]="trip.overrideDriver"
                                  (change)="toggleOverrideDriver(trip, $event.target.checked)">
                              </div>
                              <div class="ml-1 row">
                                <input *ngIf="!trip.driverConflict && trip.driverDayOff" class="col-auto"
                                  type="checkbox" id="dayOff-driver" [checked]="trip.overrideDriverDayOff"
                                  (change)="toggleOverrideDriverDayOff(trip, $event.target.checked)">
                              </div>
                            </div>



                            <!-- Receiver Badge -->
                            <div class="badge badge-secondary p-1 mr-1" [ngClass]="getBadgeClass(trip, 'receiver')">
                              <i *ngIf="(!trip.receiverConflict && trip.reciever) || (!trip.receiverConflict && trip.receiver)"
                                class="fa-solid fa-check"></i>
                              <i *ngIf="trip.receiverConflict" class="fa-solid fa-x"></i>
                              <img src="./assets/img/icons/cashier.png" class="mx-1 icon-small">
                              <input *ngIf="trip.receiverConflict" class="col-auto" type="checkbox" id="export-receiver"
                                [checked]="trip.overrideReceiver"
                                (change)="toggleOverrideReceiver(trip, $event.target.checked)">
                              <input *ngIf="!trip.receiverConflict && trip.receiverDayOff" class="col-auto"
                                type="checkbox" id="dayOff-receiver" [checked]="trip.overrideReceiverDayOff"
                                (change)="toggleOverrideReceiverDayOff(trip, $event.target.checked)">
                            </div>

                            <!-- Bus Badge -->
                            <div class="badge badge-secondary p-1 mr-1" [ngClass]="getBadgeClass(trip, 'bus')">
                              <div>
                                <i *ngIf="!trip.busConflict && trip.bus" class="fa-solid fa-check"></i>
                                <i *ngIf="trip.busConflict" class="fa-solid fa-x"></i>
                                <img src="./assets/img/icons/bus.png" class="mx-1 icon-small">
                                <input *ngIf="trip.busConflict" class="col-auto" type="checkbox" id="export-bus"
                                  [checked]="trip.overrideBus"
                                  (change)="toggleOverrideBus(trip, $event.target.checked)">
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="border m-2 p-2">
            <div class="row">

              <div
                class="col d-flex align-items-center bg-grey bg-lighten-4 rounded p-3 mr-3 ml-lg-3 ml-sm-2 mb-3 mt-3">
                <img src="./assets/img/icons/driver.png" class="mr-4 w-auto">
                <div class=" w-100 point-list-none" formGroupName="driver">

                  <div>
                    <ng-select id="drivers" formControlName="id" [items]="groupedDrivers" [groupBy]="groupBy"
                      bindLabel="name" bindValue="id" [searchable]="true" placeholder="Select a driver"
                      (change)="onDriverChange($event)">

                    </ng-select>

                  </div>

                </div>
              </div>



              <div
                class="col d-flex align-items-center bg-grey bg-lighten-4 rounded p-3 mr-3 ml-lg-3 ml-sm-2 mb-3 mt-3">
                <img src="./assets/img/icons/cashier.png" class="mr-4 w-auto">

                <div class="w-100 point-list-none" formGroupName="reciever">

                  <div>
                    <ng-select id="receivers" formControlName="id" [items]="groupedReceivers" [groupBy]="groupBy"
                      bindLabel="name" bindValue="id" [searchable]="true" placeholder="Select a receiver"
                      (change)="onReceiverChange($event)">

                    </ng-select>
                  </div>

                </div>

              </div>


              <div class="w-100"></div>

              <div
                class="col d-flex align-items-center bg-grey bg-lighten-4 rounded p-3 mr-3 ml-lg-3 ml-sm-2 mb-3 mt-3">
                <img src="./assets/img/icons/bus.png" class="mr-4" alt="">
                <div class="point-list-none w-100" formGroupName="bus">
                  <div>
                    <ng-select [items]="busList" bindLabel="vehicleNumber" bindValue="id" formControlName="id"
                      [placeholder]="'' | translate" [searchable]="true" (change)="onBusChange($event)">
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </ng-container>
      </div>
    </div>
    <!-- <ngb-accordion #acc="ngbAccordion">
                <ngb-panel *ngFor="let group of transformInstancesByDate()" [id]="'date-' + group.date" >
                    <ng-template ngbPanelTitle>
                        <span class="d-flex align-items-center text-dark font-weight-bold">
                          <i class="fa" [ngClass]="{'fa-chevron-down': acc.isExpanded('date-' + group.date), 'fa-chevron-right': !acc.isExpanded('date-' + group.date)}"></i>
                          <span class="ml-2">{{ getDayOfWeek(group.date) }} {{ group.date }} ({{ group.instances.length }} voyages)</span>
                        </span>
                      </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row m-0">
                      <div *ngFor="let trip of group.instances" class="col-md-3 p-1">
                        <div class="mission-item-w border border-1 bg-lighten-4 rounded">
                          <div class="row m-0">
                            <div class="col-4 p-1 d-flex align-content-center flex-wrap">
                              <span class="badge badge-pill bg-success mr-3">{{ trip.lineDirection }}</span>
                            </div>
                            <div class="col-8 p-1 d-flex align-content-center flex-wrap">
                              <div class="text-bold-600 text-secondary">{{ trip.plannedDeparture | slice:11:16 }}</div>
                              <div class="text-secondary">{{ trip.tripRoute }}</div>
                            </div>
                          </div>
                          <div class="row m-0 bg-dark rounded-bottom">
                            <div class="col p-1">
                              <div class="badge badge-secondary p-1 mr-1" 
                              

                                [ngClass]="{
                                  'bg-danger': trip.driverAvailable == 1,
                                  'bg-success': trip.driverAvailable == 0 ,
                                  'bg-warning': trip.driverAvailable == -1,
                                  'bg-yellow': trip.driverAvailable === 2 && selectedDriverId
                                
                                 
                              }"
                                 
                                [title]="getDriverMsg(trip.driverAvailable)">
                                <i *ngIf="trip.driverAvailable == 0 " class="fa-solid fa-check "></i>
                                <i *ngIf="trip.driverAvailable ===2" class="fa-solid fa-check text-success"></i>
                                <i *ngIf="trip.driverAvailable == 1" class="fa-solid fa-x"></i>
                                <img src="./assets/img/icons/driver.png" class="mx-1 icon-small">
                                <input *ngIf="trip.driverAvailable === 1"   class="col-auto" type="checkbox" id="removeDriverCheckbox"
                                  (click)="toggleRemoveDriver(trip.id,$event)"
                                  [checked]="driverToRemove.includes(trip.id)"
                                  [class.bg-blue]="driverToRemove.includes(trip.id)">
                                <input *ngIf="trip.driverAvailable === 2 && selectedDriverId === 0"  [ngClass]="{'bg-blue': driverConflictMissions.includes(trip.id)}" class="col-auto" type="checkbox" id="deleteAssignmentCheckbox"
                                  (click)="toggleRemoveDriverAssignment(trip.id,$event)"
                                  [checked]="driverConflictMissions.includes(trip.id)">
                                <input *ngIf="trip.driverAvailable === -1 && selectedDriverId !==0"  [ngClass]="{'bg-blue': forcedDriverMissions.includes(trip.id)}" class="col-auto" type="checkbox" id="forceAssignDriverCheckbox"
                                  (click)="toggleForceAssignDriver(trip.id,$event)"
                                  [checked]="forcedDriverMissions.includes(trip.id)">
                              
                                  <input *ngIf="trip.driverAvailable == 2 && selectedDriverId"
                                   class="col-auto"
                                   type="checkbox"
                                   [ngClass]="{'bg-blue': reassinedDriverMissions.includes(trip.id)}"
                                   id="reAssignDriverCheckbox"
                                   (click)="reAssignDriver(trip.id,$event)"
                                   [checked]="reassinedDriverMissions.includes(trip.id)">
                              </div>
          
                              <div class="badge badge-secondary p-1 mr-1" 
                             
                              [ngClass]="{
                                'bg-danger': trip.receiverAvailable == 1,
                                'bg-success': trip.receiverAvailable == 0 ,
                                'bg-warning': trip.receiverAvailable == -1,
                                'bg-yellow': trip.receiverAvailable === 2 && selectedReceiverId
                              
                               
                            }"
                                [title]="getReceiverMsg(trip.receiverAvailable)">
                                <i *ngIf="trip.receiverAvailable == 0" class="fa-solid fa-check"></i>
                                <i *ngIf="trip.receiverAvailable == 1" class="fa-solid fa-x"></i>
                                <img src="./assets/img/icons/cashier.png" class="mx-1 icon-small">
                                <input *ngIf="trip.receiverAvailable == 1" class="col-auto" type="checkbox" id="removeReceiverCheckbox"
                                  (click)="toggleRemoveReceiver(trip.id,$event)"
                                  [checked]="receiverToRemove.includes(trip.id)">
                                <input *ngIf="trip.receiverAvailable == -1 && selectedReceiverId !== 0" class="col-auto" type="checkbox" id="forceAssignReceiverCheckbox"
                                  (click)="toggleForceAssignReceiver(trip.id,$event)"
                                  [checked]="forcedReceiverMissions.includes(trip.id)">
                                  <input *ngIf="trip.receiverAvailable == 2 && selectedReceiverId === 0" class="col-auto" type="checkbox" id="deleteReceiverAssignmentCheckbox"
                                  (click)="toggleRemoveReceiverAssignment(trip.id,$event)"
                                  [checked]="receiverConflictMissions.includes(trip.id)">
                                <input *ngIf="trip.receiverAvailable == 2 && selectedReceiverId" class="col-auto" type="checkbox" id="reAssignReceiverCheckbox"
                                (click)="reAssignReceiver(trip.id,$event)"
                                [checked]="reassinedReceiverMissions.includes(trip.id)">
                              </div>
          
                              <div class="badge badge-secondary p-1 mr-1" 
                               
                                [ngClass]="{
                                  'bg-danger': trip.busAvailable == 1,
                                  'bg-success': trip.busAvailable == 0 ,
                                  'bg-yellow': trip.busAvailable === 2 && selectedBusId
                                
                                 
                              }"
                                [title]="getBusMsg(trip.busAvailable)">
                                <i *ngIf="trip.busAvailable == 0" class="fa-solid fa-check"></i>
                                <i *ngIf="trip.busAvailable == 1" class="fa-solid fa-x"></i>
                                <img src="./assets/img/icons/bus.png" class="mx-1 icon-small">
                                <input *ngIf="trip.busAvailable == 1" class="col-auto" type="checkbox" id="removeBusCheckbox"
                                  (click)="toggleRemoveBus(trip.id,$event)"
                                  [checked]="busToRemove.includes(trip.id)">
                                <input *ngIf="trip.busAvailable == 2 && selectedBusId === 0" class="col-auto" type="checkbox" id="deleteBusAssignmentCheckbox"
                                  (click)="toggleRemoveBusAssignment(trip.id,$event)"
                                  [checked]="busConflictMissions.includes(trip.id)">
                                <input *ngIf="trip.busAvailable == 2 && selectedBusId" class="col-auto" type="checkbox" id="reAssignBusCheckbox"
                                  (click)="reAssignBus(trip.id,$event)"
                                  [checked]="reassinedBusMissions.includes(trip.id)">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion> -->

    <!-- </div> -->

    <!-- <div class="border m-2 p-2">
            <div class="row">

                <div
                    class="col d-flex align-items-center bg-grey bg-lighten-4 rounded p-3 mr-3 ml-lg-3 ml-sm-2 mb-3 mt-3">
                    <img src="./assets/img/icons/driver.png" class="mr-4 w-auto">
                    <div class=" w-100 point-list-none" formGroupName="driver">
                        <div>
                            <ng-select 
                            id="drivers"
                            formControlName="id"
                            [items]="groupedDrivers"
                            [groupBy]="groupBy"
                            bindLabel="name"
                            bindValue="id"
                            [searchable]="true"
                            placeholder="Select a driver"
                            (change)="onDriverChange($event)"
                          >
                          
                          </ng-select>

                          

                        </div>
                     
                    </div>
                </div>



                <div
                    class="col d-flex align-items-center bg-grey bg-lighten-4 rounded p-3 mr-3 ml-lg-3 ml-sm-2 mb-3 mt-3">
                    <img src="./assets/img/icons/cashier.png" class="mr-4 w-auto">

                    <div class="w-100 point-list-none" formGroupName="reciever">
                        <div>
                            <ng-select 
                            id="receivers"
                            formControlName="id"
                            [items]="groupedReceivers"
                            [groupBy]="groupBy"
                            bindLabel="name"
                            bindValue="id"
                            [searchable]="true"
                            placeholder="Select a receiver"
                            (change)="onReceiverChange($event)"
                          >
                          
                          </ng-select>
                        </div>
                     
                    </div>

                </div>


                <div class="w-100"></div>

                <div
                    class="col d-flex align-items-center bg-grey bg-lighten-4 rounded p-3 mr-3 ml-lg-3 ml-sm-2 mb-3 mt-3">
                    <img src="./assets/img/icons/bus.png" class="mr-4" alt="">
                    <div class="point-list-none w-100" formGroupName="bus">
                        <div>
                            <ng-select
                            [items]="busList"
                            bindLabel="vehicleNumber"
                            bindValue="id"
                            formControlName="id"
                            [placeholder]="'' | translate"
                            [searchable]="true"
                            (change)="onBusChange($event)"
                          >
                          </ng-select>
                        </div>
                    </div>
                </div>
            </div>

        </div> -->
    <!-- <div class="modal-footer row col-md-12 d-flex justify-content-end">
            <button type="submit" class="btn btn-info" >Save</button>
            <button type="button" class="btn btn-danger"
                (click)="activeModal.close('Close click')">{{"Annuler"|translate}}</button>
        </div> -->
    <div class="modal-footer row col-md-12 d-flex justify-content-end">
      <!-- <button type="submit" class="btn btn-info">Save</button>
          <button type="button" class="btn btn-danger"
            (click)="activeModal.close('Close click')">{{"Annuler"|translate}}</button> -->




      <button *ngIf="viewState === 'assignment'" type="button" class="btn btn-secondary" (click)="returnToPrevious()">
        {{ 'RETURN' | translate }}
      </button>
      <button *ngIf="viewState === 'assignment'" type="button" class="btn btn-secondary"
        (click)="checkAvailabilityForInstances()">
        {{ 'CHECK-AVAILABILTIES' | translate }}
      </button>

      <button *ngIf="viewState === 'form' " type="button" class="btn btn-primary" (click)="onNextClicked()">
        {{ 'NEXT' | translate }}
      </button>

      <button (click)="saveForm()" *ngIf="viewState === 'assignment'" class="btn btn-primary"
        [disabled]="!isAvailabilityChecked">Save</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">{{"Close" |
        translate}}</button>
    </div>
  </form>
</div>