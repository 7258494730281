import {
  Component,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef,
  Inject,
  Renderer2,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  HostListener,
  Injector,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LayoutService } from "../services/layout.service";
import { Subscription } from "rxjs";
import { ConfigService } from "../services/config.service";
import { DOCUMENT, registerLocaleData } from "@angular/common";
import { CustomizerService } from "../services/customizer.service";
import { UntypedFormControl } from "@angular/forms";
import { LISTITEMS } from "../data/template-search";
import { Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { en_US, fr_FR, NZ_I18N, NzI18nService } from "ng-zorro-antd/i18n";
import en from "@angular/common/locales/en";
import fr from "@angular/common/locales/fr";

import { UsersService } from "../services/users.service";
import { User } from "./user";
import { WebsocketService } from "../services/websocket.service";

interface Notification {
  message: string; // Ajout de la propriété 'message'
  timestamp: Date;
}
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  private websocketSubscription: Subscription | null = null;
  unreadCount: number = 0;
  notifications: Notification[] = [];
  currentLang = "fr";
  selectedLanguageText = "French";
  selectedLanguageFlag = "./assets/img/flags/fr.png";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  logoUrl = "assets/img/logo.png";
  menuPosition = "Side";
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = "";
  transparentBGClass = "";
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  username: string;
  user: User;

  @ViewChild("search") searchElement: ElementRef;
  @ViewChildren("searchResults") searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new UntypedFormControl();

  public config: any = {};

  constructor(
    public translate: TranslateService,
    private layoutService: LayoutService,
    private router: Router,
    private nzI18nService: NzI18nService,
    private authService: AuthService,
    private userService: UsersService,
    private injector: Injector,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private websocketService: WebsocketService
  ) {
    const savedLanguage = localStorage.getItem("langue");

    // Always set French as the default language
    this.ChangeLanguage(savedLanguage || "fr");
    translate.use(savedLanguage || "fr");
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe((isShow) => {
      this.hideSidebar = !isShow;
    });
  }

  async ngOnInit() {
    this.listItems = LISTITEMS;
    if (this.authService.isLoggedIn()) {
      await this.getCurrentUser();
    }

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
    const currentUsername = this.getCurrentUsername();
    if (currentUsername) {
      this.connectWebSocket(currentUsername);
    } else {
      console.error("Impossible de récupérer le nom d'utilisateur");
    }

    // this.loadNotificationsFromLocalStorage();
  }

  private addNotification(message: string) {
    const newNotification: Notification = {
      message: message,
      timestamp: new Date(),
    };

    this.notifications.unshift(newNotification);
    this.unreadCount++;

    this.saveNotificationsToLocalStorage();
  }

  private saveNotificationsToLocalStorage() {
    localStorage.setItem("notifications", JSON.stringify(this.notifications));
  }

  onDropdownClose() {
    this.unreadCount = 0;
  }

  get notificationCount(): number {
    return this.unreadCount;
  }

  private getCurrentUsername(): string | null {
    return this.authService.getUsernameFromToken();
  }

  private disconnectWebSocket() {
    if (this.websocketSubscription) {
      this.websocketSubscription.unsubscribe();
      this.websocketSubscription = null;
    }
    this.websocketService.disconnect();
  }

  public sendMessage(message: any) {
    this.websocketService.send(message);
  }

  private connectWebSocket(username: string) {
    this.disconnectWebSocket();

    this.websocketService.initializeConnection(username);

    this.websocketSubscription = this.websocketService.onMessage().subscribe(
      (message) => {
        if (message) {
          this.addNotification(message);
        }
      },
      (error) => console.error("Erreur WebSocket:", error)
    );
  }

  markAllAsRead() {
    this.unreadCount = 0;
    this.saveNotificationsToLocalStorage();
  }

  // get current user from keycloak by username
  async getCurrentUser() {
    // get username from token in local storage
    const decodedToken = this.authService.getDecodedToken();
    this.username = decodedToken.preferred_username;
    this.userService.getLocalUserDetailsByUsername(this.username).subscribe(
      (user) => {
        this.user = user;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe(
      (templateConf) => {
        if (templateConf) {
          this.config = templateConf;
        }
        this.loadLayout();
        this.cdr.markForCheck();
      }
    );
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {
    if (
      this.config.layout.menuPosition &&
      this.config.layout.menuPosition.toString().trim() != ""
    ) {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === "Light") {
      this.logoUrl = "assets/img/logo-dark.png";
    } else {
      this.logoUrl = "assets/img/logo.png";
    }

    if (this.config.layout.variant === "Transparent") {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    } else {
      this.transparentBGClass = "";
    }
  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add(
        "first-active-item"
      );
    }

    if (event.target.value === "") {
      this.seachTextEmpty.emit(true);
    } else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove(
        "first-active-item"
      );
    }
  }

  onEscEvent() {
    this.control.setValue("");
    this.searchOpenClass = "";
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      let url = this.searchResults.first.url;
      if (url && url != "") {
        this.control.setValue("");
        this.searchOpenClass = "";
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);
    let ngZorroLocale;

    switch (language) {
      case "en":
        ngZorroLocale = en_US;
        registerLocaleData(en);
        this.selectedLanguageText = "English";
        this.selectedLanguageFlag = "./assets/img/flags/us.png";
        break;
      case "fr":
        ngZorroLocale = fr_FR;
        registerLocaleData(fr);
        this.selectedLanguageText = "French";
        this.selectedLanguageFlag = "./assets/img/flags/fr.png";
        break;
    }
    this.injector.get(NZ_I18N, ngZorroLocale);
    localStorage.setItem("langue", language);
  }

  ToggleClass() {
    this.toggleClass =
      this.toggleClass === "ft-maximize" ? "ft-minimize" : "ft-maximize";
  }

  toggleSearchOpenClass(display) {
    this.control.setValue("");
    if (display) {
      this.searchOpenClass = "open";
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    } else {
      this.searchOpenClass = "";
    }
    this.seachTextEmpty.emit(true);
  }

  logout() {
    this.authService.logout();
  }

  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }

  gotToSettings() {
    this.router.navigate(["/settings"]);
  }

  // url :'profile/:username',
  goToProfile() {
    this.router.navigate(["/profile"]);
  }
}
