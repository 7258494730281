import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BASE_URL_FLEET, M_BASE_URL, VEHICLE } from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { InterventionFormComponent } from "../intervention-form/intervention-form.component";
import { DeleteModalComponent } from "../delete-modal/delete-modal.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AuthService } from "app/shared/auth/auth.service";

@Component({
  selector: "app-work-order-form",
  templateUrl: "./work-order-form.component.html",
  styleUrls: ["./work-order-form.component.scss"],
})
export class WorkOrderFormComponent implements OnInit {
  @Output() listUpdated: EventEmitter<void> = new EventEmitter<void>();
  @Input() item;
  interventionsLength: any;
  vehicles;
  interventions;
  workOrderForm: FormGroup;
  username :string;
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private crudService: CrudService,
    private fb: FormBuilder,
    private authservice:AuthService
  ) {
    this.username = this.authservice.getUsernameFromToken();

  }

  /**
   * Initializes the component by setting up the form and retrieving interventions
   * related to the work order. This method is automatically called when the component
   * is initialized.
   */

  ngOnInit(): void {
    this.initForm();
    this.getInterventionByWorkOrder();
  }

  /**
   * Closes the currently active modal window.
   */

  closeModal() {
    this.activeModal.close();
  }

  /**
   * Initializes the reactive form for the work order. The form contains fields for
   * indicating if the defect is eliminated and for adding observations.
   * The values are pre-filled based on the current item data.
   */

  initForm(): void {
    this.workOrderForm = this.fb.group({
      defectEliminated: [
        this.item.defectEliminated !== null ? this.item.defectEliminated : 1,
      ],
      observation: [this.item.observation || ""],
    });
  }

  /**
   * Saves the updated work order data. If the form is valid, it sends an update request to the server
   * and closes the modal upon successful completion. Emits an event to notify that the list
   * of work orders has been updated.
   */

  // save() {
  //   if (this.workOrderForm.valid) {
  //     const updatedData = {
  //       id: this.item.id,
  //       defectEliminated: Number(this.workOrderForm.get('defectEliminated').value),
  //       observation: this.workOrderForm.get('observation').value
  //     };

  //     this.crudService.update(`${M_BASE_URL}/workOrder`, this.item.id, updatedData).subscribe(
  //       (response) => {
  //         this.listUpdated.emit();
  //         this.activeModal.close();
  //       },
  //       (error) => {
  //         console.error('Error updating WorkOrder', error);
  //       }
  //     );
  //   }
  // }

  save() {
    if (this.workOrderForm.valid) {
      const defectEliminated = Number(
        this.workOrderForm.get("defectEliminated").value
      );
      const updatedData = {
        id: this.item.id,
        defectEliminated: defectEliminated,
        observation: this.workOrderForm.get("observation").value,
        status: defectEliminated === 1 ? "terminé" : this.item.status, // Update status if defect is eliminated
      };

      // Update the work order
      this.crudService
        .update(`${M_BASE_URL}/workOrder`, this.item.id, updatedData)
        .subscribe(
          (response) => {
            // If defect is eliminated, also update the linked work request status to 'treated'
            if (defectEliminated === 1) {
              const workRequestUpdateData = { status: "treated" };
              this.crudService
                .update(
                  `${M_BASE_URL}/workRequest`,
                  this.item.workRequestId,
                  workRequestUpdateData
                )
                .subscribe(
                  (workRequestResponse) => {
                    this.listUpdated.emit();
                    this.activeModal.close();
                  },
                  (error) => {
                    console.error("Error updating work request", error);
                  }
                );
            } else {
              // Just close the modal if there's no need to update the work request
              this.listUpdated.emit();
              this.activeModal.close();
            }
          },
          (error) => {
            console.error("Error updating WorkOrder", error);
          }
        );
    }
  }

  /**
   * Retrieves the list of interventions related to the current work order by sending a request to the server.
   * Updates the component's intervention list and stores the total number of interventions.
   */

  getInterventionByWorkOrder() {
    this.crudService
      .getOne<any>(`${M_BASE_URL}/intervention`, this.item.id)
      .subscribe(
        (data: any) => {
          this.interventions = data;
          this.interventionsLength = this.interventions.length;
        },
        (error) => {
          console.error("Error retrieving standard for editing:", error);
        }
      );
  }

  /**
   * Retrieves the list of vehicles from the server and updates the component's vehicle list.
   */




  getVehicles() {
    const url = `${BASE_URL_FLEET}/vehicles?username=${encodeURIComponent(
      this.username
     )}`;
      this.crudService.getAll<any>(url).subscribe((data) => {
      this.vehicles = data.data;
    });
  }

  /*getVehicles() {
    this.crudService.getAll(BASE_URL_FLEET + VEHICLE).subscribe((data: any) => {
      this.vehicles = data.data;
    });
  }*/

  /**
   * Opens a modal window to add a new intervention. The intervention form is displayed
   * in the modal, and after successfully adding an intervention, the list of interventions is refreshed.
   *
   * @param itemId {any} The ID of the work order or item related to the new intervention.
   */

  addIntervention(itemId: any) {
    const modalRef = this.modalService.open(InterventionFormComponent, {
      size: "xl",
    });
    modalRef.componentInstance.itemId = itemId;
    modalRef.componentInstance.interventionLength = this.interventionsLength;

    modalRef.componentInstance.listUpdated.subscribe(() => {
      this.getInterventionByWorkOrder();
    });
  }

  /**
   * Opens a modal window to confirm the deletion of an intervention. The modal allows the user
   * to confirm the deletion of a specific intervention, and upon successful deletion,
   * the list of interventions is refreshed.
   *
   * @param interventionId {string} The ID of the intervention to delete.
   * @param intervention {string} The type of intervention being deleted.
   * @param name {string} The name of the intervention item.
   */

  openInterventionDeleteModal(
    interventionId: string,
    intervention: string,
    name: string
  ) {
    const modalRef = this.modalService.open(DeleteModalComponent);
    modalRef.componentInstance.itemId = interventionId;
    modalRef.componentInstance.itemType = intervention;
    modalRef.componentInstance.itemName = name;
    modalRef.componentInstance.itemDeleted.subscribe(() => {
      this.getInterventionByWorkOrder();
    });
  }

  /**
   * Opens a modal window to edit an existing intervention. The intervention form is displayed
   * with the data of the selected intervention, allowing the user to update the details.
   * After successfully editing the intervention, the list of interventions is refreshed.
   *
   * @param item {any} The intervention data to be edited.
   */

  editIntervention(item: any) {
    const modalRef = this.modalService.open(InterventionFormComponent, {
      size: "xl",
    });
    modalRef.componentInstance.item = item;

    modalRef.componentInstance.listUpdated.subscribe(() => {
      this.getInterventionByWorkOrder();
    });
  }
}
