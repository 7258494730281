import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { forkJoin, Observable, of, throwError } from "rxjs";
import { KeycloakService } from "./keycloak.service";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { ALERT, BASE_API_USER } from "../global/var";
import { BASE_API_INSCRIPTION } from "../global/var";
import { BASE_API_ALERTS } from "../global/var";
@Injectable({
  providedIn: "root",
})
export class UsersService {
  /**
   * Creates an instance of UserService.
   * @param http - The HttpClient service used for making HTTP requests.
   */
  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService
  ) {}

  /**
   * Retrieves a list of users from Keycloak.
   * @param token - The authorization token for the request.
   * @returns {Observable<any>} - An observable containing the list of users.
   */
  getUsersList(token: string): any {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http.get(
      `${environment.baseUrlAdmin}${environment.realmClient}/users`,
      { headers: headers }
    );
  }

  /**
   * Retrieves the client roles assigned to a specific user.
   * @param token - The authorization token for the request.
   * @param userId - The ID of the user.
   * @returns {Observable<any>} - An observable containing the client roles assigned to the user.
   */
  getRoleByUser(token: string, userId: string): any {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http.get(
      `${environment.baseUrlAdmin}${environment.realmClient}/users/${userId}/role-mappings/clients/${environment.clientCode}`,
      { headers: headers }
    );
  }

  /**
   * Retrieves the realm roles assigned to a specific user.
   * @param token - The authorization token for the request.
   * @param userId - The ID of the user.
   * @returns {Observable<any>} - An observable containing the realm roles assigned to the user.
   */
  getRealmRolesByUser(token: string, userId: string): any {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http.get(
      `${environment.baseUrlAdmin}${environment.realmClient}/users/${userId}/role-mappings/realm`,
      { headers: headers }
    );
  }

  /**
   * Retrieves a list of client roles.
   * @param token - The authorization token for the request.
   * @returns {Observable<any[]>} - An observable containing the list of client roles.
   */
  getRolesList(token: string): any {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http.get(
      `${environment.baseUrlAdmin}${environment.realmClient}/clients/${environment.clientCode}/roles`,
      { headers: headers }
    );
  }

  /**
   * Retrieves a list of realm roles.
   * @param token - The authorization token for the request.
   * @returns {Observable<any[]>} - An observable containing the list of realm roles.
   */
  getRealmRolesList(token: string): any {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http.get(
      `${environment.baseUrlAdmin}${environment.realmClient}/roles`,
      { headers: headers }
    );
  }

  /**
   * Creates a new user account in Keycloak.
   * @param body - The form data containing user details.
   * @param token - The authorization token for the request.
   * @returns {Observable<any>} - An observable containing the response of the user creation request.
   */
  createUserAccount(body: FormData, token: any): any {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http.post(
      `${environment.baseUrlAdmin}${environment.realmClient}/users`,
      body,
      { headers: headers, observe: "response" }
    );
  }

  /**
   * Envoie une requête HTTP pour créer un utilisateur dans la base de données locale.
   * @param userData Les données de l'utilisateur à créer.
   */
  createUserInLocalDB(userData: any): Observable<any> {
    const localUserData = {
      username: userData.username,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      phoneNumber: userData.attributes.phoneNumber,
      address: userData.attributes.address,
      password: userData.credentials[0].value,
      centerIds: (userData.centerIds || []).map((id) => Number(id)), // Assurez-vous que les IDs sont des nombres
      agencyIds: (userData.agencyIds || []).map((id) => Number(id)), // Assurez-vous que les IDs sont des nombres
    };

    // Utilisation de l'URL de base de l'API user avec le chemin relatif 'add'
    return this.http.post(`${BASE_API_USER}/add`, localUserData);
  }

  /**
   * Assigns client roles to a specific user.
   * @param id_user - The ID of the user.
   * @param token - The authorization token for the request.
   * @param body - The roles to be assigned to the user.
   * @returns {Observable<any>} - An observable containing the response of the role assignment request.
   */
  assignRolesToUser(id_user: any, token: any, body: any): any {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http.post(
      `${environment.baseUrlAdmin}${environment.realmClient}/users/${id_user}/role-mappings/clients/${environment.clientCode}`,
      body,
      { headers: headers }
    );
  }

  /**
   * Assigns realm roles to a specific user.
   * @param id_user - The ID of the user.
   * @param token - The authorization token for the request.
   * @param body - The realm roles to be assigned to the user.
   * @returns {Observable<any>} - An observable containing the response of the realm role assignment request.
   */
  assignRealmRolesToUser(id_user: any, token: any, body: any): any {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http.post(
      `${environment.baseUrlAdmin}${environment.realmClient}/users/${id_user}/role-mappings/realm`,
      body,
      { headers: headers }
    );
  }

  /**
   * Retrieves user details by username.
   * @param username - The username of the user to retrieve.
   * @returns {Observable<any>} - An observable containing the user details.
   */
  findUserByUsername(username: string): Observable<any> {
    const token = this.keycloakService.getToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http.get<any>(
      `${environment.baseUrlAdmin}${environment.realmClient}/users?username=${username}`,
      { headers: headers }
    );
  }

  deleteUserFromKeycloak(userId: string): Observable<any> {
    const token = this.keycloakService.getToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http.delete(
      `${environment.baseUrlAdmin}${environment.realmClient}/users/${userId}`,
      { headers: headers }
    );
  }

  deleteUserFromLocalDB(username: string): Observable<any> {
    return this.http.delete(`${BASE_API_USER}/delete/${username}`);
  }

  deleteUserAccount(username: string): Observable<any> {
    return this.findUserByUsername(username).pipe(
      switchMap((keycloakUsers: any[]) => {
        if (keycloakUsers.length > 0) {
          const userId = keycloakUsers[0].id; // Récupération de l'ID de Keycloak
          return forkJoin([
            this.deleteUserFromKeycloak(userId), // Suppression de Keycloak
            this.deleteUserFromLocalDB(username), // Suppression de la base de données locale
          ]);
        } else {
          throw new Error("User not found in Keycloak");
        }
      })
    );
  }

  /**
   * Retrieves the credentials of the user by userId.
   * @param userId - The ID of the user.
   * @returns {Observable<any>} - An observable containing the user credentials.
   */
  getUserCredentials(userId: string): Observable<any> {
    const token = this.keycloakService.getToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http.get<any>(
      `${environment.baseUrlAdmin}${environment.realmClient}/users/${userId}/credentials`,
      { headers: headers }
    );
  }

  /**
   * Retrieves the role mappings of the user by userId.
   * @param userId - The ID of the user.
   * @returns {Observable<any>} - An observable containing the role mappings of the user.
   */
  getUserRoles(userId: string): Observable<any> {
    const token = this.keycloakService.getToken();
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http.get<any>(
      `${environment.baseUrlAdmin}${environment.realmClient}/users/${userId}/role-mappings`,
      { headers: headers }
    );
  }

  /**
   * Removes client roles from a specific user.
   * @param id_user - The ID of the user.
   * @param token - The authorization token for the request.
   * @param roles - The roles to be removed from the user.
   * @returns {Observable<any>} - An observable containing the response of the role removal request.
   */
  removeRolesFromUser(id_user: any, token: any, roles: any): any {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http.delete(
      `${environment.baseUrlAdmin}${environment.realmClient}/users/${id_user}/role-mappings/realm`,
      {
        headers: headers,
        body: roles,
      }
    );
  }

  updateUserAccount(
    username: string,
    userData: any,
    selectedRole: any
  ): Observable<any> {
    return this.findUserByUsername(username).pipe(
      switchMap((users) => {
        if (users.length === 0) {
          return throwError(
            () =>
              new Error(`Utilisateur avec le username ${username} non trouvé`)
          );
        }

        const userId = users[0].id;
        const token = this.keycloakService.getToken();

        return this.getRealmRolesByUser(token, userId).pipe(
          switchMap((assignedRoles: any[]) => {
            const nonDefaultRoles = assignedRoles.filter(
              (role) => !this.isDefaultRole(role)
            );
            const roleToRemove =
              nonDefaultRoles.length > 0 ? nonDefaultRoles[0] : null;

            if (roleToRemove) {
              return this.removeRoleFromUser(
                userId,
                token,
                roleToRemove.name
              ).pipe(
                switchMap(() => {
                  return this.assignRealmRolesToUser(userId, token, [
                    selectedRole,
                  ]);
                })
              );
            } else {
              return this.assignRealmRolesToUser(userId, token, [selectedRole]);
            }
          }),
          switchMap(() => {
            return this.http.put(
              `${environment.baseUrlAdmin}${environment.realmClient}/users/${userId}`,
              userData,
              {
                headers: new HttpHeaders({
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                }),
              }
            );
          }),
          catchError((error) => {
            console.error("Error updating user:", error);
            return throwError(() => new Error("Failed to update user"));
          })
        );
      })
    );
  }

  isDefaultRole(role: any): boolean {
    const defaultRoleNames = [
      "offline_access",
      "uma_authorization",
      "view-profile",
      "default-roles-quarkus",
    ];
    return defaultRoleNames.includes(role.name);
  }

  getRealmRoleIdByName(
    roleName: string,
    token: string
  ): Observable<string | null> {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });

    return this.http
      .get<any[]>(
        `${environment.baseUrlAdmin}${environment.realmClient}/roles`,
        { headers: headers }
      )
      .pipe(
        map((roles) => {
          const role = roles.find((r) => r.name === roleName);
          return role ? role.id : null;
        }),
        catchError((error) => {
          console.error("Error fetching roles:", error);
          return throwError(() => new Error("Failed to fetch roles"));
        })
      );
  }

  removeRoleFromUser(
    id_user: string,
    token: string,
    roleName: string
  ): Observable<any> {
    return this.getRealmRoleIdByName(roleName, token).pipe(
      switchMap((roleId) => {
        if (roleId) {
          const headers = new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          });

          const roleData = [
            {
              id: roleId,
              name: roleName,
            },
          ];

          console.log(
            "Sending DELETE request to URL:",
            `${environment.baseUrlAdmin}${environment.realmClient}/users/${id_user}/role-mappings/realm`
          );
          console.log("Request body:", roleData);

          return this.http
            .request(
              "DELETE",
              `${environment.baseUrlAdmin}${environment.realmClient}/users/${id_user}/role-mappings/realm`,
              {
                headers: headers,
                body: roleData,
              }
            )
            .pipe(
              catchError((error) => {
                console.error(`Error removing role '${roleName}':`, error);
                console.error("Response status:", error.status);
                console.error("Response body:", error.error);
                return throwError(
                  () => new Error(`Failed to remove role '${roleName}'`)
                );
              })
            );
        } else {
          return throwError(() => new Error(`Role '${roleName}' not found`));
        }
      })
    );
  }

  updateUserInLocalDB(username: string, userData: any): Observable<any> {
    const url = `${BASE_API_USER}/update/${username}`;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    return this.http.put(url, userData, { headers }).pipe(
      catchError((error) => {
        console.error("Error updating user in local DB:", error);
        return throwError(() => new Error("Error updating user in local DB"));
      })
    );
  }

  getLocalUserDetailsByUsername(username: string): Observable<any> {
    const url = `${BASE_API_USER}/get/${username}`;
    return this.http.get<any>(url);
  }

  getLocalUserInscriptionByUsername(username: string): Observable<any> {
    const url = `${BASE_API_INSCRIPTION}/user/${username}`;
    return this.http.get<any>(url);
  }

  getAllAlerts(): Observable<any> {
    //return this.http.get<any>(`${BASE_API_ALERTS}`);
    return this.http.get<any>(`${BASE_API_INSCRIPTION + ALERT}`);
  }

  getUserIdByUsername(username: string): Observable<number> {
    const url = `${BASE_API_USER}/getIdUser/${username}`;
    return this.http.get<number>(url);
  }

  createInscription(inscription: any): Observable<any> {
    const url = `${BASE_API_INSCRIPTION}/add`;
    return this.http.post<any>(url, inscription);
  }

  getAlertIdByLibelle(libelle: string): Observable<number> {
    const encodedLibelle = encodeURIComponent(libelle);
    const params = new HttpParams().set("libelle", encodedLibelle);
    return this.http.get<number>(
      `${BASE_API_INSCRIPTION + ALERT}/AlertIdByLibelle`,
      { params }
    );
  }

  deleteInscription(id: number): Observable<void> {
    const url = `${BASE_API_INSCRIPTION}/${id}`;
    return this.http.delete<void>(url);
  }

  getAlertById(id: number): Observable<any> {
    return this.http.get<any>(`${BASE_API_INSCRIPTION + ALERT}/${id}`);
  }

  updateInscription(id: number, inscriptionData: any): Observable<any> {
    const url = `${BASE_API_INSCRIPTION}/${id}`;
    return this.http.put<any>(url, inscriptionData);
  }

  getInscriptionById(id: number): Observable<any> {
    const url = `${BASE_API_INSCRIPTION}/${id}`;
    return this.http.get<any>(url);
  }
}
