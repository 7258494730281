// breadcrumb.component.ts
import { Component } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { RouteInfo } from 'app/shared/vertical-menu/vertical-menu.metadata';
import { ROUTES } from 'app/shared/vertical-menu/vertical-menu-routes.config';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent  {
  public breadcrumbs;
  public title: string;
  role: string;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) {
  
                this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .pipe(map(() => this.activatedRoute))
                .pipe(map((route) => {
                  while (route.firstChild) {
                    route = route.firstChild;
                  }
                  return route;
                }))
                .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
                .subscribe(route => {
                  const parent = route.parent.snapshot.data.breadcrumb;
                  const child = route.snapshot.data.breadcrumb;                  
                  this.breadcrumbs = {};
                  this.breadcrumbs = {
                    parentBreadcrumb: parent,
                    childBreadcrumb: child
                  };
                });
            }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
