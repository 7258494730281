<div class="rt-tracking-map" style="width: 100%; height: 400px"></div>

<div class="dashboard-container mt-3">
    <div class="dashboard-triangle">
        <div id="fuel-gauge" class="tracking-map"></div>
        <!--
        Vehicle Real Time Tracking data display between gauges
         -->
        <div class="tracking-data">
            <div class="tracking-data-item">
                <span class="tracking-data-item-title">{{ 'VEHICLE_REGISTRATION' | translate }}</span>
                <span class="tracking-data-item-value">{{ vehicle.registration }}</span>
                <span class="tracking-data-item-title">{{ 'VEHICLE_TYPE' | translate }}</span>
                <span class="tracking-data-item-value">{{ vehicle.family.familyName }}</span>
                <span class="tracking-data-item-title">{{ 'VEHICLE_POSITION' | translate }}</span>
                <span class="tracking-data-item-value">
                    {{ (rtData?.lat | number:'1.5-5') + ', ' + (rtData?.lon | number:'1.5-5') }}
                </span>
                <span class="tracking-data-item-title">Date</span>
                <span class="tracking-data-item-value">{{ rtData?.date | date: 'dd/MM/yyyy HH:mm:ss' }}</span>
            </div>

        </div>
        <div id="speed-gauge" class="speed-gauge"></div>
        <div id="rpm-gauge" class="rpm-gauge"></div>
        <div id="temperature-gauge" class="temperature-gauge"></div>

    </div>
</div>