import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  NgbActiveModal,
  NgbDateStruct,
  NgbModal,
  NgbTimeStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { StationsFormComponent } from "app/modules/operating-networks/stations-management/stations-form/stations-form.component";
import { ItinerayMapComponent } from "app/shared/components/itineray-map/itineray-map.component";
import { OPN_BASE_URL } from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { DragulaService } from "ng2-dragula";
import { forkJoin, Observable, Subscription } from "rxjs";
import { mergeMap } from "rxjs/operators";

interface Station {
  id: number;
  name: string;
  type: string;
  lat: string;
  lon: string;
  status: number;
  order?: number;
  distance?: number;
  duration?: number;
  stoppingTime?: number;
  itineraryId?: number;
  itineraryStationId?: number;
}

@Component({
  selector: "app-rent-modal",
  templateUrl: "./rent-modal.component.html",
  styleUrls: ["./rent-modal.component.scss"],
})
export class RentModalComponent implements OnInit {
  @Output() listUpdated: EventEmitter<void> = new EventEmitter<void>();
  @ViewChildren(ItinerayMapComponent)
  mapComponents: QueryList<ItinerayMapComponent>;
  @Input() itemId;
  @Input() rentLength;

  submitted: boolean = false;
  rentForm: FormGroup;
  rents: Station[] = [];
  subs: Subscription[] = [];
  showSelectList: boolean = false;
  stations = [];
  blocks: any[] = [];
  time = { hour: 0, minute: 0 }; // Default time or can be set dynamically
  selectedDate: any;
  datePipe: DatePipe;
  dragDisabled = true;
  showMap: boolean = false;

  constructor(
    private dragulaService: DragulaService,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private crudService: CrudService,
    private fb: FormBuilder,
    datePipe: DatePipe
  ) {
    this.datePipe = datePipe;
    this.rentForm = this.fb.group({
      blocks: this.fb.array([]),
      client: ["", Validators.required],
      rentDate: ["", Validators.required],
      rentTime: [{ hour: 0, minute: 0 }, Validators.required],
      rentDateTime: [null],
      nbrOfPassenger: ["", Validators.required],
      nbrOfBus: ["", Validators.required],
      distance: ["", Validators.required],
      duration: ["", Validators.required],
    });

    this.addBlock();
  }

    /**
 * Initializes the component and sets up form value change listeners to update date and time.
 * It also loads the station list and, if an itemId is provided, loads rent data for that ID.
 */

    ngOnInit(): void {
    
      this.rentForm.get("rentDate").valueChanges.subscribe(() => {
        this.updateDateTime();
      });
  
      this.rentForm.get("rentTime").valueChanges.subscribe(() => {
        this.updateDateTime();
      });
      this.rentForm
        .get("rentDate")
        .valueChanges.subscribe(() => this.updateFirstBlockMinDate());
      this.rentForm
        .get("rentTime")
        .valueChanges.subscribe(() => this.updateFirstBlockMinTime());
  
      this.getStationList();
      if (this.itemId) {
        this.loadRentData(this.itemId);
      }
    }
  
  /**
 * Handles the date selection event and updates the combined date and time field.
 * @param {any} date - The selected date object.
 */

  onDateSelect(date: any) {
    this.updateDateTime();
  }

  /**
 * Updates the combined rent date and time by fetching values from the form.
 * If both the date and time are present, they are combined into a single Date object.
 */

  updateDateTime() {
    const date = this.rentForm.get("rentDate").value;
    const time = this.rentForm.get("rentTime").value;
    if (date && time) {
      const combinedDateTime = new Date(
        date.year,
        date.month - 1,
        date.day,
        time.hour,
        time.minute
      );
      this.rentForm.get("rentDateTime").setValue(combinedDateTime);
    }
  }
  
  /**
 * Updates the trip's date and time for the specified block index.
 * Combines the selected date and time into a Date object and updates the tripDateTime field.
 * @param {number} blockIndex - The index of the block being updated.
 */

  updateTripDateTime(blockIndex: number) {
    const blockControl = this.getBlockFormGroup(blockIndex);
    const date = blockControl.get("tripDate").value;
    const time = blockControl.get("tripTime").value;
    if (date && time) {
      const combinedDateTime = new Date(
        date.year,
        date.month - 1,
        date.day,
        time.hour,
        time.minute
      );
      blockControl.get("tripDateTime").setValue(combinedDateTime);
    }
  }

  /**
 * Updates the minimum date for the first block's trip date based on the rent date.
 * Ensures that the trip date is not earlier than the rent date.
 */

  updateFirstBlockMinDate() {
    const rentDate = this.rentForm.get("rentDate").value;

    // Cast 'blocks' to FormArray
    const blocksArray = this.rentForm.get("blocks") as FormArray;
    const firstBlock = blocksArray.at(0);

    if (rentDate && firstBlock) {
      const tripDate = firstBlock.get("tripDate").value;
      if (
        !tripDate ||
        tripDate.year < rentDate.year ||
        (tripDate.year === rentDate.year && tripDate.month < rentDate.month) ||
        (tripDate.year === rentDate.year &&
          tripDate.month === rentDate.month &&
          tripDate.day < rentDate.day)
      ) {
        firstBlock.get("tripDate").setValue(null);
      }
    }
  }
  
  /**
 * Updates the minimum time for the first block's trip time based on the rent time.
 * Ensures that the trip time is not earlier than the rent time.
 */

  updateFirstBlockMinTime() {
    const rentTime = this.rentForm.get("rentTime").value;

    // Cast 'blocks' to FormArray
    const blocksArray = this.rentForm.get("blocks") as FormArray;
    const firstBlock = blocksArray.at(0);

    if (rentTime && firstBlock) {
      const tripTime = firstBlock.get("tripTime").value;
      if (
        !tripTime ||
        tripTime.hour < rentTime.hour ||
        (tripTime.hour === rentTime.hour && tripTime.minute < rentTime.minute)
      ) {
        firstBlock
          .get("tripTime")
          .setValue({ hour: rentTime.hour, minute: rentTime.minute });
      }
    }
  }
  
  /**
 * Validates if the selected date for the first block is valid based on the rent date.
 * @param {NgbDateStruct} date - The selected date object.
 * @returns {boolean} True if the date is valid, false otherwise.
 */

  isFirstBlockDateValid = (date: NgbDateStruct): boolean => {
    const rentDate = this.rentForm.get("rentDate").value;
    if (!rentDate) return true;

    return (
      date.year > rentDate.year ||
      (date.year === rentDate.year && date.month > rentDate.month) ||
      (date.year === rentDate.year &&
        date.month === rentDate.month &&
        date.day >= rentDate.day)
    );
  };
  
  /**
 * Validates if the selected time for the first block is valid based on the rent time and date.
 * Ensures that the time is not earlier than the rent time if the dates are the same.
 * @param {NgbTimeStruct} time - The selected time object.
 * @returns {boolean} True if the time is valid, false otherwise.
 */

  isFirstBlockTimeValid = (time: NgbTimeStruct): boolean => {
    const rentDate = this.rentForm.get("rentDate").value;
    const rentTime = this.rentForm.get("rentTime").value;

    // Cast 'blocks' to FormArray
    const blocksArray = this.rentForm.get("blocks") as FormArray;
    const tripDate = blocksArray.at(0).get("tripDate").value;

    if (!rentDate || !rentTime || !tripDate) return true;

    if (
      tripDate.year === rentDate.year &&
      tripDate.month === rentDate.month &&
      tripDate.day === rentDate.day
    ) {
      return (
        time.hour > rentTime.hour ||
        (time.hour === rentTime.hour && time.minute >= rentTime.minute)
      );
    }

    return true;
  };
 
  /**
 * Cleans up any resources and subscriptions when the component is destroyed.
 * Destroys all Dragula groups and unsubscribes from any event subscriptions.
 */

  ngOnDestroy(): void {
    this.cleanupDragulaGroups();
  }
 
  /**
 * Destroys Dragula groups for block elements and unsubscribes from drag-and-drop events.
 */

  cleanupDragulaGroups(): void {
    this.blocks.forEach((_, index) => {
      const groupName = `DRAGULA_FACTS_${index}`;
      if (this.dragulaService.find(groupName)) {
        this.dragulaService.destroy(groupName);
      }
    });
    this.subs.forEach((sub) => sub.unsubscribe());
    this.subs = [];
  }
  
  /**
 * Gets the blocks form array from the rent form.
 * @returns {FormArray} The blocks form array.
 */

  get blocksFormArray(): FormArray {
    return this.rentForm.get("blocks") as FormArray;
  }
  
  /**
 * Adds a new block to the form. Initializes the new block with default values and
 * creates a new Dragula group for handling drag-and-drop within that block.
 */

  addBlock() {
    const newBlock = this.fb.group({
      itineraryStations: this.fb.array([], this.validateAtLeastOneStation),
      empty: [false],
      tripDate: ["", Validators.required],
      tripTime: [{ hour: 0, minute: 0 }, Validators.required],
      tripDateTime: [null],
      nbrOfNights: ["", Validators.required],
      tripId: [null],
      itineraryId: [null],
    });

    this.blocksFormArray.push(newBlock);
    this.blocks.push({ rents: [], showSelectList: false, showMap: false });

  
    const blockIndex = this.blocks.length - 1;
    this.dragulaService.createGroup(`DRAGULA_FACTS_${blockIndex}`, {
      removeOnSpill: true,
    });

   
    const subDropModel = this.dragulaService
      .dropModel(`DRAGULA_FACTS_${blockIndex}`)
      .subscribe((data: any) => {
        this.blocks[blockIndex].rents = data.sourceModel;
        this.reorderStations(blockIndex);
      });

   
    this.subs.push(subDropModel);
  } 

  /**
 * Removes a block at the specified index, deletes the trip and itinerary from the database if they exist,
 * and updates the order of the remaining blocks in the form.
 *
 * @param index the index of the block to remove
 */

  removeBlock(index: number) {
     // Retrieve the form group for the block at the specified index
    const blockControl = this.getBlockFormGroup(index);
    const tripId = blockControl.get("tripId").value;
    const itineraryId = blockControl.get("itineraryId").value;

    // Delete the trip from the database if tripId exists
    if (tripId) {
      this.crudService.delete(OPN_BASE_URL + "/trip/delete", tripId).subscribe(
        () => {
          console.log("Trip deleted successfully");
        },
        (error) => {
          console.error("Error deleting trip", error);
        }
      );
    }

    // Delete the itinerary from the database if itineraryId exists
    if (itineraryId) {
      this.crudService
        .delete(OPN_BASE_URL + "/itinerary/delete", itineraryId)
        .subscribe(
          () => {
            console.log("Itinerary deleted successfully");
          },
          (error) => {
            console.error("Error deleting itinerary", error);
          }
        );
    }

   // Remove the block from the form and update the order of the remaining blocks
    this.blocksFormArray.removeAt(index);
    this.blocks.splice(index, 1);

    this.blocks.forEach((block, i) => {
      const blockControl = this.getBlockFormGroup(i);
      blockControl.patchValue({ ordre: i });
    });
  }

 /**
 * Creates a form group for a given station with validation rules.
 *
 * @param station the station for which the form group is created
 * @return a FormGroup representing the station form
 */

  addStationFormGroup(station: Station): FormGroup {
    return this.fb.group({
      stationId: [station.id, Validators.required],
      stationOrder: [station.order ? station.order : 0, Validators.required],
      stationName: [station.name ? station.name : "", Validators.required],
      distance: [station.distance ? station.distance : 0, Validators.required],
      duration: [station.duration ? station.duration : 0, Validators.required],
      stoppingTime: [station.stoppingTime, Validators.required],
    });
  }
 
  /**
 * Validates that at least one station is present in the provided form control.
 *
 * @param control the form control to validate
 * @return a map containing an error if no stations are present, or null if validation passes
 */

  // validateAtLeastOneStation(
  //   control: AbstractControl
  // ): { [key: string]: boolean } | null {
  //   const formArray = control as FormArray;
  //   return formArray.length > 1 ? null : { noStations: true };
  // }

  validateAtLeastOneStation(control: AbstractControl): { [key: string]: boolean } | null {
    if (this.submitted) {
      const formArray = control as FormArray;
      return formArray.length > 1 ? null : { noStations: true };
    }
    return null;
  }
 
  /**
 * Adds a placeholder station to the block at the specified index.
 *
 * @param blockIndex the index of the block where the placeholder station will be added
 */

  addPlaceholderStation(blockIndex: number) {
    const placeholder: Station = {
      id: 0,
      name: "",
      type: "",
      lat: "",
      lon: "",
      status: 0,
      order: this.blocks[blockIndex].rents.length + 1,
      distance: 0,
      duration: 0,
    };
    this.blocks[blockIndex].rents.push(placeholder);
    this.getItineraryStations(blockIndex).push(
      this.addStationFormGroup(placeholder)
    );
  }
  
  /**
 * Removes the placeholder station from the block at the specified index.
 *
 * @param blockIndex the index of the block from which the placeholder station will be removed
 */

  removePlaceholderStation(blockIndex: number) {
    const block = this.blocks[blockIndex];
    if (block.rents.length && !block.rents[block.rents.length - 1].name) {
      block.rents.pop();
      this.getItineraryStations(blockIndex).removeAt(
        this.getItineraryStations(blockIndex).length - 1
      );
    }
  }
  
  /**
 * Retrieves the list of available stations from the database.
 */

  getStationList() {
    this.crudService
      .getAll<any>(OPN_BASE_URL + "/stations")
      .subscribe((data) => {
        this.stations = data.data;
      });
  }
  
  /**
 * Removes the station at the specified index from the block and updates the map and station order.
 *
 * @param index the index of the station to remove
 * @param blockIndex the index of the block from which the station is removed
 */

  removeStation(index: number, blockIndex: number) {
    this.blocks[blockIndex].rents.splice(index, 1);
    this.getItineraryStations(blockIndex).removeAt(index);
    const stations = this.blocks[blockIndex].rents.map((station) => ({
      lat: parseFloat(station.lat),
      lon: parseFloat(station.lon),
    }));
  
    this.mapComponents.toArray()[blockIndex].updateMap(stations);

    this.reorderStations(blockIndex);
  }
  
  /**
 * Reorders the stations within the block and updates the form group for each station.
 *
 * @param blockIndex the index of the block whose stations will be reordered
 * @param stoppingTimes an optional list of stopping times to maintain the previous stopping times
 */

  reorderStations(blockIndex: number, stoppingTimes: number[] = []) {
    const block = this.blocks[blockIndex];
    const itineraryStations = this.getItineraryStations(blockIndex);

    block.rents.forEach((station, index) => {
      station.order = index + 1;
    });

    const previousStoppingTimes = stoppingTimes.length
      ? stoppingTimes
      : this.trackStoppingTimes(blockIndex);
    itineraryStations.clear();

    block.rents.forEach((station, index) => {
      const stationFormGroup = this.addStationFormGroup(station);
      if (previousStoppingTimes[index] !== undefined) {
        stationFormGroup
          .get("stoppingTime")
          .setValue(previousStoppingTimes[index]);
      }
      itineraryStations.push(stationFormGroup);
    });

    const stations = block.rents.map((station) => ({
      lat: parseFloat(station.lat),
      lon: parseFloat(station.lon),
    }));

  
    this.mapComponents.toArray()[blockIndex].updateMap(stations);
  }
  
  /**
 * Tracks the stopping times for the stations within the block.
 *
 * @param blockIndex the index of the block whose stopping times will be tracked
 * @return an array of stopping times for the block
 */

  trackStoppingTimes(blockIndex: number) {
    const itineraryStations = this.getItineraryStations(blockIndex);
    return itineraryStations.controls.map(
      (control: FormGroup) => control.get("stoppingTime").value
    );
  }
  
  /**
 * Adds a station to the block and updates the form group and map.
 *
 * @param stationName the name of the station to add
 * @param blockIndex the index of the block to which the station will be added
 */

  addStation(stationName: string, blockIndex: number) {
    const foundStation = this.stations.find(
      (station) => station.name === stationName
    );
    if (!foundStation) {
      return;
    }

    const stationData = {
      id: foundStation.id,
      name: stationName,
      type: foundStation.type,
      lat: foundStation.lat,
      lon: foundStation.lon,
      status: foundStation.status,
      order: this.blocks[blockIndex].rents.length + 1,
      distance: 0,
      duration: 0,
      stoppingTime: foundStation.stoppingTime || 0,
    };

    const currentStoppingTimes = this.trackStoppingTimes(blockIndex);

    const block = this.blocks[blockIndex];
    const itineraryStations = this.getItineraryStations(blockIndex);

    if (block.rents.length && !block.rents[block.rents.length - 1].name) {
      const lastStation = block.rents[block.rents.length - 1];
      Object.assign(lastStation, stationData);
      const stationFormGroup = itineraryStations.at(
        itineraryStations.length - 1
      ) as FormGroup;

      stationFormGroup.patchValue({
        stationId: stationData.id,
        stationOrder: stationData.order,
        stationName: stationData.name,
        distance: stationData.distance,
        duration: stationData.duration,
        stoppingTime: stationData.stoppingTime,
      });
    } else {
      block.rents.push(stationData);
      itineraryStations.push(this.addStationFormGroup(stationData));
    }

    const stations = block.rents.map((station) => ({
      lat: parseFloat(station.lat),
      lon: parseFloat(station.lon),
    }));

  
    this.mapComponents.toArray()[blockIndex].updateMap(stations);

    block.showSelectList = false;
    this.reorderStations(blockIndex, currentStoppingTimes);
  }
 
  /**
 * Handles the calculation of distances and durations for the legs of a route 
 * and updates the itinerary stations with the calculated values.
 * 
 * @param legsData   An array of objects containing the distance (in meters) 
 *                   and duration (in seconds) for each leg of the route.
 * @param blockIndex The index of the block (trip) being updated in the form array.
 */

  onRouteCalculated(
    legsData: { distance: number; duration: number }[],
    blockIndex: number
  ): void {
    const itineraryStations = this.getItineraryStations(blockIndex);
    itineraryStations.at(0).patchValue({ distance: 0, duration: 0 });

    for (let i = 0; i < legsData.length; i++) {
      const stationFormGroup = itineraryStations.at(i + 1) as FormGroup;
      const distanceInKm = (legsData[i].distance / 1000).toFixed(2);
      const durationInMin = (legsData[i].duration / 60).toFixed(2);
      stationFormGroup.patchValue({
        distance: parseFloat(distanceInKm),
        duration: parseFloat(durationInMin),
      });
    }
  }
  
  /**
 * Retrieves the form group for a specific block (trip) in the form array.
 * 
 * @param index The index of the block to retrieve.
 * @return The FormGroup representing the block (trip) at the specified index.
 */

  getBlockFormGroup(index: number): FormGroup {
    return this.blocksFormArray.at(index) as FormGroup;
  }
  
  /**
 * Retrieves the itinerary stations FormArray for a specific block (trip).
 * 
 * @param blockIndex The index of the block to retrieve the itinerary stations from.
 * @return The FormArray representing the itinerary stations.
 */

  getItineraryStations(blockIndex: number): FormArray {
    return this.getBlockFormGroup(blockIndex).get(
      "itineraryStations"
    ) as FormArray;
  }
  
  /**
 * Calculates the total distance for a given block (trip) by summing the 
 * distances of all itinerary stations.
 * 
 * @param blockIndex The index of the block to calculate the total distance for.
 * @return A string representing the total distance in kilometers, formatted to two decimal places.
 */

  getTotalDistance(blockIndex: number): string {
    const itineraryStations = this.getItineraryStations(blockIndex);
    const distanceInKm = itineraryStations.value.reduce(
      (acc: number, station: any) => acc + (station.distance || 0),
      0
    );
    return distanceInKm.toFixed(2);
  }
  
  /**
 * Calculates the total duration for a given block (trip) by summing the 
 * durations of all itinerary stations, including stopping times.
 * 
 * @param blockIndex The index of the block to calculate the total duration for.
 * @return A string representing the total duration in minutes, formatted to two decimal places.
 */

  getTotalDuration(blockIndex: number): string {
    const itineraryStations = this.getItineraryStations(blockIndex);
    const durationInMin = itineraryStations.value.reduce(
      (acc: number, station: any) =>
        acc + (station.duration || 0) + (station.stoppingTime || 0),
      0
    );
    return durationInMin.toFixed(2);
  }

  /**
 * Calculates the total duration for a given block (trip) in hours and minutes.
 * If the total duration is less than 60 minutes, it returns the duration in minutes.
 * 
 * @param blockIndex The index of the block to calculate the total duration for.
 * @return A string representing the total duration in hours and minutes or just minutes.
 */

  getTotalDurationInHoursAndMinutes(blockIndex: number): string {
    const itineraryStations = this.getItineraryStations(blockIndex);
    const durationInMin = itineraryStations.value.reduce(
      (acc: number, station: any) =>
        acc + (station.duration || 0) + (station.stoppingTime || 0),
      0
    );

    if (durationInMin >= 60) {
      const hours = Math.floor(durationInMin / 60);
      const minutes = durationInMin % 60;
      return `${hours}h ${minutes.toFixed(0)}min`;
    } else {
      return `${durationInMin.toFixed(0)}min`;
    }
  }
  
  /**
 * Toggles the display of the station select list for a specific block (trip). 
 * If the select list is not currently shown, a placeholder station is added. 
 * If the select list is shown, the placeholder station is removed.
 * 
 * @param blockIndex The index of the block to toggle the select list for.
 */

  toggleSelectList(blockIndex: number) {
    const block = this.blocks[blockIndex];
    if (!block.showSelectList) {
      this.addPlaceholderStation(blockIndex);
    } else {
      this.removePlaceholderStation(blockIndex);
    }
    block.showSelectList = !block.showSelectList;
  }
 
  /**
 * Retrieves the form controls from the rent form.
 * 
 * @return The controls of the rent form.
 */

  get r() {
    return this.rentForm.controls;
  }
  
  /**
 * Opens a modal window to add a new station. After the modal is closed, 
 * it refreshes the list of stations.
 */

  AddNewStation() {
    const modalRef = this.modalService.open(StationsFormComponent, {
      size: "xl",
    });
    modalRef.componentInstance.isModal = true;
    modalRef.componentInstance.closeModal.subscribe(() => {
      this.getStationList();
      modalRef.close();
    });
  }
 
  /**
 * Loads rent data from the server and populates the rent form with the retrieved values.
 * Additionally, it loads the associated trips for the rent.
 * 
 * @param rentId The ID of the rent to load.
 */

  loadRentData(rentId: string) {
    this.crudService.getOne(OPN_BASE_URL + "/rent", rentId).subscribe(
      (rentData: any) => {
        const rentDateTime = new Date(rentData.rentDate);

        this.rentForm.patchValue({
          client: rentData.client,
          rentDate: {
            year: rentDateTime.getFullYear(),
            month: rentDateTime.getMonth() + 1,
            day: rentDateTime.getDate(),
          },
          rentTime: {
            hour: rentDateTime.getHours(),
            minute: rentDateTime.getMinutes(),
          },

          nbrOfPassenger: rentData.nbrOfPassenger,
          nbrOfBus: rentData.nbrOfBus,
        });

        // Charger les trips (blocs)
        this.loadTrips(rentId);
      },
      (error) => {
        console.error("Error loading rent data", error);
      }
    );
  }
  

/**
 * Parses a date string in ISO format (yyyy-MM-ddTHH:mm:ss) and extracts 
 * the year, month, and day components.
 * 
 * @param dateString The date string to parse.
 * @return An object containing the year, month, and day.
 */

  parseDate(dateString: string): { year: number; month: number; day: number } {
    const datePart = dateString.split("T")[0]; // Split the string to get the date part before 'T'
    const [year, month, day] = datePart.split("-").map(Number); // Split the date part by '-' and convert to number

    return {
      year: year,
      month: month,
      day: day,
    };
  }
  
  /**
 * Loads trips for the specified rent ID and updates the form with trip details.
 *
 * @param rentId the ID of the rent for which to load trips
 */

  loadTrips(rentId: string) {
    this.crudService.getAll(OPN_BASE_URL + "/trip/by-rent/" + rentId).subscribe(
      (trips: any[]) => {
        trips.forEach((trip, index) => {
          if (index > 0) {
            this.addBlock(); // Ajouter un nouveau bloc pour chaque trip supplémentaire
          }
          // const parsedDate = trip.date ? this.parseDate(trip.date) : null;
          const tripDateTime = new Date(trip.date);
          const blockControl = this.blocksFormArray.at(index) as FormGroup;
          blockControl.patchValue({
            tripId: trip.id,
            tripDate: {
              year: tripDateTime.getFullYear(),
              month: tripDateTime.getMonth() + 1,
              day: tripDateTime.getDate(),
            },
            tripTime: {
              hour: tripDateTime.getHours(),
              minute: tripDateTime.getMinutes(),
            },
            // date: parsedDate,
            empty: trip.empty === 1,
            nbrOfNights: trip.nbrOfNights,
          });

          this.loadItinerary(trip.id, index);
        });
      },
      (error) => {
        console.error("Error loading trips", error);
      }
    );
  }
  
    /**
   * Loads the itinerary for the specified trip ID and updates the block form.
   *
   * @param tripId the ID of the trip for which to load the itinerary
   * @param blockIndex the index of the block form to update
   */

  loadItinerary(tripId: string, blockIndex: number) {
    this.crudService
      .getAll(OPN_BASE_URL + "/itinerary/by-trip/" + tripId)
      .subscribe(
        (itineraries: any[]) => {
          if (itineraries.length > 0) {
            const itinerary = itineraries[0];
            const blockControl = this.blocksFormArray.at(
              blockIndex
            ) as FormGroup;

            blockControl.patchValue({
              itineraryId: itinerary.id,
              distance: itinerary.distance,
              duration: itinerary.avgDuration,
            });

            this.loadItineraryStations(itinerary.id, blockIndex);
          }
        },
        (error) => {
          console.error("Error loading itinerary", error);
        }
      );
  }
  
    /**
   * Loads itinerary stations for the specified itinerary ID and updates the block form.
   *
   * @param itineraryId the ID of the itinerary for which to load stations
   * @param blockIndex the index of the block form to update
   */

  loadItineraryStations(itineraryId: string, blockIndex: number) {
    this.crudService
      .getAll(OPN_BASE_URL + "/itinerary-station/by-itinerary/" + itineraryId)
      .subscribe(
        (itineraryStations: any[]) => {
          const formItineraryStations = this.getItineraryStations(blockIndex);
          formItineraryStations.clear();

          const stationPromises = itineraryStations.map((itineraryStation) =>
            this.crudService
              .getOne<Station>(
                OPN_BASE_URL + "/stations",
                itineraryStation.stationId
              )
              .toPromise()
          );
          Promise.all(stationPromises).then((stationDetails) => {
            const stationsData = itineraryStations.map(
              (itineraryStation, index) => {
                const stationDetail = stationDetails[index] as Station;
                return {
                  id: itineraryStation.stationId,
                  name: stationDetail.name,
                  order: itineraryStation.stationOrder,
                  distance: itineraryStation.distance,
                  duration: itineraryStation.duration,
                  stoppingTime: itineraryStation.stoppingTime,
                  type: stationDetail.type || "",
                  lat: stationDetail.lat,
                  lon: stationDetail.lon,
                  status: stationDetail.status || 0,
                };
              }
            );

            stationsData.forEach((station) => {
              formItineraryStations.push(this.addStationFormGroup(station));
            });

            this.blocks[blockIndex].rents = stationsData;
            const mapStations = stationsData.map((station) => ({
              lat: parseFloat(station.lat),
              lon: parseFloat(station.lon),
            }));

            // Mettre à jour la carte
            this.mapComponents.toArray()[blockIndex].updateMap(mapStations);

            this.reorderStations(blockIndex);
          });
        },
        (error) => {
          console.error("Error loading itinerary stations", error);
        }
      );
  }
    /**
   * Saves the form data and handles validation checks.
   */

  saveForm() {
    this.submitted = true;

  if (
    this.rentForm.get("client").invalid ||
    this.rentForm.get("rentDate").invalid ||
    this.rentForm.get("nbrOfPassenger").invalid ||
    this.rentForm.get("nbrOfBus").invalid
  ) {
    return;
  }

  let isValid = true;
  this.blocksFormArray.controls.forEach((block: FormGroup, index: number) => {
    if (
      block.get("nbrOfNights").invalid ||
      block.get("tripDateTime").invalid
    ) {
      isValid = false;
    }
    
    // Check stoppingTime for each station
    const stations = block.get("itineraryStations") as FormArray;
    if (stations.length < 2) {
      isValid = false;
    }
    stations.controls.forEach((station: FormGroup, stationIndex: number) => {
      if (station.get("stoppingTime").invalid) {
        isValid = false;
      }
    });
  });

  // Ajout de cette condition pour bloquer la sauvegarde si le formulaire n'est pas valide
  if (!isValid) {
    console.error('Form validation failed. Please check all fields and ensure each block has at least two stations.');
    return;
  }

  

    const dateTime = this.rentForm.value.rentDateTime;
    const formattedDateTime = this.datePipe.transform(
      dateTime,
      "yyyy-MM-dd'T'HH:mm:ss"
    );

    const rentData = {
      client: this.rentForm.get("client").value,
      rentDate: formattedDateTime,
      nbrOfPassenger: this.rentForm.get("nbrOfPassenger").value,
      nbrOfBus: this.rentForm.get("nbrOfBus").value,
      distance: this.getTotalDistanceAllBlocks(),
      duration: this.getTotalDurationAllBlocks(),
      name: "location",
    //  number: this.itemId ? undefined : this.rentLength+1,
    };

    const saveOrUpdateObservable = this.itemId
      ? this.crudService.update(
          OPN_BASE_URL + "/rent/update",
          this.itemId,
          rentData
        )
      : this.crudService.post(OPN_BASE_URL + "/rent/add", rentData);

    saveOrUpdateObservable.subscribe(
      (rentResponse: any) => {
        // const rentId = this.itemId || rentResponse.id;
        // const rentCode = `${rentData.name}-${
        //   rentData.number || rentResponse.number
        // }`;

        const rentId = this.itemId || rentResponse.id;
        const rentCode = `${rentData.name}-${rentId}`;
        
        this.saveOrUpdateTrips(rentId, rentCode).then(() => {
          if (this.itemId) {
            // Edition mode
            this.updateTripInstances(rentId).subscribe(
              (tripInstancesResponse) => {
                this.activeModal.close("Updated successfully");
                this.listUpdated.emit();
              },
              (error) => {
                console.error("Error updating trip instances", error);
                this.activeModal.close(
                  "Saved successfully, but failed to update trip instances"
                );
                this.listUpdated.emit();
              }
            );
          } else {
            // Add mode
          
              
          
            this.createTripInstances(rentId).subscribe(
              (tripInstancesResponse) => {
              
                this.activeModal.close("Saved successfully");
                this.listUpdated.emit();
              }
            );
          }
        });
      },
      (error) => {
        console.error("Error saving/updating rent", error);
      }
    );
  }
  
  /**
 * Saves or updates trips and their associated itineraries and itinerary stations.
 * 
 * @param rentId  The ID of the rent.
 * @param rentCode  The code of the rent.
 * @return  A Promise that resolves when all trips, itineraries, and stations are saved or updated.
 */

  saveOrUpdateTrips(rentId: string, rentCode: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const tripPromises = this.blocksFormArray.controls.map(
        (blockControl: FormGroup, blockIndex: number) => {
          return new Promise<void>((resolveTripPromise, rejectTripPromise) => {
            const tripDateTime = blockControl.get("tripDateTime").value;
            const formattedTripDateTime = this.datePipe.transform(
              tripDateTime,
              "yyyy-MM-dd'T'HH:mm:ss"
            );
            const tripData = {
              idRent: rentId,
              date: formattedTripDateTime,
              ordre: blockIndex,
              code: rentCode,
              empty: blockControl.get("empty").value ? 1 : 0,
              nbrOfNights: blockControl.get("nbrOfNights").value,
            };

            const tripId = blockControl.get("tripId")
              ? blockControl.get("tripId").value
              : null;
            const saveOrUpdateTripObservable = tripId
              ? this.crudService.update(
                  OPN_BASE_URL + "/trip/update",
                  tripId,
                  tripData
                )
              : this.crudService.post(OPN_BASE_URL + "/trip/add", tripData);

            saveOrUpdateTripObservable.subscribe(
              (tripResponse: any) => {
                const currentTripId = tripId || tripResponse.id;
                blockControl.patchValue({ tripId: currentTripId });

                const itineraryData = {
                  tripId: currentTripId,
                  distance: parseFloat(this.getTotalDistance(blockIndex)),
                  avgDuration: parseFloat(this.getTotalDuration(blockIndex)),
                };

                const itineraryId = blockControl.get("itineraryId")
                  ? blockControl.get("itineraryId").value
                  : null;
                const saveOrUpdateItineraryObservable = itineraryId
                  ? this.crudService.update(
                      OPN_BASE_URL + "/itinerary/update",
                      itineraryId,
                      itineraryData
                    )
                  : this.crudService.post(
                      OPN_BASE_URL + "/itinerary/add",
                      itineraryData
                    );

                saveOrUpdateItineraryObservable.subscribe(
                  (itineraryResponse: any) => {
                    const currentItineraryId =
                      itineraryId || itineraryResponse.id;
                    blockControl.patchValue({
                      itineraryId: currentItineraryId,
                    });

                    this.saveOrUpdateItineraryStations(
                      currentItineraryId,
                      blockIndex
                    )
                      .then(() => resolveTripPromise())
                      .catch((error) => rejectTripPromise(error));
                  },
                  (error) => {
                    console.error("Error saving/updating itinerary", error);
                    rejectTripPromise(error);
                  }
                );
              },
              (error) => {
                console.error("Error saving/updating trip", error);
                rejectTripPromise(error);
              }
            );
          });
        }
      );

      Promise.all(tripPromises)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  }
  
  /**
 * Saves or updates the itinerary stations for a given itinerary.
 * 
 * @param itineraryId  The ID of the itinerary.
 * @param blockIndex  The index of the form block to retrieve the itinerary stations from.
 * @return  A Promise that resolves when all itinerary stations are saved or updated.
 */

  saveOrUpdateItineraryStations(
    itineraryId: string,
    blockIndex: number
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const itineraryStations = this.getItineraryStations(blockIndex);
      const stationPromises = itineraryStations.controls.map(
        (stationControl: FormGroup) => {
          return new Promise<void>(
            (resolveStationPromise, rejectStationPromise) => {
              const stationData = {
                itineraryId: itineraryId,
                stationId: stationControl.get("stationId").value,
                stationOrder: stationControl.get("stationOrder").value,
                distance: stationControl.get("distance").value,
                duration: stationControl.get("duration").value,
                stoppingTime: stationControl.get("stoppingTime").value,
              };

              const stationId = stationControl.get("id")
                ? stationControl.get("id").value
                : null;
              const saveOrUpdateStationObservable = stationId
                ? this.crudService.update(
                    OPN_BASE_URL + "/itinerary-station/update",
                    stationId,
                    stationData
                  )
                : this.crudService.post(
                    OPN_BASE_URL + "/itinerary-station/add",
                    stationData
                  );

              saveOrUpdateStationObservable.subscribe(
                (stationResponse: any) => {
                  stationControl.patchValue({
                    id: stationId || stationResponse.id,
                  });
                  resolveStationPromise();
                },
                (error) => {
                  console.error(
                    "Error saving/updating itinerary station",
                    error
                  );
                  rejectStationPromise(error);
                }
              );
            }
          );
        }
      );

      Promise.all(stationPromises)
        .then(() => {
          resolve();
        })
        .catch((error) => reject(error));
    });
  }
  
  /**
 * Creates trip instances for a given rent.
 * 
 * @param rentId  The ID of the rent.
 * @return  An Observable that emits the result of creating trip instances.
 */

  createTripInstances(rentId: string): Observable<any> {
    return this.crudService
      .getAll(OPN_BASE_URL + "/trip/by-rent/" + rentId)
      .pipe(
        mergeMap((trips: any[]) => {
          const tripInstanceObservables = trips
            .map((trip) => {
              const nbrOfBus = this.rentForm.get("nbrOfBus").value;
              return Array(nbrOfBus)
                .fill(null)
                .map(() => this.createTripInstance(trip));
            })
            .reduce((acc, curr) => acc.concat(curr), []);
          return forkJoin(tripInstanceObservables);
        })
      );
  }

  /**
 * Creates a trip instance for the given trip.
 * 
 * @param trip the trip object containing trip details such as date and duration
 * @returns an Observable that emits the result of creating a trip instance
 */

  createTripInstance(trip: any): Observable<any> {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const plannedDeparture = new Date(trip.date);
    const durationInMinutes = trip.duration; // Assume duration is in minutes

    // Calculate estimatedArrival based on plannedDeparture and duration
    const estimatedArrival = new Date(
      plannedDeparture.getTime() + durationInMinutes * 60000
    );

    // Get the day of the week in English
    const dayOfWeek = daysOfWeek[plannedDeparture.getDay()];

    // Prepare the trip instance data
    const tripInstanceData = {
      plannedDeparture: this.datePipe.transform(
        plannedDeparture,
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      estimatedArrival: this.datePipe.transform(
        estimatedArrival,
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      status: 1,
      tripRoute: this.rentForm.get("client").value, // Ensure 'name' is the correct form control
      dayOfWeek: dayOfWeek,
      idTrip: trip.id,
      lineDirection: "Loc - " + trip.idRent,
      lineNumber: trip.idRent,
    };

    return this.crudService.post(
      OPN_BASE_URL + "/trips-instance/add",
      tripInstanceData
    );
  }
  
  /**
 * Updates all trip instances for a given rent ID.
 * 
 * @param rentId the ID of the rent for which to update trip instances
 * @returns an Observable that emits when all trip instances have been updated
 */

  updateTripInstances(rentId: string): Observable<any> {
    return this.crudService
      .getAll(OPN_BASE_URL + "/trip/by-rent/" + rentId)
      .pipe(
        mergeMap((trips: any[]) => {
          const tripInstanceUpdateObservables = trips.map((trip) =>
            this.updateTripInstancesForTrip(trip)
          );
          return forkJoin(tripInstanceUpdateObservables);
        })
      );
  }
  
  /**
 * Updates all instances of a given trip.
 * 
 * @param trip the trip object containing trip details
 * @returns an Observable that emits when all trip instances for the given trip have been updated
 */

  updateTripInstancesForTrip(trip: any): Observable<any> {
    return this.crudService
      .getAll(OPN_BASE_URL + "/trips-instance/by-trip/" + trip.id)
      .pipe(
        mergeMap((tripInstances: any[]) => {
          const updateObservables = tripInstances.map((instance) =>
            this.updateTripInstance(instance, trip)
          );
          return forkJoin(updateObservables);
        })
      );
  }
  
  /**
 * Updates a specific trip instance with new trip details.
 * 
 * @param instance the existing trip instance to be updated
 * @param trip the trip object containing updated trip details
 * @returns an Observable that emits the result of the update operation
 */

  updateTripInstance(instance: any, trip: any): Observable<any> {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const plannedDeparture = new Date(trip.date);
    const durationInMinutes = trip.duration;

    const estimatedArrival = new Date(
      plannedDeparture.getTime() + durationInMinutes * 60000
    );
    const dayOfWeek = daysOfWeek[plannedDeparture.getDay()];

    const updatedInstanceData = {
      id: instance.id,
      plannedDeparture: this.datePipe.transform(
        plannedDeparture,
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      estimatedArrival: this.datePipe.transform(
        estimatedArrival,
        "yyyy-MM-dd'T'HH:mm:ss"
      ),
      status: instance.status,
      tripRoute: this.rentForm.get("client").value,
      dayOfWeek: dayOfWeek,

      tripId: trip.id,
    };

    return this.crudService.update(
      OPN_BASE_URL + "/trips-instance/update/rent",
      instance.id,
      updatedInstanceData
    );
  }
 
  /**
 * Calculates the total distance across all blocks.
 * 
 * @returns the total distance as a number
 */

  getTotalDistanceAllBlocks(): number {
    return this.blocks.reduce((total, _, index) => {
      return total + parseFloat(this.getTotalDistance(index));
    }, 0);
  }
 
  /**
 * Calculates the total duration across all blocks.
 * 
 * @returns the total duration as a number
 */

  getTotalDurationAllBlocks(): number {
    return this.blocks.reduce((total, _, index) => {
      return total + parseFloat(this.getTotalDuration(index));
    }, 0);
  }
 
  /**
 * Calculates the total duration across all blocks and returns it formatted as hours and minutes.
 * 
 * @returns the total duration formatted as a string (e.g., "2h 30min" or "45min")
 */

  getTotalDurationAllBlocksInHoursAndMinutes(): string {
    const totalDurationInMin = this.blocks.reduce((total, _, index) => {
      return (
        total +
        parseFloat(
          this.getTotalDuration(index)
            .replace("h", "")
            .replace("min", "")
            .replace(" ", "")
        )
      );
    }, 0);

    if (totalDurationInMin >= 60) {
      const hours = Math.floor(totalDurationInMin / 60);
      const minutes = totalDurationInMin % 60;
      return `${hours}h ${minutes.toFixed(0)}min`;
    } else {
      return `${totalDurationInMin.toFixed(0)}min`;
    }
  }
}
