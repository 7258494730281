import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/shared/auth/auth.service";
import { NgbdSortableHeader } from "app/shared/directives/sortable.directive";
import { OPN_BASE_URL } from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { NotyService } from "app/shared/services/noty.service";
import { BehaviorSubject, forkJoin } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Component({
  selector: "app-group-services-management",
  templateUrl: "./group-services-management.component.html",
  styleUrls: ["./group-services-management.component.scss"],
})
export class GroupServicesManagementComponent implements OnInit {
  submitted = false;
  trips: any[] = [];
  isOpen = true;
  isEdit = false;
  selectedItem: any;
  selectedTrips: any;
  routes: any;
  username:string;
  missingAssociatedTrips: any[] = [];
  @Input() itemId: string;
  private tripsSubject = new BehaviorSubject<any[]>([]);
  public trips$ = this.tripsSubject.asObservable();
  @Output() itemAdded: EventEmitter<void> = new EventEmitter<void>();
  @Output() itemEdited: EventEmitter<void> = new EventEmitter<void>();

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  serviceGroupForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required]),
    trips: new UntypedFormControl([], Validators.required),
    flatRateHours: new UntypedFormControl("", Validators.required),
  });

  constructor(
    public activeModal: NgbActiveModal,
    private crudService: CrudService,
    private authservice :AuthService,
    private notyService: NotyService
  ) {
    this.username = this.authservice.getUsernameFromToken();
  }

  /**
   * Initializes the component, retrieves necessary data, and sets up editing mode if required.
   */

  ngOnInit(): void {
    this.loadInitialData();
  }

  /**
   * Loads the initial data required for the component.
   * Fetches trips and routes data from the server and stores them in component properties.
   * If the component is in edit mode, it fetches the service group data to edit.
   */

  loadInitialData() {
    forkJoin({
      trips: this.crudService.getAll<any>(`${OPN_BASE_URL}/trip?username=${encodeURIComponent(this.username)}`),
      routes: this.crudService.getAll<any>( `${OPN_BASE_URL}/route?username=${encodeURIComponent(this.username)}`),
    })
      .pipe(
        map((results) => {
          this.trips = results.trips;
          this.routes = results.routes;
          if (!this.isEdit) {
            this.editServiceGroup(this.itemId);
          }
        }),
        catchError((error) => {
          console.error("Error loading initial data:", error);
          return [];
        })
      )
      .subscribe();
  }

  /**
   * Retrieves the list of routes from the server.
   */

  /*getRouteList() {
    this.crudService.getAll<any>(OPN_BASE_URL + "/route").subscribe((data) => {
      this.routes = data;
    });
  }*/


  getRouteList() {

    const url = `${OPN_BASE_URL}/route?username=${encodeURIComponent(this.username)}`;

    this.crudService.getAll<any>(url).subscribe((data) => {
      this.routes = data;
    });
  }

  /**
   * Retrieves the list of trips from the server.
   */

  getTripList() {
    const url = `${OPN_BASE_URL}/trip?username=${encodeURIComponent(this.username)}`;

    this.crudService.getAll<any>(url).subscribe((data) => {
      this.trips = data;
    });
  }

  /**
   * Edits the service group with the specified ID.
   *
   * @param serviceGroupId The ID of the service group to edit.
   */

  editServiceGroup(serviceGroupId: string) {
    if (!serviceGroupId) {
      console.error("Service group ID is undefined");
      return;
    }

    this.selectedItem = serviceGroupId;
    this.crudService
      .getOne<any>(OPN_BASE_URL + "/serviceGroup", serviceGroupId)
      .subscribe(
        (serviceGroup: any) => {
          const tripIds = serviceGroup.trips.map((trip: any) => trip.id);
          this.serviceGroupForm.patchValue({
            id: serviceGroup.id,
            name: serviceGroup.name,
            flatRateHours: serviceGroup.flatRateHours,
            trips: tripIds,
          });
          this.isOpen = true;
          this.isEdit = true;
        },
        (error) => {
          console.error("Error retrieving service for editing:", error);
        }
      );
  }

  /**
   * Saves the form data. Handles both adding a new service group and updating an existing one.
   */

  saveForm() {
    this.submitted = true;
    if (this.serviceGroupForm.invalid) {
      return;
    }
    const transformedTrips = this.serviceGroupForm.value.trips.map(
      (tripId: number) => ({ id: tripId })
    );
    const formValue = {
      ...this.serviceGroupForm.value,
      trips: transformedTrips,
    };
    if (this.isEdit) {
      this.crudService
        .update(
          OPN_BASE_URL + "/serviceGroup/update",
          this.itemId.toString(),
          formValue
        )
        .subscribe(
          (response) => {
            this.itemEdited.emit();
            this.serviceGroupForm.reset();
            this.submitted = false;
            this.isOpen = false;
            this.notyService.displayNotification(
              "Service Group updated successfully",
              "success"
            );
          },
          (error) => {
            console.error("Error updating service:", error);
            this.notyService.displayNotification("Failed update", "error");
          }
        );
    } else {
      this.crudService
        .post(OPN_BASE_URL + "/serviceGroup/add", formValue)
        .subscribe(
          (response) => {
            this.itemAdded.emit();
            this.serviceGroupForm.reset();
            this.submitted = false;
            this.isOpen = false;
            this.notyService.displayNotification(
              "Service Group added successfully",
              "success"
            );
          },
          (error) => {
            console.error("Error adding service:", error);
            this.notyService.displayNotification("Failed Add", "error");
          }
        );
    }
    this.activeModal.close();
  }

  /**
   * Returns the form controls for validation.
   * @returns The form controls.
   */

  get s() {
    return this.serviceGroupForm.controls;
  }

  /**
   * Cancels the form, resets the data, and closes the modal.
   */

  cancelForm() {
    this.serviceGroupForm.reset();
    this.submitted = false;
    this.activeModal.close();
  }

  /**
   * Retrieves the selected trips and adds route details to each trip.
   *
   * @returns The list of selected trips with route details.
   */

  getSelectedTrips() {
    this.missingAssociatedTrips = [];
    const selectedTripIds = this.serviceGroupForm.get("trips")?.value;

    if (selectedTripIds == null || selectedTripIds.length === 0) {
      return [];
    }

    const addedAssociatedTrips: Set<number> = new Set();
    this.selectedTrips = this.trips
      .filter((trip) => selectedTripIds.includes(trip.id))
      .map((trip) => {
        return this.processTrip(trip, selectedTripIds, addedAssociatedTrips);
      });

    return this.selectedTrips;
  }

  /**
   * Processes a trip by associating it with its route details and checking for associated trips.
   * If an associated trip is not selected and not already added, it is added to the missingAssociatedTrips list.
   *
   * @param trip - The trip object to be processed.
   * @param selectedTripIds - Array of selected trip IDs.
   * @param addedAssociatedTrips - Set of IDs of trips that have already been checked and added to the missingAssociatedTrips list.
   * @returns A new trip object with additional route details.
   */

  processTrip(
    trip: any,
    selectedTripIds: any,
    addedAssociatedTrips: Set<number>
  ) {
    if (this.routes != null) {
      const route = this.routes.find((route) => route.id === trip.idRoute);
      const associatedTripCode = trip.code.includes("A")
        ? trip.code.replace("A", "R")
        : trip.code.replace("R", "A");
      const associatedTrip = this.trips.find(
        (t: any) => t.code === associatedTripCode
      );

      if (
        !selectedTripIds.includes(associatedTrip.id) &&
        !addedAssociatedTrips.has(associatedTrip.id)
      ) {
        this.missingAssociatedTrips.push(associatedTrip);
        addedAssociatedTrips.add(associatedTrip.id);
      }
      return {
        ...trip,
        routeNum: route ? route.number : null,
        routeName: route ? route.name : null,
      };
    }
  }

  /**
   * Returns the appropriate card title based on the edit mode.
   *
   * @returns The card title.
   */

  getCardTitle(): string {
    return this.isEdit ? "UPDATE_SERVICE_GROUP" : "ADD_SERVICE_GROUP";
  }

  addTripToSelected(tripId: number) {
    const tripsControl = this.serviceGroupForm.get("trips");
    if (tripsControl) {
      const currentTrips = tripsControl.value || [];
      if (!currentTrips.includes(tripId)) {
        tripsControl.setValue([...currentTrips, tripId]);
      }
    }
  }
}
