<!-- breadcrumb.component.html -->
<nav aria-label="breadcrumb" appRefresh>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a [routerLink]="'/page'">
                <i class="ft-home"></i>
            </a>
        </li>
        <li class="breadcrumb-item" *ngIf="breadcrumbs?.parentBreadcrumb">{{ breadcrumbs?.parentBreadcrumb | translate
            }}</li>
        <li class="breadcrumb-item active"
            *ngIf="breadcrumbs?.childBreadcrumb && breadcrumbs?.childBreadcrumb !== breadcrumbs?.parentBreadcrumb">
            {{ breadcrumbs?.childBreadcrumb | translate }}
        </li>
    </ol>
</nav>