import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { forkJoin, Observable, Subscription } from "rxjs";
// @ts-ignore
import L from "leaflet";
import { RealTimeTrackingService } from "../services/real-time-tracking.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Vehicle } from "app/modules/maintenance-management/work-request/models/work-request";
import { Station } from "app/modules/operating-networks/stations-management/models/stations.model";
import { BASE_URL_FLEET, OPN_BASE_URL } from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

export interface RealTimeData {
  localite: string;
  isAlert: any;
  alert: any;
  temp: number;
  dist: number;
  rpm: number;
  car: number;
  vitesseInst: number;
  id: string;
  date: string;
  lat: number;
  lon: number;
  vitesse: number;
  cap: number;
  contact: string;
  move: string;
  gps: string;
  gsm: string;
  Carburant: number;
  T1: number;
  T2: number;
  km: number;
  RPM: number;
  TFU: number;
  x: number;
  y: number;
  z: number;
}

@Component({
  selector: "app-vehicles-list-tracking",
  templateUrl: "./vehicles-list-tracking.component.html",
  styleUrls: ["./vehicles-list-tracking.component.scss"],
})
export class VehiclesListTrackingComponent implements OnInit, OnDestroy {
  mapModalRef: NgbModalRef | null = null;

  isContentOverlay = false;
  searchQuery: "";
  vehiclesList: Vehicle[] = [];
  stationsList: Station[] = [];
  realTimeData: RealTimeData[] = [];
  selectedVehicle: Vehicle;
  private map = L.map;
  private stationsMarkers: any[] = [];
  private vehiclesMarkers: any[] = [];
  private langChangeSub: Subscription;

  @ViewChild("mapModal") mapModal: ElementRef;

  constructor(
    private crudService: CrudService,
    private _elementRef: ElementRef,
    private realTimeTrackingService: RealTimeTrackingService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.langChangeSub = this.translate.onLangChange.subscribe(
      (event: LangChangeEvent) => {
        // Re-render the popup when language changes
        if (this.selectedVehicle) {
          this.openPopup(
            this.realTimeData.find(
              (rt) => parseInt(rt.id, 10) === this.selectedVehicle.idBoitier
            ),
            this.vehiclesMarkers.find(
              (m) =>
                m.options.icon.options.text ===
                this.selectedVehicle.vehicleNumber
            ),
            this.selectedVehicle
          );
        }
      }
    );
  }

  loadData() {
    this.stationsList = [];
    this.realTimeData = [];
    this.vehiclesList = [];

    // Call to get stations
    this.crudService.getAll(OPN_BASE_URL + "/stations").subscribe({
      next: (result: any) => {
        this.stationsList = result.data || [];
        this.loadRealTimeData(); // Proceed to load real-time data
      },
      error: (err) => {
        console.error("Error fetching stations:", err);
        this.stationsList = [];
        this.loadRealTimeData();
      },
    });
  }

  loadRealTimeData() {
    // Call to get real-time data
    this.crudService.getAll(OPN_BASE_URL + "/rt/all").subscribe({
      next: (result: any) => {
        this.realTimeData = result || [];
        this.loadVehicles(); // Proceed to load vehicles
      },
      error: (err) => {
        console.error("Error fetching real-time data:", err);
        // Handle error for real-time data if needed
        this.realTimeData = [];
        this.loadVehicles();
      },
    });
  }

  loadVehicles() {
    this.crudService.getAll(BASE_URL_FLEET + "/vehicles/list").subscribe({
      next: (data: any) => {
        this.vehiclesList = data || [];
        this.getUpdatedData();
        for (const vehicle of this.vehiclesList) {
          const rtData = this.realTimeData.find(
            (rt) => parseInt(rt.id, 10) === vehicle.idBoitier
          );
          if (rtData) {
            vehicle.location = rtData.localite;
          }
        }
        this.initMap();
      },
      error: (err) => {
        console.error("Error fetching vehicles:", err);
      },
    });
  }

  ngOnDestroy(): void {
    this.clearMap();
  }

  initMap(): void {
    const el = this._elementRef.nativeElement.querySelector(".real-time-map");
    this.map = L.map(el, {
      attributionControl: false,
      center: [34.551117, 9.369019],
      zoom: 10,
    });
    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution: "",
    }).addTo(this.map);
    for (const station of this.stationsList) {
      this.addStationsMarkers([station.lat, station.lon], station.name);
    }
    for (const rt of this.realTimeData) {
      const vehicle = this.vehiclesList.find(
        (v) => v.idBoitier === parseInt(rt.id, 10)
      );
      if (vehicle) {
        this.addVehiclesMarkers([rt.lat, rt.lon], vehicle);
      }
    }
    // fit the map bounds to the markers
    this.fitMapToMarkers();
  }

  addStationsMarkers(latlng: any, stationName: string): void {
    const customIcon = L.icon({
      iconUrl: "./assets/img/leaflet/bus-stop.png",
      iconSize: [25, 41],
      text: stationName,
    });
    const marker = L.marker(latlng, { icon: customIcon }).addTo(this.map);
    this.stationsMarkers.push(marker);
  }

  clearMap(): void {
    this.stationsMarkers.forEach((marker) => {
      this.map.removeLayer(marker);
    });
    this.vehiclesMarkers.forEach((marker) => {
      this.map.removeLayer(marker);
    });
    this.map.remove();
  }

  fitMapToMarkers(): void {
    if (this.stationsMarkers.length > 0) {
      const group = new L.featureGroup(this.stationsMarkers);
      this.map.fitBounds(group.getBounds());
    }
  }

  // clicking on a vehicle in list
  onVehicleClick(vehicle: Vehicle) {
    this.selectedVehicle = vehicle;
    // zoom to the vehicle
    const marker = this.vehiclesMarkers.find(
      (m) => m.options.icon.options.text === vehicle.vehicleNumber
    );
    if (marker) {
      this.map.setView(marker.getLatLng(), 15);
    }
    // open popup with real time data
    const rtData = this.realTimeData.find(
      (rt) => parseInt(rt.id, 10) === vehicle.idBoitier
    );
    if (rtData) {
      this.openPopup(rtData, marker, vehicle);
    }
  }

  openPopup(rtData: RealTimeData, marker: any, vehicle: Vehicle): void {
    this.getReverseGeocode(rtData.lat, rtData.lon);

    // Fetch all translations at once
    this.translate
      .get([
        "Track",
        "DATE",
        "Speed",
        "Temp",
        "RPM",
        "Fuel Level",
        "Distance",
        "Location",
      ])
      .subscribe((translations: any) => {
        const popupContent = `
        <div class="popup-container" style="font-family: Arial, sans-serif; color: #333;">
            <h3 class="vehicle-number" style="font-size: 18px; margin-bottom: 10px;">
                <i class="fas fa-bus" 
                style="color: #007bff; margin-right: 5px;"></i>${
                  vehicle.vehicleNumber
                }
            </h3>
            <div class="vehicle-stats" style="font-size: 14px; line-height: 1.5;">
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["DATE"]}:</strong></span>
                    <span>${rtData.date
                      .replace("T", " ")
                      .replace("Z", "")}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Speed"]}:</strong></span>
                    <span>${rtData.vitesseInst} km/h</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Temp"]}:</strong></span>
                    <span>${rtData.temp} °C</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["RPM"]}:</strong></span>
                    <span>${rtData.rpm}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Fuel Level"]}:</strong></span>
                    <span>${rtData.car} L</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Distance"]}:</strong></span>
                    <span>${rtData.dist} km</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Location"]}:</strong></span>
                    <span>${vehicle.location}</span>
                </div>
            </div>
            <div style="text-align: center; margin-top: 10px;">
                <button id="trackButton" class="btn btn-primary" style="background-color: #007bff; border: none; padding: 5px 10px;">
                    ${translations["Track"]}
                </button>
            </div>
        </div>
        `;

        // Bind the popup content to the marker
        marker.bindPopup(popupContent);
        marker.openPopup();

        this.selectedVehicle = this.vehiclesList.find(
          (v) => v.vehicleNumber === vehicle.vehicleNumber
        );
        const trackButton = document.getElementById("trackButton");
        if (trackButton) {
          trackButton.replaceWith(trackButton.cloneNode(true)); // Remove all previous event listeners
          const newTrackButton = document.getElementById("trackButton");
          newTrackButton.addEventListener("click", () => {
            this.selectedVehicle = this.vehiclesList.find(
              (v) => v.idBoitier === parseInt(rtData.id, 10)
            );
            console.log("updatePopupContent", this.selectedVehicle);
            this.openMapModal(this.mapModal);
          });
        }
      });
  }

  getUpdatedData(): void {
    this.realTimeTrackingService.connect();
    this.realTimeTrackingService.message$.subscribe((message) => {
      this.handleMessage(message);
    });
  }

  updateVehicleMarker(vehicle: Vehicle, rtData: RealTimeData): void {
    const marker = this.vehiclesMarkers.find(
      (m) => m.options.icon.options.text === vehicle.vehicleNumber
    );

    if (marker) {
      marker.setLatLng([rtData.lat, rtData.lon]);
      marker.on("click", () => {
        this.selectedVehicle = this.vehiclesList.find(
          (v) => v.vehicleNumber === vehicle.vehicleNumber
        );
        this.openMapModal(this.mapModal);
      });
      this.updatePopupContent(marker, vehicle, rtData);
    }
  }

  updatePopupContent(
    marker: any,
    vehicle: Vehicle,
    rtData: RealTimeData
  ): void {
    this.getReverseGeocode(rtData.lat, rtData.lon).subscribe(
      (response: any) => {
        this.translate
          .get([
            "Track",
            "DATE",
            "Speed",
            "Temp",
            "RPM",
            "Fuel Level",
            "Distance",
            "Location",
          ])
          .subscribe((translations: any) => {
            const popupContent = `
        <div class="popup-container" style="font-family: Arial, sans-serif; color: #333;">
            <h3 class="vehicle-number" style="font-size: 18px; margin-bottom: 10px;">
                <i class="fas fa-bus" 
                style="color: #007bff; margin-right: 5px;"></i>${
                  vehicle.vehicleNumber
                }
            </h3>
            <div class="vehicle-stats" style="font-size: 14px; line-height: 1.5;">
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["DATE"]}:</strong></span>
                    <span>${rtData.date
                      .replace("T", " ")
                      .replace("Z", "")}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Speed"]}:</strong></span>
                    <span>${rtData.vitesse} km/h</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Temp"]}:</strong></span>
                    <span>${rtData.T1} °C</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["RPM"]}:</strong></span>
                    <span>${rtData.RPM}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Fuel Level"]}:</strong></span>
                    <span>${rtData.Carburant} L</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Distance"]}:</strong></span>
                    <span>${rtData.km} km</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Location"]}:</strong></span>
                    <span>${response}</span>
                </div>
            </div>
            <div style="text-align: center; margin-top: 10px;">
                <button id="trackButton" class="btn btn-primary" style="background-color: #007bff; border: none; padding: 5px 10px;">
                    ${translations["Track"]}
                </button>
            </div>
        </div>
        `;

            marker.bindPopup(popupContent);
            if (marker.isPopupOpen()) {
              marker.openPopup();
            }

            // this.selectedVehicle = this.vehiclesList.find(v => v.idBoitier === parseInt(rtData.id, 10));

            // Remove previous click event listener, if any
            const trackButton = document.getElementById("trackButton");
            if (trackButton) {
              trackButton.replaceWith(trackButton.cloneNode(true)); // Remove all previous event listeners
              const newTrackButton = document.getElementById("trackButton");
              newTrackButton.addEventListener("click", () => {
                this.selectedVehicle = this.vehiclesList.find(
                  (v) => v.idBoitier === parseInt(rtData.id, 10)
                );
                console.log("updatePopupContent", this.selectedVehicle);
                this.openMapModal(this.mapModal);
              });
            }
          });
      }
    );
  }

  addVehiclesMarkers(latlng: any, vehicle: Vehicle): void {
    const customIcon = L.icon({
      iconUrl: vehicle.family.bus
        ? "./assets/img/markers/bus.png"
        : "./assets/img/markers/car.png",
      iconSize: [30, 41],
      text: vehicle.vehicleNumber,
    });
    const marker = L.marker(latlng, { icon: customIcon }).addTo(this.map);

    // onMarkerClick openModal
    marker.on("popupopen", () => {
      const trackButton = marker
        .getPopup()
        .getElement()
        .querySelector("#trackButton");
      if (trackButton) {
        // Remove any existing event listeners
        trackButton.replaceWith(trackButton.cloneNode(true)); // Clear previous listeners

        // Reattach the click event listener
        const newTrackButton = marker
          .getPopup()
          .getElement()
          .querySelector("#trackButton");
        newTrackButton.addEventListener("click", () => {
          console.log("Track button clicked", vehicle); // Log the correct vehicle
          this.selectedVehicle = this.vehiclesList.find(
            (v) => v.vehicleNumber === vehicle.vehicleNumber
          );
          this.openMapModal(this.mapModal);
          marker.closePopup();
        });
      }
    });

    // Add the new marker to the array
    this.vehiclesMarkers.push(marker);
  }

  handleMessage(message: any): void {
    const rtData = JSON.parse(JSON.stringify(message));
    const vehicle = this.vehiclesList.find(
      (v) => v.idBoitier === parseInt(message.id, 10)
    );
    if (vehicle) {
      this.updateVehicleMarker(vehicle, rtData);
      vehicle.location = this.realTimeData.find(
        (rt) => parseInt(rt.id, 10) === vehicle.idBoitier
      ).localite;
      // replace old rtData with new one
      const index = this.realTimeData.findIndex(
        (rt) => parseInt(rt.id, 10) === vehicle.idBoitier
      );
      if (index !== -1) {
        this.realTimeData[index] = rtData;
      }
    }
  }

  openMapModal(content: any) {
    // if there is already a modal open, close it
    if (this.mapModalRef) {
      this.mapModalRef.close();
    }
    this.mapModalRef = this.modalService.open(content, {
      size: "xl",
      centered: true,
    });
  }

  closeMapModal() {
    if (this.mapModalRef) {
      this.mapModalRef.close();
    }
    if (this.selectedVehicle) {
      const marker = this.vehiclesMarkers.find(
        (m) =>
          m.options.icon.options.text === this.selectedVehicle.vehicleNumber
      );
      if (marker) {
        this.map.setView(marker.getLatLng(), 15);
      }
    }
  }

  // unselect vehicle
  unselectVehicle() {
    this.searchQuery = "";
    this.selectedVehicle = null;
    // close popups
    this.vehiclesMarkers.forEach((marker) => {
      marker.closePopup();
    });
    // zoom out
    this.fitMapToMarkers();
  }

  // get reverse geocode
  getReverseGeocode(lat: number, lng: number): Observable<string> {
    return this.http
      .get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=fr`
      )
      .pipe(
        map((res: any) => {
          const location = res.principalSubdivision + ", " + res.locality;
          this.selectedVehicle.location = location;
          return location;
        })
      );
  }
}
