import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { TimelineElement } from './timeline-element';

/**
 * Component to display a vertical timeline with animations.
 * Provides functionality to navigate through timeline events and adjust their positions.
 */
@Component({
  selector: 'app-vertical-timeline',
  templateUrl: './vertical-timeline.component.html',
  styleUrls: ['./vertical-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('contentState', [
      state('active', style({
        position: 'relative', 'z-index': 2, opacity: 1,
      })),
      transition('right => active', [
        style({
          transform: 'translateY(100%)' // Vertical transition for content
        }),
        animate('400ms ease-in-out', keyframes([
          style({ opacity: 0, transform: 'translateY(100%)', offset: 0 }),
          style({ opacity: 1, transform: 'translateY(0%)', offset: 1.0 })
        ]))
      ]),
      transition('active => right', [
        style({
          transform: 'translateY(-100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({ opacity: 1, transform: 'translateY(0%)', offset: 0 }),
          style({ opacity: 0, transform: 'translateY(100%)', offset: 1.0 })
        ]))
      ]),
      transition('active => left', [
        style({
          transform: 'translateY(-100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({ opacity: 1, transform: 'translateY(0%)', offset: 0 }),
          style({ opacity: 0, transform: 'translateY(-100%)', offset: 1.0 })
        ]))
      ]),
      transition('left => active', [
        style({
          transform: 'translateY(100%)'
        }),
        animate('400ms ease-in-out', keyframes([
          style({ opacity: 0, transform: 'translateY(-100%)', offset: 0 }),
          style({ opacity: 1, transform: 'translateY(0%)', offset: 1.0 })
        ]))
      ]),
    ])
  ]
})

export class VerticalTimelineComponent implements OnInit, AfterViewInit {

  prevLinkInactive: boolean = true;  // Indicates if the previous link is inactive
  nextLinkInactive: boolean = false; // Indicates if the next link is inactive
  loaded: boolean = false;          // Indicates if the timeline has been initialized
  selectedIndex: number = 0;        // Index of the currently selected timeline event
  currentScrollPosition: number = 0;
  formattedDiff: string;            // Formatted difference between dates

  @ViewChild('eventsWrapper') eventsWrapper: ElementRef; // Reference to the timeline wrapper
  @ViewChildren('timelineEvents') timelineEvents: QueryList<ElementRef>; // Query list of timeline events

  eventsWrapperHeight: number = 0; // Height of the events wrapper
  private _viewInitialized = false; // Indicates if the view has been initialized
  private _timelineWrapperHeight = 480; // Default height of the timeline wrapper

  @Input()
  set timelineWrapperHeight(value: number) {
    this._timelineWrapperHeight = value;
    this._cdr.detectChanges();
  }

  private _eventsMinDistance: number = 100; // Minimum distance between events
  @Input()
  set eventsMinDistance(value: number) {
    this._eventsMinDistance = value;
    this.initView();
  }

  private _timelineElements: TimelineElement[]; // Array of timeline elements
  get timelineElements(): TimelineElement[] {
    return this._timelineElements;
  }
  @Input()
  set timelineElements(value: TimelineElement[]) {
    this._timelineElements = value;
    this.initView();
  }

  @Input() progression; // Progression value for the timeline

  private _dateFormat: string = 'MMMM d, y'; // Date format for the timeline
  private _dateFormatTop: string = 'HH:mm';  // Time format for the timeline

  get dateFormat(): string {
    return this._dateFormat;
  }
  get dateFormatTop(): string {
    return this._dateFormatTop;
  }

  @Input()
  set dateFormat(value: string) {
    this._dateFormat = value;
    this.initView();
  }

  @Input()
  set dateFormatTop(value: string) {
    this._dateFormatTop = value;
    this.initView();
  }

  private _disabled: boolean = false; // Indicates if the timeline is disabled
  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
  }

  private _showContent: boolean = false; // Indicates if content is visible
  get showContent(): boolean {
    return this._showContent;
  }
  @Input()
  set showContent(value: boolean) {
    this._showContent = value;
    this._cdr.detectChanges();
  }

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this._viewInitialized = true;
    this.initView();
    this._cdr.detectChanges();
  }

  /**
   * Initializes the timeline view with provided elements.
   */
  private initView(): void {
    if (!this._viewInitialized) {
      return;
    }
    if (this._timelineElements && this._timelineElements.length) {
      this.initTimeline(this._timelineElements);
    }
  }

  /**
   * Initializes the timeline with the given elements.
   * Sets the date positions and timeline height.
   * @param timeLines An array of timeline elements.
   */
  private initTimeline(timeLines: TimelineElement[]): void {
    let eventsMinLapse = VerticalTimelineComponent.minLapse(timeLines);
    this.setDatePosition(timeLines, this._eventsMinDistance, eventsMinLapse);
    const totalHeight = this.calculateTotalHeight();
    this.setTimelineHeight(timeLines, this._eventsMinDistance, eventsMinLapse);
    this.loaded = true;
  
    // Initialize button states
    this.prevLinkInactive = true;
    this.nextLinkInactive = totalHeight <= this._timelineWrapperHeight;
  
    this._cdr.detectChanges();
  }

  /**
   * Updates the timeline slide position based on the scroll direction.
   * @param timelineTotHeight The total height of the timeline.
   * @param forward If true, scrolls forward; if false, scrolls backward.
   */
  updateSlide(timelineTotHeight: number, forward: boolean): void {
    let change = forward ? -this._timelineWrapperHeight : this._timelineWrapperHeight;
  
    // Calculate new scroll position
    let newScrollPosition = this.currentScrollPosition + change;
  
    // Limit the scroll position
    newScrollPosition = Math.max(Math.min(newScrollPosition, 0), -(timelineTotHeight - this._timelineWrapperHeight));
  
    // Update the current scroll position
    this.currentScrollPosition = newScrollPosition;
  
    // Update button states
    this.prevLinkInactive = this.currentScrollPosition >= 0;
    this.nextLinkInactive = this.currentScrollPosition <= -(timelineTotHeight - this._timelineWrapperHeight);
  
    // Translate the timeline
    this.translateTimeline(this.currentScrollPosition, timelineTotHeight);
  
    // Trigger change detection
    this._cdr.detectChanges();
  }

  /**
   * Translates the timeline to a specific position.
   * @param value The translation value in pixels.
   * @param totHeight The total height of the timeline.
   */
  private translateTimeline(value: number, totHeight: number): void {
    VerticalTimelineComponent.setTransformValue(this.eventsWrapper.nativeElement, 'translateY', value + 'px');
  }

  private calculateTotalHeight(): number {
    if (!this.timelineEvents) return 0;
    const events = this.timelineEvents.toArray();
    if (events.length === 0) return 0;
    const lastEvent = events[events.length - 1].nativeElement;
    return lastEvent.offsetTop + lastEvent.offsetHeight;
  }

  /**
   * Sets the height of the timeline wrapper based on the number of elements and their distance.
   * @param elements An array of timeline elements.
   * @param height The height of each event.
   * @param eventsMinLapse The minimum lapse between events.
   * @returns The computed height of the events wrapper.
   */
  private setTimelineHeight(elements: TimelineElement[], height: number, eventsMinLapse: number): number {
    if (elements.length <= 1) {
      this.eventsWrapperHeight = this._timelineWrapperHeight;
      return this.eventsWrapperHeight;
    }

    let timeSpan = VerticalTimelineComponent.dayDiff(elements[0].date, elements[elements.length - 1].date);
    let timeSpanNorm = Math.max(Math.round(timeSpan / eventsMinLapse), elements.length - 1);
    this.eventsWrapperHeight = (timeSpanNorm + 1) * height; // Add 1 to ensure last element is fully visible

    // Ensure minimum height for few elements
    let minRequiredHeight = elements.length * height;
    this.eventsWrapperHeight = Math.max(this.eventsWrapperHeight, minRequiredHeight);

    // Ensure minimum height for wrapper
    this.eventsWrapperHeight = Math.max(this.eventsWrapperHeight, this._timelineWrapperHeight);

    return this.eventsWrapperHeight;
  }

  /**
   * Sets the position of timeline events based on their date and minimum distance.
   * @param elements An array of timeline elements.
   * @param min The minimum distance between events.
   * @param eventsMinLapse The minimum lapse between events.
   */
  private setDatePosition(elements: TimelineElement[], min: number, eventsMinLapse: number): void {
    let timelineEventsArray = this.timelineEvents.toArray();
    for (let i = 0; i < elements.length; i++) {
      let distance = VerticalTimelineComponent.dayDiff(elements[0].date, elements[i].date);
      let distanceNorm = Math.round(distance / eventsMinLapse);

      // Ensure a minimum distance between events
      distanceNorm = Math.max(distanceNorm, i);

      let topPosition = distanceNorm * min;
      timelineEventsArray[i].nativeElement.style.top = topPosition + 'px';

      // Adjust span position
      let span: HTMLSpanElement = <HTMLSpanElement>timelineEventsArray[i].nativeElement.parentElement.children[1];
      let spanHeight = VerticalTimelineComponent.getElementHeight(span);
      span.style.top = (topPosition + spanHeight / 2) + 'px';
    }
  }

  /**
   * Handles scroll click events to navigate through the timeline.
   * @param event The click event.
   * @param forward If true, scrolls forward; if false, scrolls backward.
   */
  onScrollClick(event: Event, forward: boolean): void {
    event.preventDefault();
    const totalHeight = this.calculateTotalHeight();
    this.updateSlide(totalHeight, forward);
  }

  // Static utility methods

  /**
   * Converts a pixel value string to a number.
   * @param val The pixel value string (e.g., '100px').
   * @returns The numeric value.
   */
  private static pxToNumber(val: string): number {
    return Number(val.replace('px', ''));
  }

  /**
   * Gets the computed height of an element.
   * @param element The DOM element.
   * @returns The height of the element in pixels.
   */
  private static getElementHeight(element: Element): number {
    const computedStyle = window.getComputedStyle(element);
    if (!computedStyle.height) {
      return 0;
    }
    return VerticalTimelineComponent.pxToNumber(computedStyle.height);
  }

  /**
   * Finds the parent element with a specific tag name.
   * @param element The starting element.
   * @param tagName The tag name of the parent element to find.
   * @returns The parent element with the specified tag name, or null if not found.
   */
  private static parentElement(element: any, tagName: string): HTMLElement | null {
    if (!element || !element.parentNode) {
      return null;
    }
    let parent = element.parentNode;
    while (true) {
      if (parent.tagName.toLowerCase() === tagName) {
        return parent;
      }
      parent = parent.parentNode;
      if (!parent) {
        return null;
      }
    }
  }

  /**
   * Gets the translateY value from the transform property of an element.
   * @param timeline The DOM element.
   * @returns The translateY value in pixels.
   */
  private static getTranslateValue(timeline: Element): number {
    let timelineStyle = window.getComputedStyle(timeline);
    let timelineTranslate = timelineStyle.getPropertyValue('-webkit-transform') ||
      timelineStyle.getPropertyValue('-moz-transform') ||
      timelineStyle.getPropertyValue('-ms-transform') ||
      timelineStyle.getPropertyValue('-o-transform') ||
      timelineStyle.getPropertyValue('transform');
    let translateValue = 0;
    if (timelineTranslate.indexOf('(') >= 0) {
      let timelineTranslateStr = timelineTranslate
        .split('(')[1]
        .split(')')[0]
        .split(',')[4];
      translateValue = Number(timelineTranslateStr);
    }
    return translateValue;
  }

  /**
   * Sets the transform property of an element.
   * @param element The DOM element.
   * @param property The transform property (e.g., 'translateY').
   * @param value The value of the transform property.
   */
  private static setTransformValue(element: any, property: any, value: any): void {
    element.style['-webkit-transform'] = property + '(' + value + ')';
    element.style['-moz-transform'] = property + '(' + value + ')';
    element.style['-ms-transform'] = property + '(' + value + ')';
    element.style['-o-transform'] = property + '(' + value + ')';
    element.style['transform'] = property + '(' + value + ')';
  }

  /**
   * Calculates the difference in milliseconds between two dates.
   * @param first The first date.
   * @param second The second date.
   * @returns The difference in milliseconds.
   */
  private static dayDiff(first: Date, second: Date): number {
    return Math.round(second.getTime() - first.getTime());
  }

  /**
   * Calculates the minimum lapse between timeline elements.
   * @param elements An array of timeline elements.
   * @returns The minimum lapse in milliseconds.
   */
  private static minLapse(elements: TimelineElement[]): number {
    if (elements && elements.length && elements.length === 1) {
      return 0;
    }
    let result: number = 0;
    for (let i = 1; i < elements.length; i++) {
      let distance = VerticalTimelineComponent.dayDiff(elements[i - 1].date, elements[i].date);
      result = result ? Math.min(result, distance) : distance;
    }
    return result;
  }
}
