import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
// @ts-ignore
import * as L from "leaflet";

@Component({
  selector: "app-itineray-map",
  templateUrl: "./itineray-map.component.html",
  styleUrls: ["./itineray-map.component.scss"],
})
export class ItinerayMapComponent implements AfterViewInit {
  
  private map;
  private markers: any[] = [];
  private routeLine;


  constructor(private _elementRef: ElementRef) {}
  @Input() mapId: string;
  @Input() blockIndex: number;

  @Output() locationSelected = new EventEmitter<{ lat: number; lon: number }>(); // Event emitter to notify when a location is selected
  @Output() routeCalculated = new EventEmitter<
    { distance: number; duration: number }[]
  >(); // Event emitter for route calculations
  @Output() shapeCalculated = new EventEmitter<any>(); // Event emitter for shape calculations
  @Output() returnShapeCalculated = new EventEmitter<any>(); // Event emitter for shape calculations



  ngAfterViewInit(): void {
    this.initMap();
  }

   /**
   * Initializes the Leaflet map and sets its view.
   */

  initMap(): void {
     if (this.map) return;
    let el = this._elementRef.nativeElement.querySelector(".leaflet-maps");
    this.map = L.map(el, {
      center: [34.551117, 9.369019],
      zoom: 6,
    });
    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);
  }




    /**
   * Adds a marker to the map at the specified latitude and longitude.
   * @param latlng - The latitude and longitude where the marker should be placed.
   */

  addMarkers(latlng: any): void {
    let customIcon = L.icon({
      iconUrl: "./assets/img/leaflet/bus-stop.png",
      iconSize: [25, 41],
    });
    const marker = L.marker(latlng, { icon: customIcon }).addTo(this.map);
    this.markers.push(marker);
    this.locationSelected.emit({
      lat: latlng.lat,
      lon: latlng.lng,
    });
  }



  /**
   * Clears all markers and routes from the map.
   */

  clearMap(): void {
    this.markers.forEach((marker) => this.map.removeLayer(marker));
    this.markers = [];
    if (this.routeLine) {
      this.map.removeLayer(this.routeLine);
      this.routeLine = null;
    }
  }
  
   /**
   * Draws a route on the map using the OSRM API.
   * @param stations - An array of objects containing the latitude and longitude of each station.
   */

   async drawRouteOSRM(stations: { lat: number; lon: number }[]): Promise<void> {
     if (stations.length < 2) return;

     const coordinates = stations
         .map((station) => `${station.lon},${station.lat}`)
         .join(";");
     const url = `https://router.project-osrm.org/route/v1/driving/${coordinates}?overview=full&geometries=geojson`;

     try {
       const response = await fetch(url);
       const data = await response.json();
       if (data.code !== "Ok") {
         console.error("OSRM request failed:", data);
         return;
       }

       const route = data.routes[0];

       // Map coordinates from [lon, lat] to { lat, lon }
       const routeCoordinates = route.geometry.coordinates.map((coord) => ({
         lat: coord[1],
         lon: coord[0],
       }));

       // Pass the correctly formatted coordinates to getDistancesOSRM
       this.getDistances(routeCoordinates);
       this.getDistances(routeCoordinates.reverse(), true);

       const legsData = route.legs.map((leg) => ({
         distance: leg.distance,
         duration: leg.duration,
       }));

       this.routeCalculated.emit(legsData);

       if (this.routeLine) {
         this.map.removeLayer(this.routeLine);
       }
       this.routeLine = L.polyline(
           routeCoordinates.map((coord) => [coord.lat, coord.lon]),
           {
             color: "blue",
             weight: 5,
           }
       ).addTo(this.map);
       this.map.fitBounds(this.routeLine.getBounds());
     } catch (error) {
       console.error("Error fetching OSRM route:", error);
     }
   }

  async getDistances(positions: { lat: number; lon: number }[], returnShape = false): Promise<void> {
     // like { lat: 34.551117, lon: 9.369019, distance: 0 }
    let s = { ...positions[0], km: 0 };
    const distances = [s];
    for (let i = 0; i < positions.length - 1; i++) {
      const distance = this.getDistance(
          positions[i].lat,
          positions[i].lon,
          positions[i + 1].lat,
          positions[i + 1].lon
      );
      s = { ...positions[i + 1], km: distances[i].km + distance };
      distances.push(s);
    }
    returnShape ? this.returnShapeCalculated.emit(distances) : this.shapeCalculated.emit(distances);
  }

  // getDistance between two points on earth
    getDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
        const R = 6371; // Radius of the earth in km
        const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
        const dLon = this.deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.deg2rad(lat1)) *
            Math.cos(this.deg2rad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in km
    }

    deg2rad(deg: number): number {
        return deg * (Math.PI / 180);
    }



  /**
   * Updates the map with the provided stations. Adds markers and draws the route if there are multiple stations.
   * @param stations - An array of objects containing the latitude and longitude of each station.
   */


    async updateMap(stations: { lat: number; lon: number }[]): Promise<void>  {

    this.clearMap();

    stations.forEach((station) => this.addMarkers([station.lat, station.lon]));

    if (stations.length >= 2) {
      await this.drawRouteOSRM(stations);
    } else if (stations.length === 1) {
      this.map.setView([stations[0].lat, stations[0].lon], 13);
    } else {
      this.map.setView([34.551117, 9.369019], 6);
    }
  }

}
