<div class="chat-application overflow-hidden d-flex">
    <!-- Sidebar (Vehicle List) -->
    <div class="chat-sidebar d-md-block flex-shrink-0" style="width: 300px;">
        <span class="sidebar-close-icon d-md-none" (click)="isContentOverlay = false">
            <i class="ft-x"></i>
        </span>
        <div class="chat-sidebar-content d-flex flex-column" style="height: 100vh;">
            <!-- Ensure the sidebar takes full viewport height -->
            <div class="chat-fixed-search py-2 px-3 my-1">
                <form action="javascript:">
                    <div class="position-relative has-icon-left">
                        <input class="form-control" placeholder="{{'Search_vehicles' | translate}}" id="timesheetinput1"
                            name="registration" type="text" [(ngModel)]="searchQuery">
                        <div class="form-control-position">
                            <i class="ft-user"></i>
                        </div>
                    </div>
                </form>
            </div>
            <hr class="m-0">
            <button class="btn btn-primary btn-block mt-2 mb-2" (click)="unselectVehicle()">{{"SHOW_VEHICLES" |
                translate}}</button>

            <!-- List of vehicles with scrolling -->
            <div class="users-list-padding flex-grow-1 overflow-auto">
                <a class="list-group-item" *ngFor="let vehicle of vehiclesList | search:'registration':searchQuery"
                    (click)="onVehicleClick(vehicle)"
                    [ngClass]="{'active-vehicle': selectedVehicle?.vehicleNumber === vehicle.vehicleNumber}">
                    <div class="media chat-list-text py-1" [ngClass]="{'align-items-center': !vehicle.isActiveChat}">
                        <span class="avatar-status-{{vehicle.vehicleNumber}}"></span>
                        <i></i>
                        <div class="media-body">
                            <h6 class="list-group-item-heading mb-1">
                                <span class="label">{{"Immatriculation" | translate}} : </span> {{ vehicle.registration
                                }}
                            </h6>
                            <h6 class="list-group-item-heading mb-1">
                                <span class="label">{{"Vehicle_Number" | translate}} : </span> {{ vehicle.vehicleNumber
                                }}
                            </h6>
                            <h6 class="list-group-item-heading mb-1">
                                <span class="label">{{"FAMILY" | translate}} :</span> {{ vehicle.family.familyName }}
                            </h6>
                            <h6 class="list-group-item-heading mb-1">
                                <span class="label">{{"LOCATION" | translate}} : </span> {{ vehicle.location }}
                            </h6>
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <ng-template #mapModal let-modal style="width: 800px">
            <button type="button" class="close " aria-label="Close" (click)="closeMapModal()">
                <span aria-hidden="true" class="close-icon d-flex float-right mr-2">&times;</span>
            </button>
            <app-vehicle-tracking [vehicle]="selectedVehicle" [isHistoric]="false"></app-vehicle-tracking>
        </ng-template>
    </div>


    <!-- Chat Window (Empty Space) -->
    <section class="ml-2 mt-2 chat-app-window position-relative flex-grow-1" #scrollMe
        [scrollTop]="scrollMe.scrollHeight">
        <div class="chats">
            <div class="real-time-map" style="width: 100%; height: 60rem;"></div>
        </div>
    </section>
</div>