<div *ngIf="control" class="mb-3">
  <div class="form-group row mb-0">
    <label [class.text-danger]="control.invalid && (control.dirty || control.touched)"
           [for]="idName"
           class="col-xl-3 col-md-4 mx-1"><span *ngIf="controlIsRequired(control)" class="text-danger">*</span>
      {{labelPlaceholder |translate| titlecase}}
      <a *ngIf="validators && prepareTooltips(validators).length > 0"
        [ngbTooltip]="titleTemplate"
        placement="top">
    <i class="ft-alert-circle"></i>
</a>

<ng-template #titleTemplate>
    <ng-container *ngFor="let toolTip of prepareTooltips(validators)">
        <span>{{ toolTip | translate}}</span><br>
    </ng-container>
</ng-template>

      <span class="pointer">
        <app-feather-icons (click)="control.setValue(null)" *ngIf="canClear" [icon]="'trash-2'"></app-feather-icons>
      </span>
    </label>
    <div class="col-xl-8 col-md-7 ">

      <nz-radio-group [formControl]="control" [id]="idName">
        <label (click)="option.show?ngModelChange.emit({field:option.show,hide:false}):null;
                option.hide?ngModelChange.emit({field:option.hide,hide:true}):null"
               *ngFor="let option of options;let i=index"
               [class.text-danger]="control.invalid&&(control.dirty||control.touched)"
               [nzValue]="option.value" [id]="option.label"
               nz-radio>{{option.label}}</label>
      </nz-radio-group>
    </div>
  </div>

  <app-error-handling [control]="control"></app-error-handling>

</div>
