import {Component, Input, OnInit} from '@angular/core';
import * as echarts from 'echarts';
import {SaeService} from '../services/sae.service';
import {forkJoin, of} from 'rxjs';
import {map} from 'rxjs/operators';
import { Station } from 'app/modules/operating-networks/stations-management/models/stations.model';
import { OPN_BASE_URL } from 'app/shared/global/var';
import { CrudService } from 'app/shared/services/crud.service';

@Component({
    selector: 'app-trip-progress',
    templateUrl: './trip-progress.component.html',
    styleUrls: ['./trip-progress.component.scss']
})
export class TripProgressComponent implements OnInit {
    @Input() itinerary: any;
    @Input() trip: any;
    stations: Array<Station> = [];
    busPosition = 0;
    chartInstance: any;
    routeDistance = 0;
    tripProgress = 0;
    stationsProperties: string | any[]

    constructor(
        private saeService: SaeService,
        private crudService: CrudService
    ) {
    }

    ngOnInit(): void {
        if (this.itinerary) {
            // if itinerary distance is 0, set routeDistance to the sum of all station distances
            this.routeDistance = this.itinerary.distance === 0 ?
                this.itinerary.itineraryStations.reduce((acc: number, station: any) => acc + station.distance, 0)
                : this.itinerary.distance;
            this.stations = this.itinerary.itineraryStations.map((station: any) => {
                return {
                    id: station.station.id,
                    name: station.station.name,
                    distance: station.distance,
                    lat: +station.station.lat,
                    lon: +station.station.lon,
                    duration: station.duration,
                    stoppingTime: station.stoppingTime,
                    stationOrder: station.stationOrder,
                    visited: false
                };
            });

            this.stations.sort((a, b) => a.distance - b.distance);
            this.stations.forEach((station, index) => {
                station.distance = this.stations.slice(0, index + 1).reduce((acc, s) => acc + s.distance, 0);
            });
            this.getStopTime();
            if (this.trip) {
                if (this.trip.status === 3) {
                    this.getUpdatedData(this.trip.id);
                } else if (this.trip.status === 4) {
                    this.busPosition = this.routeDistance;
                    this.updateChart([[0, 0], [this.routeDistance, 0]]);
                }
            }
        }
    }

    initializeChart() {
        if (this.chartInstance) {
            this.chartInstance.dispose();
        }
        const chartDom = document.getElementById('trip-progress');
        this.chartInstance = echarts.init(chartDom);

        // bus position is last visited station
        this.busPosition = this.stations.filter(station => station.visited).pop()?.distance || 0;
        // travelled distance is the sum of all visited stations
        const traveledDistance = this.stations.filter(station => station.visited).reduce((acc, station) => acc + station.distance, 0);

        const option = {
            xAxis: {
                type: 'value',
                splitLine: {show: false},
                axisLabel: {
                    formatter: '{value} km',
                    color: '#000000'
                },
            },
            yAxis: {
                type: 'value',
                show: false,
                splitLine: {show: false}
            },
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    // Retrieve the station based on the index
                    const station = this.stations[params.dataIndex];

                    // Check if the hovered item is a station or a checkmark (visited station)
                    if (params && (params.seriesName === 'Stations' || params.seriesName === 'Visited Stations')) {
                        if (station) {
                            return `${station.name}<br>Distance: ${station.distance} km`;
                        }
                    }
                    return '';
                }.bind(this)
            },
            series: [
                {
                    name: 'Traveled Distance',
                    type: 'line',
                    data: [[0, 0], [traveledDistance, 0]],
                    lineStyle: {color: '#40c057', width: 4}
                },
                {
                    name: 'Remaining Route',
                    type: 'line',
                    data: this.stations.filter(station => !station.visited).map(station => [station.distance, 0]),
                    lineStyle: {color: '#a0a0a0', width: 2}
                },
                {
                    name: 'Stations',
                    type: 'scatter',
                    data: this.stations.map(station => [station.distance, 0]),
                    symbol: 'image://assets/img/leaflet/bus-stop.png',
                    symbolSize: 30,
                    symbolKeepAspect: true,
                    symbolOffset: [0, -20],
                    markPoint: {
                        symbol: 'image://assets/img/markers/checkmark.png',
                        symbolSize: [20, 20],
                        data: this.stations
                            .filter(station => station.visited)
                            .map(station => {
                                const timeDifference = Math.round((new Date(station.rtArrivalTime).getTime()
                                    - new Date(station.estimatedArrivalTime).getTime()) / 60000);
                                const differenceText = `${timeDifference > 0 ? '+' : ''}${timeDifference} min`;
                                const visitedText = `Visited: ${station.rtArrivalTime.replace('T', ' ')}`;
                                const labelColor = timeDifference <= 0 ? 'green' : 'red';

                                return {
                                    coord: [station.distance, 0],
                                    value: timeDifference,
                                    label: {
                                        show: true,
                                        formatter: [
                                            '{difference|' + differenceText + '}',
                                            '{marker|}',
                                            '{visited|' + visitedText + '}'
                                        ].join('\n'),
                                        rich: {
                                            difference: {
                                                color: labelColor,
                                                padding: [5, 5, 5, 50],
                                                position: 'center',
                                                borderRadius: 3,
                                                backgroundColor: '#ffffff',
                                                offset: [-10, -20]
                                            },
                                            marker: {
                                                height: 70
                                            },
                                            visited: {
                                                color: '#2f8be6',
                                                position: 'bottom',
                                                padding: [0, 0],
                                                borderRadius: 3,
                                                backgroundColor: '#ffffff',
                                                offset: [0, 0]
                                            }
                                        },
                                        position: 'insideBottom',
                                        distance: -30
                                    }
                                };
                            })
                    },
                },
                {
                    name: 'Bus',
                    type: 'scatter',
                    data: [[this.busPosition, 0]],
                    symbol: 'image://assets/img/markers/bus-icon.png',
                    symbolSize: 40,
                    symbolOffset: [0, -17]
                }
            ]
        };

        this.chartInstance.setOption(option);
    }


    updateBusPosition(message: { distance: number; progress: number; }): void {
        const busDistance = message.distance;
        this.busPosition = message.distance;
        this.tripProgress = message.progress;
        for (let i = 1; i < this.stationsProperties.length; i++) {
            if (this.stationsProperties[i].rtArrivalTime && !this.stationsProperties[i].rtArrivalTime.match(/\d{4}-\d{2}-\d{2} null/)) {
                this.stations[i].visited = true;
                this.stations[i].rtArrivalTime = this.stationsProperties[i].rtArrivalTime;
                this.stations[i].rtDepartureTime = this.stationsProperties[i].rtDepartureTime;
                this.stations[i].estimatedArrivalTime = this.stationsProperties[i].estimatedArrivalTime;
                this.stations[i].estimatedDepartureTime = this.stationsProperties[i].plannedDepartureTime;
            }
        }
        this.updateChart([[0, 0], [busDistance, 0]], [[busDistance, 0], [this.routeDistance, 0]]);
    }

    private updateChart(traveledData: number[][], routeData?: number[][]): void {
        this.chartInstance.setOption({
            series: [
                {
                    name: 'Bus',
                    data: [[this.busPosition, 0]],
                },
                {
                    name: 'Traveled Distance',
                    data: traveledData,
                    symbol: 'none',
                    lineStyle: {color: '#40c057', width: 4}
                },
                {
                    name: 'Remaining Route',
                    data: routeData,
                    lineStyle: {color: '#a0a0a0', width: 2}
                },
                {
                    name: 'Stations',
                    data: this.stations.map(station => [station.distance, 0]),
                    markPoint: {
                        symbol: 'image://assets/img/markers/checkmark.png',
                        symbolSize: [20, 20],
                        data: this.stations
                            .filter(station => station.visited)
                            .map(station => {
                                const timeDifference = Math.round((new Date(station.rtArrivalTime).getTime()
                                    - new Date(station.estimatedArrivalTime).getTime()) / 60000);
                                const differenceText = `${timeDifference > 0 ? '+' : ''}${timeDifference} min`;
                                const visitedText = `Visited: ${station.rtArrivalTime.replace('T', ' ')}`;
                                const labelColor = timeDifference <= 0 ? 'green' : 'red';

                                return {
                                    coord: [station.distance, 0],
                                    value: timeDifference,
                                    label: {
                                        show: true,
                                        formatter: [
                                            '{difference|' + differenceText + '}',
                                            '{marker|}',
                                            '{visited|' + visitedText + '}'
                                        ].join('\n'),
                                        rich: {
                                            difference: {
                                                color: labelColor,
                                                padding: [5, 5, 5, 50],
                                                borderRadius: 3,
                                                backgroundColor: '#ffffff',
                                                offset: [0, -20]
                                            },
                                            marker: {
                                                height: 70
                                            },
                                            visited: {
                                                color: '#2f8be6',
                                                position: 'bottom',
                                                padding: [0, 0],
                                                borderRadius: 3,
                                                backgroundColor: '#ffffff',
                                                offset: [0, 0]
                                            }
                                        },
                                        position: 'insideBottom',
                                        distance: -30
                                    }
                                };
                            })
                    },
                }
            ]
        });
    }

    getUpdatedData(idTrip: number): void {
        this.saeService.connect(idTrip);
        this.saeService.message$.subscribe((message) => {
            this.handleMessage(message);
        });
    }

    handleMessage(message: any): void {
        if (this.trip.id === parseInt(message.id, 10)) {

            this.stationsProperties = message.stations.map((station: any) => {
                return {
                    name: station.properties.name,
                    rtArrivalTime: station.properties.arrival_time_rt,
                    estimatedArrivalTime: station.properties.arrival_time,
                    plannedDepartureTime: station.properties.departude_time,
                    rtDepartureTime: station.properties.departude_time_rt,
                    lat: station.geometry.coordinates[1],
                    lon: station.geometry.coordinates[0]
                };
            });
            this.updateBusPosition(message);
        }
    }

    // get stop time
    getStopTime(): any {
        this.crudService.getAll(OPN_BASE_URL + `/stop-times/trips-instance/${this.trip.id}`).subscribe((res: any) => {
            const observables = this.stations.map((station) => {
                const itineraryStationId = this.itinerary.itineraryStations.find(
                    (itineraryStation: { station: { id: number; }; }) =>
                        itineraryStation.station.id === station.id
                )?.id;

                if (itineraryStationId) {
                    return this.crudService.getAll(OPN_BASE_URL + `/stop-times/itinerary-station/${itineraryStationId}/trips-instance/${this.trip.id}`)
                        .pipe(
                            map((stopTime: any) => {
                                if (stopTime) {
                                    station.visited = stopTime.rtArrivalTime !== null;
                                    station.rtArrivalTime = stopTime.rtArrivalTime;
                                    station.estimatedDepartureTime = stopTime.departureTime;
                                    station.estimatedArrivalTime = stopTime.arrivalTime;
                                    station.rtDepartureTime = stopTime.rtDepartureTime;
                                }
                                return station; // Return the station after updates
                            })
                        );
                } else {
                    return of(station); // Return the station unmodified if no itineraryStationId found
                }
            });

            // Wait for all observables to complete
            forkJoin(observables).subscribe(updatedStations => {
                this.stations = updatedStations;
                this.initializeChart();
            });
        });
    }
}
