import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { VehiclesListTrackingComponent } from "./vehicles-list-tracking/vehicles-list-tracking.component";
import { AuthGuard } from "app/shared/auth/auth-guard.service";

const routes: Routes = [
  {
    path: "",
    component: VehiclesListTrackingComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Real Time Tracking",
      breadcrumb: "Real Time Tracking",
      permissions: ["Real_Time_Tracking"],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RealTimeTrackingRoutingModule {}
