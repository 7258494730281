import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  HostListener,
} from "@angular/core";
import { ROUTES } from "./vertical-menu-routes.config";
import { HROUTES } from "../horizontal-menu/navigation-routes.config";

import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from "ngx-device-detector";
import { ConfigService } from "../services/config.service";
import { Subscription } from "rxjs";
import { LayoutService } from "../services/layout.service";
import { CrudService } from "../services/crud.service";
import { OPN_BASE_URL } from "../global/var";
import { CompanySettingsService } from "../../modules/company-settings/services/company-settings.service";
import { AuthService } from "../auth/auth.service";
import { RouteInfo } from "./vertical-menu.metadata";

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  styleUrls: ["./vertical-menu.component.scss"],
  animations: customAnimations,
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("toggleIcon") toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = "assets/img/logo.png";
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  profileConfig: any;
  acronym: string;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private crudService: CrudService,
    private settingsService: CompanySettingsService,
    private authService: AuthService // Inject AuthService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }

  ngOnInit() {
    this.menuItems = ROUTES;
    this.getCompanySettings();
    this.settingsService.settings$.subscribe((settings) => {
      this.logoUrl = settings.logo
        ? settings.logo
        : "/assets/img/bus-svgrepo-com.png";
    });
    this.loadMenuItems();
  }

  ngAfterViewInit() {
    this.configSub = this.configService.templateConf$.subscribe(
      (templateConf) => {
        if (templateConf) {
          this.config = templateConf;
        }
        this.loadLayout();
        this.cdr.markForCheck();
      }
    );

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      (collapse) => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      }
    );
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }, 500);
  }

  loadLayout() {
    if (this.config.layout.menuPosition === "Top") {
      // Horizontal Menu
      if (this.innerWidth < 1200) {
        // Screen size < 1200
        this.menuItems = HROUTES;
      }
    } else if (this.config.layout.menuPosition === "Side") {
      // Vertical Menu
      this.loadMenuItems(); // Re-load menu items for vertical layout
    }

    if (this.config.layout.sidebar.backgroundColor === "white") {
      // this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      // this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    } else {
      this.collapseSidebar = false;
    }
  }

  loadMenuItems() {
    this.menuItems = this.filterRoutes(
      ROUTES,
      this.authService.getRolesFromToken()
    );
  }

  filterRoutes(routes: RouteInfo[], roles: string[]): RouteInfo[] {
    return routes
      .filter((route) => this.hasAccess(route, roles))
      .map((route) => ({
        ...route,
        submenu: this.filterRoutes(route.submenu || [], roles),
      }));
  }

  hasAccess(route: RouteInfo, roles: string[]): boolean {
    // Si la route a des permissions, vérifier si l'utilisateur a au moins une de ces permissions
    if (route.permissions) {
      return route.permissions.some((permission) => roles.includes(permission));
    }
    // Si pas de permissions spécifiques, considérer la route comme accessible
    return true;
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    } else {
      this.perfectScrollbarEnable = true;
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  getCompanySettings() {
    this.crudService
      .getAll<any>(OPN_BASE_URL + "/company-settings/all")
      .subscribe(async (res: any) => {
        const settingsMap = res.map((item: any) => ({
          configKey: item.configKey,
          configValue: JSON.parse(item.configValue),
        }));

        this.profileConfig = settingsMap.find(
          (item) => item.configKey === "profile-config"
        );
        this.acronym = this.profileConfig.configValue.name
          ? this.profileConfig.configValue.name
          : "";
        this.logoUrl = this.profileConfig.configValue.logo;
        if (!this.logoUrl || this.logoUrl === "") {
          this.logoUrl = "/assets/img/bus-svgrepo-com.png";
        }
      });
  }
}
