import { NotyService } from "./../../services/noty.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  Energy_BASE_URL,
  M_BASE_API,
  M_BASE_URL,
  OPN_BASE_URL,
} from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { KeycloakService } from "app/shared/services/keycloak.service";
import { UsersService } from "app/shared/services/users.service";

@Component({
  selector: "app-delete-modal",
  templateUrl: "./delete-modal.component.html",
  styleUrls: ["./delete-modal.component.scss"],
})
export class DeleteModalComponent {
  @Input() itemId: string;
  @Input() itemType: string;
  @Input() itemName: string;
  @Output() itemDeleted: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Creates an instance of DeleteModalComponent.
   * @param activeModal The active modal service for managing modal operations.
   * @param notyService Service for displaying notifications to the user.
   * @param crudService Service for performing CRUD operations.
   * @param keycloakService Service for managing Keycloak-related operations.
   */
  constructor(
    public activeModal: NgbActiveModal,
    private notyService: NotyService,
    private crudService: CrudService,
    private keycloakService: KeycloakService,
    private usersService: UsersService
  ) {}

  /**
   * Deletes an item based on the item type.
   * This method determines the type of the item to be deleted and
   * calls the corresponding deletion API endpoint. Upon successful
   * deletion, an event is emitted to notify other components of the
   * deletion. If an error occurs during the deletion process, a
   * notification is displayed to the user.
   *
   * The method handles the deletion of various item types including
   * services, service periods, routes, work requests, standards,
   * service groups, and realm roles. After the deletion process,
   * the modal is closed.
   */
  deleteItem(): void {
    if (this.itemType === "service") {
      this.crudService
        .delete(OPN_BASE_URL + "/services/delete", this.itemId)
        .subscribe(
          (data: any) => {
            this.itemDeleted.emit();
            this.notyService.displayNotification(
              "Service deleted successfully",
              "success"
            );
          },
          (error) => {
            if (error.status === 400) {
              this.notyService.displayNotification(error.error, "error");
            }
          }
        );
    } else if (this.itemType === "servicePeriod") {
      this.crudService
        .delete(OPN_BASE_URL + "/servicePeriod/delete", this.itemId)
        .subscribe(
          (data: any) => {
            this.itemDeleted.emit();
            this.notyService.displayNotification(
              "Service period deleted successfully",
              "success"
            );
          },
          (error) => {
            if (error.status === 400) {
              this.notyService.displayNotification(error.error, "error");
            }
          }
        );
    } else if (this.itemType === "route") {
      this.crudService
        .delete(OPN_BASE_URL + "/route/delete", this.itemId)
        .subscribe(
          (data: any) => {
            this.itemDeleted.emit();
            this.notyService.displayNotification(
              "Route deleted successfully",
              "success"
            );
          },
          (error) => {
            if (error.status === 400) {
              this.notyService.displayNotification(error.error, "error");
            }
          }
        );
    } else if (this.itemType === "workRequest") {
      this.crudService
        .delete(M_BASE_API + "/workRequest/delete", this.itemId)
        .subscribe((data: any) => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            "Work request deleted successfully",
            "success"
          );
        });
    } else if (this.itemType === "standard") {
      this.crudService
        .delete(Energy_BASE_URL + "/standard/delete", this.itemId)
        .subscribe((data: any) => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            "Standard deleted successfully",
            "success"
          );
        });
    } else if (this.itemType === "station") {
      this.crudService
        .delete(OPN_BASE_URL + "/stations/delete", this.itemId)
        .subscribe(
          (data: any) => {
            console.log("data", data);
            this.itemDeleted.emit();
            this.notyService.displayNotification(
              "Station deleted successfully",
              "success"
            );
          },
          (error) => {
            if (error.status === 400) {
              this.notyService.displayNotification(error.error, "error");
            }
          }
        );
    } else if (this.itemType === "serviceGroup") {
      this.crudService
        .delete(OPN_BASE_URL + "/serviceGroup/delete", this.itemId)
        .subscribe((data: any) => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            "Service group deleted successfully",
            "success"
          );
        });
    } else if (this.itemType === "scheduled-task") {
      this.crudService
        .delete(M_BASE_URL + "/scheduled-tasks", this.itemId)
        .subscribe((data: any) => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            "Scheduled task deleted successfully",
            "success"
          );
        });
    } else if (this.itemType === "realmRole") {
      this.keycloakService.deleteRealmRole(this.itemId).subscribe(
        (data: any) => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            "Role deleted successfully",
            "success"
          );
        },
        (error) => {
          if (error.status === 400) {
            this.notyService.displayNotification(error.error, "error");
          }
        }
      );
    } else if (this.itemType === "clientRole") {
      this.keycloakService
        .deleteClientRole(this.itemId)
        .subscribe((data: any) => {
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            "Permission deleted successfully",
            "success"
          );
        });
    } else if (this.itemType === "itinerary") {
      this.crudService
        .delete(OPN_BASE_URL + "/itinerary/delete", this.itemId)
        .subscribe(
          (data: any) => {
            this.itemDeleted.emit();
            this.notyService.displayNotification(
              "Itinerary deleted successfully",
              "success"
            );
          },
          (error) => {
            if (error.status === 400) {
              this.notyService.displayNotification(error.error, "error");
            }
          }
        );
    } else if (this.itemType === "user") {
      this.usersService.deleteUserAccount(this.itemName).subscribe(
        () => {
          console.log("User deleted successfully");
          this.itemDeleted.emit();
          this.notyService.displayNotification(
            "User deleted successfully",
            "success"
          );
        },
        (error) => {
          console.error("Error deleting user:", error);
          if (error.status === 400) {
            this.notyService.displayNotification(error.error, "error");
          }
        }
      );
    } else if (this.itemType === "inscription") {
      // Nouvelle condition pour gérer la suppression d'une inscription
      this.usersService
        .deleteInscription(Number(this.itemId)) // Convertir itemId en nombre ici
        .subscribe(
          () => {
            this.itemDeleted.emit();
          },
          (error) => {
            if (error.status === 400) {
              this.notyService.displayNotification(error.error, "error");
            }
          }
        );
    } else if (this.itemType === "rent") {
      this.crudService
        .delete(OPN_BASE_URL + "/rent/delete", this.itemId)
        .subscribe((data: any) => {
          this.itemDeleted.emit();
        });
    } else if (this.itemType === "intervention") {
      this.crudService
        .delete(M_BASE_URL + "/intervention/delete", this.itemId)
        .subscribe(
          (data: any) => {
            this.itemDeleted.emit();
            this.notyService.displayNotification(
              "Intervention deleted successfully",
              "success"
            );
          },
          (error) => {
            console.error("Error deleting user:", error);
            if (error.status === 400) {
              this.notyService.displayNotification(error.error, "error");
            }
          }
        );
    } else if (this.itemType === "crud") {
      this.itemDeleted.emit();
    }

    this.activeModal.close();
  }
}
