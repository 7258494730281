<div class="row">
  <div class="col-12">
    <div id="kick-start" class="card">
      <div class="card-header">
        <div class="content-header">
          <h5 *ngIf="formName" class="card-title">{{ formName }}</h5>
          <h5 *ngIf="!formName"> {{ (modelId ? 'Update ' : 'Add ') + (modelNameFromRoute?.replace('-', ' ') )|titlecase | translate }}





</h5>
        </div>
      </div>
      <div class="card-content">
        <div class="card-body">
          <section id="extended">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                  </div>
                  <div class="tab2-card">
                    <!-- <div class="nav nav-tabs" id="myTab" role="tablist">
                      <a class="nav-link active" id="details-tab" data-toggle="tab" href="#details" role="tab" aria-controls="details" aria-selected="true">Details</a>
                    </div> -->
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                        <form  [formGroup]="form" class="user-add">
                          <!-- <h4 *ngIf="showModalNameFromRoute" class="mb-3">
                            {{ modelNameFromRoute?.replace('-', ' ') | titlecase }} Details
                          </h4> -->
                          <app-form-group
                            (fileUploaded)="fileUploaded.emit($event)"
                            (filedChanged)="files=$event"
                            [fields]="fields"
                            [form]="form"
                            [modelId]="modelId"
                            [relatedModels]="relatedModels"
                            [url]="url"
                          ></app-form-group>
                            <app-cancel-button (click)="onCancel()"></app-cancel-button>
                            <app-submit-button (click)="onSubmit()" [disabled]="!form.valid"
                                             [modelId]="modelId">Submit</app-submit-button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
