import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import * as echarts from "echarts";
import { ICON_PATH, TEMPERATURE_ICON } from "./icon";
import { RealTimeTrackingService } from "../services/real-time-tracking.service";
// @ts-ignore
import L from "leaflet";
import { HttpClient, HttpParams } from "@angular/common/http";
import { RealTimeData } from "../vehicles-list-tracking/vehicles-list-tracking.component";
import { Vehicle } from "app/modules/maintenance-management/work-request/models/work-request";
import { OPN_BASE_URL } from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { TranslateService } from "@ngx-translate/core";
import { addHours, format } from "date-fns";
import { th } from "date-fns/locale";

@Component({
  selector: "app-vehicle-tracking",
  templateUrl: "./vehicle-tracking.component.html",
  styleUrls: ["./vehicle-tracking.component.scss"],
})
export class VehicleTrackingComponent implements OnInit, OnDestroy {
  private map = L.map;
  private markers: any[] = [];
  private routeLine: any;
  private charts: { [key: string]: echarts.ECharts } = {};
  routeLines: L.Polyline[] = []; // Array to store all route lines
  osrmRouteLines: L.Polyline[] = []; // Array to store all route lines
  lastKnownLocation: any;

  @Input() stations: any[] = [];
  @Input() isHistoric = true;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() vehicle: Vehicle;

  rtData: any;

  constructor(
    private _elementRef: ElementRef,
    private crudService: CrudService,
    private http: HttpClient,
    private realTimeTrackingService: RealTimeTrackingService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initMap();
  }

  ngOnDestroy() {
    this.disposeCharts();
    this.markers.forEach((marker) => this.map.removeLayer(marker));
    this.routeLines.forEach((routeLine) => this.map.removeLayer(routeLine));
    this.osrmRouteLines.forEach((routeLine) => this.map.removeLayer(routeLine));
    this.markers = [];
    // this.realTimeTrackingService.closeConnection();
  }

  private initGauges(): void {
    const gaugeNames = [
      "fuel-gauge",
      "speed-gauge",
      "rpm-gauge",
      "temperature-gauge",
    ];
    gaugeNames.forEach((name) => this.initGauge(name));
  }

  private initGauge(gaugeName: string): void {
    const el = document.getElementById(gaugeName);
    if (el) {
      switch (gaugeName) {
        case "fuel-gauge":
          this.initFuelGauge();
          break;
        case "speed-gauge":
          this.initSpeedGauge();
          break;
        case "rpm-gauge":
          this.initRpmGauge();
          break;
        case "temperature-gauge":
          this.initTemperatureGauge();
          break;
        default:
          break;
      }
    }
  }

  private disposeCharts(): void {
    Object.keys(this.charts).forEach((key) => {
      const chart = this.charts[key];
      if (chart && !chart.isDisposed()) {
        chart.dispose();
      }
    });
    this.charts = {};
  }

  updateValue(value: number, gaugeName: string): void {
    const chart = this.charts[gaugeName];
    if (chart && !chart.isDisposed()) {
      chart.setOption({
        series: [
          {
            data: [{ value: value }],
          },
        ],
      }); // Avoid resetting the chart, just update the value
    } else {
      console.warn(`Chart ${gaugeName} not found or disposed`);
      this.initFuelGauge(); // Reinitialize if chart is disposed
    }
  }

  initMap(): void {
    this.clearMap();
    const el = this._elementRef.nativeElement.querySelector(".rt-tracking-map");
    this.map = L.map(el, {
      center: [34.551117, 9.369019],
      zoom: 10,
    });
    L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);
    if (this.stations.length > 0) {
      this.stations.sort((a, b) => a.stationOrder - b.stationOrder);
      for (const station of this.stations) {
        this.addMarkers(
          [station.station.lat, station.station.lon],
          station.station.name
        );
      }
      this.drawRouteOSRM(this.stations.map((station) => station.station)).then(
        (r) => r
      );
    }

    if (!this.isHistoric) {
      this.getRealTimeData();
    }
  }

  addMarkers(latlng: any, stationName: string): void {
    const customIcon = L.icon({
      iconUrl: "./assets/img/leaflet/bus-stop.png",
      iconSize: [25, 41],
      text: stationName,
    });
    const marker = L.marker(latlng, { icon: customIcon }).addTo(this.map);
    // Add a popup to the marker and open it
    marker.bindPopup(stationName).openPopup();
    this.markers.push(marker);
  }

  /**
   * Draws a route on the map using the OSRM API.
   * Fetches the route data from OSRM and draws a polyline on the map.
   * Adjusts the map bounds to fit the route.
   *
   * @param {Object[]} stations - An array of station objects with latitude and longitude properties.
   * @returns {Promise<void>} A promise that resolves when the route is drawn.
   */
  async drawRouteOSRM(stations: { lat: number; lon: number }[]): Promise<void> {
    if (stations.length < 2) {
      return;
    }

    const coordinates = stations
      .map((station) => `${station.lon},${station.lat}`)
      .join(";");
    const url = `https://router.project-osrm.org/route/v1/driving/${coordinates}?overview=full&geometries=geojson`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.code !== "Ok") {
        console.error("OSRM request failed:", data);
        return;
      }
      const route = data.routes[0];

      const routeCoordinates = route.geometry.coordinates.map(
        (coord: any[]) => [coord[1], coord[0]]
      );

      const newRouteLine = L.polyline(routeCoordinates, {
        color: "#1e90ff",
        weight: 5,
      }).addTo(this.map);
      this.osrmRouteLines.push(newRouteLine);
    } catch (error) {
      console.error("Error fetching OSRM route:", error);
    }
  }

  clearMap(): void {
    this.markers.forEach((marker) => this.map.removeLayer(marker));
    this.markers = [];
    if (this.routeLine) {
      this.map.removeLayer(this.routeLine);
      this.routeLine = null;
    }
    if (this.routeLines.length > 0) {
      this.routeLines.forEach((routeLine) => this.map.removeLayer(routeLine));
      this.routeLines = [];
    }

    if (this.osrmRouteLines.length > 0) {
      this.osrmRouteLines.forEach((routeLine) =>
        this.map.removeLayer(routeLine)
      );
      this.osrmRouteLines = [];
    }
  }

  addVehiclesMarker(latlng: any): void {
    const customIcon = L.icon({
      iconUrl: this.vehicle.family.bus
        ? "./assets/img/markers/bus.png"
        : "./assets/img/markers/car.png",
      iconSize: [25, 41],
      text: this.vehicle.vehicleNumber,
    });
    const marker = L.marker(latlng, { icon: customIcon }).addTo(this.map);
    this.markers.push(marker);
    this.map.setView(marker.getLatLng(), 20);
    this.openPopup(this.rtData, marker);
  }

  getRealTimeData(): void {
    this.crudService
      .getOne(OPN_BASE_URL + "/rt", this.vehicle.idBoitier.toString(10))
      .subscribe(
        (res: { lat: number; lon: number; date: string }) => {
          this.rtData = res;
          this.addVehiclesMarker([this.rtData.lat, this.rtData.lon]);
          this.initGauges();
          if (this.vehicle.family.bus) {
            this.getCurrentTripData();
          } else {
            // archive data for the past 2 hours
            this.startDate = format(
              addHours(new Date(), -2),
              "yyyy-MM-dd HH:mm:ss"
            );
            this.endDate = format(new Date(), "yyyy-MM-dd HH:mm:ss");
            this.getArchiveData(this.startDate, this.endDate);
          }
          this.getUpdatedData(this.vehicle.idBoitier);
        },
        (error: any) => {
          console.error("Error fetching real time data:", error);
        }
      );
  }

  getCurrentTripData(): void {
    // format now like yyyy-mm-dd hh:mm:ss
    // const now: string = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    const now = "2024-09-10 09:00:56";
    this.crudService
      .getAllWithParams(
        OPN_BASE_URL + `/trips-instance/bus-trip/${this.vehicle.id}`,
        new HttpParams().set("date", now)
      )
      .subscribe(
        (res: any) => {
          const data = res;
          this.startDate = data.rtDeparture
            ? data.rtDeparture
            : data.plannedDeparture;
          this.endDate = data.rtArrival
            ? data.rtArrival
            : data.estimatedArrival;
          this.getArchiveData(
            this.startDate.replace("T", " ").replace("Z", ""),
            this.endDate.replace("T", " ").replace("Z", "")
          );
        },
        (error) => {
          // Handle error
          console.error("Error fetching trip data", error);
        }
      );
  }

  getArchiveData(startDate: string, endDate: string): void {
    this.http
      .get(OPN_BASE_URL + `/archive2/${this.vehicle.idBoitier}/dataRange`, {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
      })
      .subscribe((res: any) => {
        const data = res;
        data.push(this.rtData);
        // draw polyline between data points
        if (data.length > 0) {
          for (let i = 0; i < data.length - 1; i++) {
            const routeLine = L.polyline(
              [
                [data[i].lat, data[i].lon],
                [data[i + 1].lat, data[i + 1].lon],
              ],
              {
                color: "#1e90ff",
                weight: 5,
              }
            ).addTo(this.map);
            this.routeLines.push(routeLine);

            const marker = L.circleMarker([data[i].lat, data[i].lon], {
              radius: 2,
              color: "#ff4500",
              fillOpacity: 1,
            }).addTo(this.map);
            this.updatePopupContent(marker, data[i]);
            this.markers.push(marker);
          }

          // Add marker for the last location in the data array
          const lastMarker = L.circleMarker(
            [data[data.length - 1].lat, data[data.length - 1].lon],
            {
              radius: 2,
              color: "#ff4500",
              fillOpacity: 1,
            }
          ).addTo(this.map);
          this.updatePopupContent(lastMarker, data[data.length - 1]);
          this.markers.push(lastMarker);

          // zoom in on the last known location
          this.map.setView(
            [data[data.length - 1].lat, data[data.length - 1].lon],
            15
          );
        }
      });
  }

  initFuelGauge(): void {
    // If the gauge chart instance already exists, avoid reinitializing
    if (!this.charts["fuel-gauge"]) {
      const gauge = echarts.init(document.getElementById("fuel-gauge"));
      const option = {
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },
        series: [
          {
            name: "Fuel",
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            center: ["50%", "75%"],
            radius: "90%",
            data: [
              {
                value: this.rtData.Carburant
                  ? this.rtData.Carburant
                  : this.rtData.car,
              },
            ],
            axisLine: {
              lineStyle: {
                width: 15,
                color: [
                  [0, "#FF4500"], // red
                  [0.25, "#FF4500"],
                  [0.5, "#FFD700"], // yellow
                  [1, "#32CD32"], // green
                ],
              },
            },
            splitLine: {
              length: 12,
              lineStyle: {
                color: "auto",
              },
            },
            axisLabel: {
              distance: 20,
              fontSize: 14,
              fontWeight: "bold",
              color: "#ffffff",
              fontFamily: "Arial",
              formatter: function (value: number) {
                return value % 20 === 0 ? value.toString() : "";
              },
            },
            axisTick: {
              splitNumber: 5,
              length: 8,
              lineStyle: {
                color: "auto",
              },
            },
            anchor: {
              show: true,
              itemStyle: {},
              offsetCenter: ["-10%", "-10%"],
              size: 20,
              icon: ICON_PATH,
            },
            pointer: {
              width: 5,
              length: "70%",
              color: "auto",
            },
            detail: {
              valueAnimation: true,
              formatter: "{value}%",
              fontSize: 16,
              color: "#f0f0f0",
              backgroundColor: "rgba(0,0,0,0.5)",
              borderColor: "#fff",
              borderWidth: 2,
              shadowColor: "#000",
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              offsetCenter: [0, "-100%"],
            },
          },
        ],
      };
      gauge.setOption(option);
      this.charts["fuel-gauge"] = gauge; // Store the instance in the class
    }
  }

  initSpeedGauge(): void {
    if (document.getElementById("speed-gauge")) {
      echarts.dispose(document.getElementById("speed-gauge"));
    }
    const gauge = echarts.init(document.getElementById("speed-gauge"));
    const option = {
      tooltip: {
        formatter: "{a} <br/>{b} : {c} km/h",
      },
      series: [
        {
          name: "Speed",
          type: "gauge",
          startAngle: 180,
          endAngle: 0,
          center: ["50%", "75%"],
          radius: "90%",
          min: 0,
          max: 220,
          splitNumber: 11, // Adjusting to show more scale labels
          data: [
            {
              value: this.rtData.vitesse
                ? this.rtData.vitesse
                : this.rtData.vitesseInst,
            },
          ],
          axisLine: {
            lineStyle: {
              width: 15,
              color: [
                [0.5, "#1e90ff"], // Blue for 0-110
                [0.727, "#f7b733"], // Yellow for 110-160
                [1, "#ff4500"], // Red for 160-220
              ],
            },
          },
          axisTick: {
            splitNumber: 5,
            length: 8,
            lineStyle: {
              color: "auto",
            },
          },
          splitLine: {
            length: 12,
            lineStyle: {
              color: "auto",
            },
          },
          axisLabel: {
            distance: 20, // Distance of labels from the gauge
            fontSize: 14, // Font size of labels
            fontWeight: "bold", // Font weight
            color: "#ffffff", // Color of labels
            fontFamily: "Arial", // Font family
            formatter: function (value: number) {
              if (value % 40 === 0) {
                return value.toString();
              }
              return "";
            },
          },
          pointer: {
            width: 5,
            length: "70%",
            color: "auto",
          },
          anchor: {
            show: true,
            itemStyle: {},
            offsetCenter: ["-10%", "-10%"],
            size: 20,
            icon: "",
          },
          title: {
            offsetCenter: [0, "110%"],
            fontSize: 20,
            color: "#fff",
          },
          detail: {
            valueAnimation: true,
            formatter: "{value} km/h",
            fontSize: 16,
            color: "#f0f0f0", // Lighter color for a brighter effect
            backgroundColor: "rgba(0,0,0,0.5)",
            borderColor: "#fff", // White border color for contrast
            borderWidth: 2, // Border width to add some emphasis
            shadowColor: "#000", // Shadow color to add depth
            shadowBlur: 10, // Blur radius for the shadow
            shadowOffsetX: 0, // Horizontal shadow offset
            shadowOffsetY: 0, // Vertical shadow offset
            offsetCenter: [0, "-100%"],
          },
        },
      ],
    };
    gauge.setOption(option, { notMerge: true, lazyUpdate: false });
    this.charts["speed-gauge"] = gauge;
  }

  // rpm gauge
  initRpmGauge(): void {
    if (document.getElementById("rpm-gauge")) {
      echarts.dispose(document.getElementById("rpm-gauge"));
    }
    const gauge = echarts.init(document.getElementById("rpm-gauge"));
    const option = {
      tooltip: {
        formatter: "{a} <br/>{b} : {c} rpm",
      },
      series: [
        {
          name: "RPM",
          type: "gauge",
          startAngle: 90,
          endAngle: 0,
          center: ["50%", "75%"],
          radius: "90%",
          min: 0,
          max: 8000,
          splitNumber: 8, // Adjusting to show more scale labels
          data: [
            { value: this.rtData.RPM ? this.rtData.RPM : this.rtData.rpm },
          ],
          axisLine: {
            lineStyle: {
              width: 15,
              color: [
                // one color for all ranges = grey
                [1, "#808080"],
              ],
            },
          },
          axisTick: {
            splitNumber: 5,
            length: 8,
            lineStyle: {
              color: "auto",
            },
          },
          splitLine: {
            length: 12,
            lineStyle: {
              color: "auto",
            },
          },
          axisLabel: {
            distance: 20, // Distance of labels from the gauge
            fontSize: 14, // Font size of labels
            fontWeight: "bold", // Font weight
            color: "#ffffff", // Color of labels
            fontFamily: "Arial", // Font family
            formatter: function (value: number) {
              if (value % 4000 === 0) {
                return value.toString();
              }
              return "";
            },
          },
          pointer: {
            width: 5,
            length: "70%",
            color: "auto",
          },
          anchor: {
            show: true,
            itemStyle: {},
            offsetCenter: ["-10%", "-10%"],
            size: 20,
            icon: "",
          },
          title: {
            offsetCenter: [0, "110%"],
            fontSize: 20,
            color: "#fff",
          },
          detail: {
            valueAnimation: true,
            width: "20%",
            lineHeight: 50,
            borderRadius: 8,
            offsetCenter: ["100%", "12%"],
            fontSize: 14,
            fontWeight: "bolder",
            formatter: "{value} rpm",
            color: "#f0f0f0", // Lighter color for a brighter effect
          },
        },
      ],
    };
    gauge.setOption(option);
    this.charts["rpm-gauge"] = gauge;
  }

  // temperature gauge
  initTemperatureGauge(): void {
    if (document.getElementById("temperature-gauge")) {
      echarts.dispose(document.getElementById("temperature-gauge"));
    }
    const gauge = echarts.init(document.getElementById("temperature-gauge"));
    const option = {
      tooltip: {
        formatter: "{a} <br/>{b} : {c} °C",
      },
      series: [
        {
          name: "Temperature",
          type: "gauge",
          startAngle: -180,
          endAngle: 90,
          center: ["50%", "75%"],
          radius: "90%",
          min: 50,
          max: 150,
          splitNumber: 8, // Adjusting to show more scale labels
          data: [{ value: this.rtData.T1 ? this.rtData.T1 : this.rtData.temp }],
          axisLine: {
            lineStyle: {
              width: 15,
              color: [
                // blue for 0-90
                [0.5, "#1e90ff"],
                [1, "#FF4500"], // Red for 110-150
              ],
            },
          },
          axisTick: {
            splitNumber: 5,
            length: 8,
            lineStyle: {
              color: "auto",
            },
          },
          splitLine: {
            length: 12,
            lineStyle: {
              color: "auto",
            },
          },
          axisLabel: {
            distance: 20, // Distance of labels from the gauge
            fontSize: 14, // Font size of labels
            fontWeight: "bold", // Font weight
            color: "#ffffff", // Color of labels
            fontFamily: "Arial", // Font family
            formatter: function (value: number) {
              if (value % 50 === 0) {
                return value.toString();
              }
              return "";
            },
          },
          pointer: {
            width: 5,
            length: "70%",
            color: "auto",
          },
          anchor: {
            show: true,
            itemStyle: {},
            offsetCenter: ["-10%", "-10%"],
            size: 20,
            icon: TEMPERATURE_ICON,
          },
          title: {
            offsetCenter: [0, "110%"],
            fontSize: 20,
            color: "#ffffff",
          },
          detail: {
            valueAnimation: true,
            width: "20%",
            lineHeight: 50,
            borderRadius: 8,
            offsetCenter: ["-100%", "12%"],
            fontSize: 14,
            fontWeight: "bolder",
            formatter: "{value} °C",
            color: "#f0f0f0", // Lighter color for a brighter effect
          },
        },
      ],
    };
    gauge.setOption(option);
    this.charts["temperature-gauge"] = gauge;
  }

  getUpdatedData(idBoitier: number): void {
    this.realTimeTrackingService.connect();
    this.realTimeTrackingService.message$.subscribe((message) => {
      this.handleMessage(message);
    });
  }

  handleMessage(message: any): void {
    const rt = JSON.parse(JSON.stringify(message));
    if (rt.id === this.vehicle.idBoitier) {
      this.rtData = rt;
      this.updateValue(this.rtData.vitesse, "speed-gauge");
      this.updateValue(this.rtData.Carburant, "fuel-gauge");
      this.updateValue(this.rtData.RPM, "rpm-gauge");
      this.updateValue(this.rtData.T1, "temperature-gauge");
      this.vehicle.location = this.rtData.localite;
      this.updateVehicleMarker(this.rtData);
    }
  }

  updateVehicleMarker(rtData: RealTimeData): void {
    // Store the last known location from the first marker
    this.lastKnownLocation = {
      lat: this.markers[0].getLatLng().lat,
      lon: this.markers[0].getLatLng().lng,
    };

    // Update the first marker with the new location
    this.markers[0].setLatLng([rtData.lat, rtData.lon]);

    // zoom in on the last known location
    //this.map.setView([rtData.lat, rtData.lon], 15);

    // Create a new circle marker at the new location without replacing the old marker
    const newLatLng = L.latLng(rtData.lat, rtData.lon);
    const circleMarker = L.circleMarker(newLatLng, {
      radius: 2,
      color: "#ff4500",
      fillOpacity: 1,
    }).addTo(this.map);

    // Draw a new polyline from the last known location to the new coordinates
    const newCoords = { lat: rtData.lat, lon: rtData.lon };
    const routeLine = L.polyline(
      [
        [this.lastKnownLocation.lat, this.lastKnownLocation.lon],
        [newCoords.lat, newCoords.lon],
      ],
      {
        color: "#1e90ff",
        weight: 5,
      }
    ).addTo(this.map);

    // Store the new route line
    this.routeLines.push(routeLine);

    // Update the popup content for the circle marker
    this.updatePopupContent(circleMarker, rtData);

    this.updatePopupContent(this.markers[0], rtData);
  }

  openPopup(rtData: RealTimeData, marker: any): void {
    this.getReverseGeocode(rtData.lat, rtData.lon);

    // Fetch all translations at once
    this.translate
      .get([
        "Track",
        "DATE",
        "Speed",
        "Temp",
        "RPM",
        "Fuel Level",
        "Distance",
        "Location",
      ])
      .subscribe((translations: any) => {
        const popupContent = `
        <div class="popup-container" style="font-family: Arial, sans-serif; color: #333;">
            <h3 class="vehicle-number" style="font-size: 18px; margin-bottom: 10px;">
                <i class="fas fa-bus" 
                style="color: #007bff; margin-right: 5px;"></i>${
                  this.vehicle.vehicleNumber
                }
            </h3>
            <div class="vehicle-stats" style="font-size: 14px; line-height: 1.5;">
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["DATE"]}:</strong></span>
                    <span>${rtData.date
                      .replace("T", " ")
                      .replace("Z", "")}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Speed"]}:</strong></span>
                    <span>${rtData.vitesseInst} km/h</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Temp"]}:</strong></span>
                    <span>${rtData.temp} °C</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["RPM"]}:</strong></span>
                    <span>${rtData.rpm}</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Fuel Level"]}:</strong></span>
                    <span>${rtData.car} L</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Distance"]}:</strong></span>
                    <span>${rtData.dist} km</span>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <span><strong>${translations["Location"]}:</strong></span>
                    <span>${this.vehicle.location}</span>
                </div>
            </div>
        </div>
    `;

        marker.bindPopup(popupContent);
        marker.openPopup();
      });
  }

  updatePopupContent(marker: any, rtData: RealTimeData): void {
    console.log(rtData.date);
    this.getReverseGeocode(rtData.lat, rtData.lon);
    this.translate
      .get([
        "Track",
        "DATE",
        "Speed",
        "Temp",
        "RPM",
        "Fuel Level",
        "Distance",
        "Location",
      ])
      .subscribe((translations: any) => {
        const popupContent = `
                          <div class="popup-container" style="font-family: Arial, sans-serif; color: #333;">
                            <h3 class="vehicle-number" style="font-size: 18px; margin-bottom: 10px;">
                                <i class="fas fa-bus" 
                                style="color: #007bff; margin-right: 5px;"></i>${
                                  this.vehicle.vehicleNumber
                                }
                            </h3>
                            <div class="vehicle-stats" style="font-size: 14px; line-height: 1.5;">
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["DATE"]
                                    }:</strong></span>
                                    <span>${rtData.date
                                      .replace("T", " ")
                                      .replace("Z", "")}</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["Speed"]
                                    }:</strong></span>
                                    <span>${rtData.vitesse} km/h</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["Temp"]
                                    }:</strong></span>
                                    <span>${rtData.T1} °C</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["RPM"]
                                    }:</strong></span>
                                    <span>${rtData.RPM}</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["Fuel Level"]
                                    }:</strong></span>
                                    <span>${rtData.Carburant} L</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["Distance"]
                                    }:</strong></span>
                                    <span>${rtData.km} km</span>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <span><strong>${
                                      translations["Location"]
                                    }:</strong></span>
                                    <span>${this.vehicle.location}</span>
                                </div>
                            </div>
                        </div>
                        `;
        marker.bindPopup(popupContent);
        if (marker.isPopupOpen()) {
          marker.openPopup();
        }
      });
  }

  getReverseGeocode(lat: number, lng: number): void {
    this.http
      .get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=fr`
      )
      .subscribe((res: any) => {
        this.vehicle.location = res.principalSubdivision + ", " + res.locality;
      });
  }
}
