<div class="modal-header">
    <h4 class="modal-title">{{"Report_Trip"|translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="reportForm" (ngSubmit)="saveForm()">
        <div class="bdr mb-2">
            <div class="row mt-2 mr-2 ">

                <div class="col d-flex justify-content-end">
                    <div class="status-pill mt-1 mr-1"
                    [ngClass]="{'bg-secondary': data.status == '1', 'bg-info':data.status == '2', 'bg-warning':data.status == '3', 'bg-light':data.status == '5', 'bg-danger':data.status == '4'}">
                </div>
                {{ data.status == 1 ? ("NOT_ASSIGNED" | translate) : (data.status == 4 ? ("CANCELLED" | translate) : 
                (data.status == 2 ? ("ASSIGNED" | translate) : data.status == 5 ? ("REPORTED" | translate) : "IN_PROGRESS" | translate)) }}
                    </div>
            </div>
            <div class="row">
                <div class="col d-flex align-items-center  bg-lighten-4 rounded p-3 mr-3  ml-3 ">
                    <img src="./assets/img/icons/clock.png" class="mr-5 mb-1 icon-size" alt="">
                    <div class="row">
                        <div class="col p-1 d-flex align-content-center flex-wrap">
                            <div class="badge bg-success col-5 mr-3">{{ data.lineDirection }}</div>
                            <div class="badge bg-success col-5 mr-3">{{ data.lineNumber }}</div>
                            <div class=" badge bg-light col-11 text-dark mt-2">{{ data.tripRoute }}</div>
                        </div>
                      
                    </div>
                    <div class="form-group row align-items-center ml-2 mr-2">
                        <div class="col-12">
                            <div class="d-flex align-items-center custom-timepicker">
                                <ngb-timepicker formControlName="plannedDeparture"></ngb-timepicker>
                                <button class="btn btn-info mb-3 mt-3" style="opacity: 0;">&nbsp;</button>
                            </div>
                        </div>
                    </div>
                      <div class="col-3 "><span>({{"ESTIMATED_ARRIVAL"|translate}}{{ formattedEstimatedArrival
                                }})</span></div>
               

                </div>
            </div>

          
        

          
                <div class="row m-0 mb-1 rounded-bottom ">
                    <div class="col p-1 d-flex justify-content-end">
                        <div class="badge badge-secondary p-1 mr-1" [ngClass]="{
                  'bg-danger': data.driverAvailable == 1,
                  'bg-success': data.driverAvailable == 0
                 
              }">

                            <i *ngIf="data.driverAvailable == 0" class="fa-solid fa-check"></i>
                            <i *ngIf="data.driverAvailable == 1" class="fa-solid fa-x"></i>
                            <img src="./assets/img/icons/driver.png" class="mx-1 icon-small">

                        </div>

                        <div class="badge badge-secondary p-1 mr-1" [ngClass]="{
                'bg-danger': data.receiverAvailable == 1,
                'bg-success': data.receiverAvailable == 0
             
            }">
                            <i *ngIf="data.receiverAvailable == 0" class="fa-solid fa-check"></i>
                            <i *ngIf="data.receiverAvailable == 1" class="fa-solid fa-x"></i>
                            <img src="./assets/img/icons/cashier.png" class="mx-1 icon-small">

                        </div>

                        <div class="badge badge-secondary p-1 mr-1" [ngClass]="{
                  'bg-danger': data.busAvailable == 1,
                  'bg-success': data.busAvailable == 0
                
                
                 
              }">
                            <i *ngIf="data.busAvailable == 0" class="fa-solid fa-check"></i>
                            <i *ngIf="data.busAvailable == 1" class="fa-solid fa-x"></i>
                            <img src="./assets/img/icons/bus.png" class="mx-1 icon-small">

                        </div>
                    </div>
                </div>
            



            <div  *ngIf="data.driverAvailable === 1"class="alert alert-light mr-1 ml-1">
               <span >{{"REPORT_DRIVER_ERROR_MESG" | translate}}</span> <span class="text-danger">"{{overlappingDriverMission}}"</span>
            </div>
            <div *ngIf="data.receiverAvailable === 1" class="alert alert-light mr-1 ml-1">
                <span *ngIf="data.receiverAvailable === 1"> {{"REPORT_RECEIVER_ERROR_MESG" | translate}}</span> <span class="text-danger">"{{overlappingReceiverMission}}"</span>
            </div>
            <div *ngIf="data.busAvailable === 1" class="alert alert-light mr-1 ml-1">
                <span  *ngIf="data.busAvailable === 1">{{"REPORT_BUS_ERROR_MESG" | translate}}</span> <span class="text-danger">"{{overlappingBusMission}}"</span>
            </div>


            
        </div>

        <div class="modal-footer row col-md-12 d-flex justify-content-end">
            <button type="submit" class="btn btn-info" [disabled]="isSaveDisabled()">Save</button>
            <button type="button" class="btn btn-danger"
                (click)="closeModal()">{{"Close"|translate}}</button>
        </div>
    </form>
</div>