import { AuthService } from 'app/shared/auth/auth.service';
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
  NgbTimeStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { TimelineElement } from "app/shared/components/horizontal-timeline/timeline-element";
import {
  BASE_API_FLEET,
  BASE_API_STAFF,
  OPN_BASE_URL,
} from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";
import { catchError, switchMap, tap } from "rxjs/operators";
import swal from "sweetalert2";
import { Observable, of } from "rxjs";
import { TripReporterModalComponent } from "../trip-reporter-modal/trip-reporter-modal.component";
import { NotyService } from "app/shared/services/noty.service";
import L from "leaflet";
import { Station } from "../../../modules/operating-networks/stations-management/models/stations.model";
import { HttpClient, HttpParams } from '@angular/common/http';
import { TripsInstances } from 'app/modules/operating-networks/trips-management/models/trips-instances.model';

interface MissionLog {
  id: number;
  missionId: number;
  responsableId: number;
  responsableName: string;
  actionType: string;
  tripCode: string;
  fieldName: string;
  oldValue: string;
  newValue: string;
  timestamp: string;
}

enum ActionType {
  CREATION = "CREATION",
  MODIFICATION = "MODIFICATION",
  AFFECTATION = "AFFECTATION",
  REPORTER = "REPORTER",
  CANCELLED = "CANCELLED",
  INPROGRESS = "INPROGRESS",
  FINISHED = "FINISHED",
}

@Component({
  selector: "app-trips-details",
  templateUrl: "./trips-details.component.html",
  styleUrls: ["./trips-details.component.scss"],
})
export class TripsDetailsComponent implements OnInit {
  timeline: TimelineElement[] = [];
  isEditing = false;
  @Input() data;
  applicants: any;
  missions: any;
  missionLogs: MissionLog[] = [];
  ActionType = ActionType;

  assignmenetForm: FormGroup;
  editMode: boolean = false;
  dataTime;

  dataTimeJoined: string;
  time: NgbTimeStruct;
  overlappingDriverMission: string | null = null;
  overlappingReceiverMission: string | null = null;
  overlappingBusMission: string | null = null;
  overlappingBussMissionId: number;
  overlappingReceiverMissionId: number;
  overlappingDriverMissionId: number;
  selectedDriver: any;
  selectedReceiver: any;

  chauffeursEnRepos: any[] = [];
  receveursDisponibles: any[] = [];

  availableDrivers: any[] = [];
  availableReceiver: any[] = [];
  vehicles: any;

  selectedDriverId: any;
  selectedReceiverId: any;
  selectedBusId: any;
  busList: any;
  removeDriverChecked = false;
  removeReceiverChecked = false;
  removeBusChecked = false;
  driverOnLeave;
  receiverOnLeave;
  selectedDriverOnLeave: boolean = false;
  selectedReceiverOnLeave: boolean = false;
  forceAssignReceiver: boolean = false;
  forceAssignDriver: boolean = false;
  groupedReceivers: any[];
  groupedDrivers: any[];
  active = 1;
  missionWithDriver: any;
  missionWithReceiver: any;
  receiver: any;
  driver = [];
  driverMissions: any;
  availableBus: any;
  @Output() itemDeleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() itemCanceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() itemClosed: EventEmitter<void> = new EventEmitter<void>();

  private map = L.map;
  stations: Station[] = [];
  itinerary: any;
  mapVisible = false;
  username: any;

  constructor(
    public activeModal: NgbActiveModal,
    private crudService: CrudService,
    private fb: FormBuilder,
    private notyService: NotyService,
    private modalService: NgbModal,
    private _elementRef: ElementRef,
    private authservice: AuthService,
    private http: HttpClient  ) {
    this.username = this.authservice.getUsernameFromToken();

    this.fetchApplicants();
    this.assignmenetForm = this.fb.group({
      id: [""],
      bus: this.fb.group({
        id: [""],
      }),
      plannedDeparture: [""],
      driver: this.fb.group({
        id: [""],
      }),
      reciever: this.fb.group({
        id: [""],
      }),
    });
  }

  ngOnInit(): void {
    this.fetchMissionLogs();
    this.initialiserTimePicker();
    this.initMap();
    this.fetchApplicants()
      .pipe(
        switchMap((applicants: any) => {
          this.applicants = applicants.data;
          this.driver = this.applicants.filter((applicant) =>
            applicant.job.includes(1)
          );
          this.receiver = this.applicants.filter((applicant) =>
            applicant.job.includes(2)
          );

          if (this.data && this.data.plannedDeparture) {
            const departureDate = new Date(this.data.plannedDeparture);
            const dayOfWeek = departureDate.getDay();
            this.driverOnLeave = this.filterDriversOnLeave(dayOfWeek);
            this.receiverOnLeave = this.filterReceiversOnLeave(dayOfWeek);

            this.driver = this.driver.filter(
              (driver) => !this.driverOnLeave.includes(driver)
            );
            this.receiver = this.receiver.filter(
              (receiver) => !this.receiverOnLeave.includes(receiver)
            );
            this.groupedReceivers = [
              ...this.receiver.map((r) => ({
                id: r.id,
                name: `${r.firstName} ${r.lastName}`,
                group: "Available Receiver",
              })),
              ...this.receiverOnLeave.map((r) => ({
                id: r.id,
                name: `${r.firstName} ${r.lastName}`,
                group: "Receiver on Rest",
              })),
            ];
            this.groupedDrivers = [
              ...this.driver.map((r) => ({
                id: r.id,
                name: `${r.firstName} ${r.lastName}`,
                group: "Available Driver",
              })),
              ...this.driverOnLeave.map((r) => ({
                id: r.id,
                name: `${r.firstName} ${r.lastName}`,
                group: "Driver on Rest",
              })),
            ];
          }
          return this.fetchMissionList();
        })
      )
      .subscribe({
        next: (missions: any) => {
          this.missions = missions;

          this.fetchVehicleList();
        },
        error: (error) => {
          console.error("Error fetching data:", error);
        },
      });
  }

  groupBy(item: any) {
    return item.group;
  }

  /**
   * Toggles the force assignment for the driver.
   */

  toggleForceAssignDriver(): void {
    this.forceAssignDriver = !this.forceAssignDriver;
  }

  /**
   * Toggles the force assignment for the receiver.
   */

  toggleForceAssignReceiver(): void {
    this.forceAssignReceiver = !this.forceAssignReceiver;
  }

  /**
   * Filters drivers who are on leave for a specific day.
   * @param day The day of the week (0-6, where 0 represents Sunday).
   * @returns A list of drivers on leave for the given day.
   */

  filterDriversOnLeave(day: number): any[] {
    if (this.driver) {
      return this.driver.filter((driver) => driver.dayOff.includes(day));
    }
  }

  /**
   * Filters receivers who are on leave for a specific day.
   * @param day The day of the week (0-6, where 0 represents Sunday).
   * @returns A list of receivers on leave for the given day.
   */

  filterReceiversOnLeave(day: number): any[] {
    if (this.receiver) {
      return this.receiver.filter((receiver) => receiver.dayOff.includes(day));
    }
  }

  /**
   * Fetches the list of applicants from the server.
   * @returns An observable containing the list of applicants.
   */

  fetchApplicants() {
    const url = `${BASE_API_STAFF}/staff?username=${encodeURIComponent(
          this.username
        )}`;
            return this.crudService.getAll(url);
  }

  /**
   * Fetches the list of missions from the server.
   * @returns An observable containing the list of missions.
   */

  fetchMissionList() {
    const params = new HttpParams().set('username', this.username);
    const apiUrl = `${OPN_BASE_URL}/trips-instance/list`;
    return this.http.get<TripsInstances[]>(apiUrl, { params });

  }


  /**
   * Checks if editing should be disabled based on the mission status and time.
   * @returns True if editing should be disabled, false otherwise.
   */
  isEditDisabled(): boolean {
    const now = new Date();
    const plannedDeparture = new Date(this.data.plannedDeparture);
    return now > plannedDeparture;
  }

  /**
   * Fetches the list of vehicles from the server and filters them to get the bus list.
   */



    fetchVehicleList() {
      const url = `${BASE_API_FLEET}/vehicles?username=${encodeURIComponent(
        this.username
      )}`;
      this.crudService.getAll(url).subscribe(
        (data: any) => {
          this.vehicles = data.data;
          this.busList = this.vehicles.filter(
            (vehicle) => vehicle.family.bus === true && vehicle.status === 1
          );
          this.busList = [
            // { id: 0, vehicleNumber: 'Unassigned' },
            ...this.busList,
          ];
        },
        (error) => {
          console.error("Error fetching applicants:", error);
        }
      );
    }

  /**
   * Toggles the removal state of the driver.
   */

  toggleRemoveDriver() {
    this.removeDriverChecked = !this.removeDriverChecked;
  }

  /**
   * Toggles the removal state of the receiver.
   */

  toggleRemoveReceiver() {
    this.removeReceiverChecked = !this.removeReceiverChecked;
  }

  /**
   * Toggles the removal state of the bus.
   */

  toggleRemoveBus() {
    this.removeBusChecked = !this.removeBusChecked;
  }

  /**
   * Determines if the save button should be disabled based on overlapping missions and force assign flags.
   *
   * @returns True if the save button should be disabled, otherwise false.
   */

  isSaveDisabled(): boolean {
    if (
      (this.overlappingDriverMission && !this.removeDriverChecked) ||
      (this.overlappingReceiverMission && !this.removeReceiverChecked) ||
      (this.overlappingBusMission && !this.removeBusChecked) ||
      (this.selectedDriverOnLeave && !this.forceAssignDriver) ||
      (this.selectedReceiverOnLeave && !this.forceAssignReceiver)
    ) {
      return true;
    }

    return false;
  }

  saveForm() {
    let deleteRequest$: Observable<any> = of(null);

    const handleStaffDeletion = (
      condition: boolean,
      staffId: number,
      tripId: number,
      successMessage: string
    ): Observable<any> => {
      if (condition) {
        return this.crudService.removeStaffFromTrip(staffId, tripId).pipe(
          tap(() => {
            console.log(`${successMessage} successfully`);
          }),
          catchError((error) => {
            console.error(`Error removing ${successMessage}`, error);
            return of(null);
          })
        );
      }
      return of(null);
    };

    const handleBusDeletion = (
      condition: boolean,
      busId: number,
      successMessage: string
    ): Observable<any> => {
      if (condition) {
        const url = `${OPN_BASE_URL}/trips-instance/remove-bus`;
        return this.crudService.remove(url, busId).pipe(
          tap(() => {
            console.log(`${successMessage} successfully`);
          }),
          catchError((error) => {
            console.error(`Error removing ${successMessage}`, error);
            return of(null);
          })
        );
      }
      return of(null);
    };

    // Process driver deletion
    deleteRequest$ = handleStaffDeletion(
      this.removeDriverChecked,
      this.selectedDriverId, // Ensure `selectedDriverId` is defined
      this.overlappingDriverMissionId, // Ensure `tripId` is defined
      "Driver removed"
    );

    // Process receiver deletion
    deleteRequest$ = deleteRequest$.pipe(
      switchMap(() =>
        handleStaffDeletion(
          this.removeReceiverChecked,
          this.selectedReceiverId, // Ensure `selectedReceiverId` is defined
          this.overlappingReceiverMissionId,
          "Receiver removed"
        )
      )
    );

    // Process bus deletion
    deleteRequest$ = deleteRequest$.pipe(
      switchMap(() =>
        handleBusDeletion(
          this.removeBusChecked,
          this.overlappingBussMissionId,
          "Bus removed"
        )
      )
    );

    // Execute the deletion chain and update the trip
    deleteRequest$.subscribe(() => {
      this.updateTrip();
      this.itemDeleted.emit();
    });
  }

  /**
   * Updates the trip instance with new data.
   */

  updateTrip() {
    const newTime: NgbTimeStruct =
      this.assignmenetForm.get("plannedDeparture").value;
    const oldDate = this.data.plannedDeparture;
    const newDateTime = this.concatenateDateAndTime(oldDate, newTime);

    const formData = {
      ...this.assignmenetForm.value,
      plannedDeparture: newDateTime,
    };

    this.crudService
      .update(OPN_BASE_URL + "/trips-instance/update", this.data.id, formData)

      .subscribe(
        (response) => {
          this.activeModal.close();
          this.notyService.displayNotification(
            "Trip updated successfully",
            "success"
          );
        },
        (error) => {
          console.error("Error updating trip:", error);
          this.notyService.displayNotification("Failed update", "error");
        }
      );
  }

  /**
   * Enables edit mode for the mission.
   */

  editMission() {
    this.isEditing = true;
    const departureTime = this.convertirISOenNgbTime(
      this.data.plannedDeparture
    );
    this.assignmenetForm.patchValue({
      id: this.data.id,
      plannedDeparture: departureTime,
      driver: { id: this.data.driver ? this.data.driver.id : "" },
      reciever: { id: this.data.reciever ? this.data.reciever.id : "" },
      bus: { id: this.data.bus ? this.data.bus.id : "" },
    });
  }

  /**
   * Converts an ISO string to an NgbTimeStruct.
   *
   * @param isoString The ISO string to convert.
   * @returns The converted NgbTimeStruct.
   */

  convertirISOenNgbTime(isoString: string): NgbTimeStruct {
    const date = new Date(isoString);
    return {
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
    };
  }

  /**
   * Initializes the time picker with the departure time.
   */

  initialiserTimePicker(): void {
    const isoString = this.data.plannedDeparture;
    this.assignmenetForm
      .get("plannedDeparture")
      .setValue(this.convertirISOenNgbTime(isoString));
  }

  /**
   * Concatenates a date string and a time struct into a single ISO datetime string.
   * @param dateString The date string.
   * @param time The time struct.
   * @returns The concatenated ISO datetime string.
   */

  concatenateDateAndTime(dateString: string, time: NgbTimeStruct): string {
    const date = new Date(dateString);
    date.setHours(time.hour, time.minute, time.second);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  /**
   * Checks the availability of a driver.
   * @param selectedDriverId The ID of the selected driver.
   */

  checkDriverAvailability(selectedDriverId: any): void {
    const url = OPN_BASE_URL + "/trips-instance/check-driver-availability";
    const departure = this.data.plannedDeparture;
    const arrival = this.data.estimatedArrival;

    this.crudService
      .checkDriverAvailability(
        url,
        this.data.id,
        selectedDriverId,
        departure,
        arrival
      )
      .subscribe((response) => {
        if (response.status === 200) {
          if (response.body === true) {
            this.overlappingDriverMission = null;
          }
        } else if (response.status === 209) {
          const overlappingMissions = response.body.map(
            (item: any) => item.lineDirection
          );
          this.overlappingDriverMission = overlappingMissions;
          const overlappingMissionsIds = response.body.map(
            (item: any) => item.id
          );
          this.overlappingDriverMissionId = overlappingMissionsIds;
        }
      });
  }

  /**
   * Checks the availability of a receiver.
   */

  checkReceiverAvailability(): void {
    this.selectedReceiverId = this.assignmenetForm.get("reciever.id").value;
    const url = OPN_BASE_URL + "/trips-instance/check-receiver-availability";

    if (this.selectedReceiverId && !this.selectedReceiverOnLeave) {
      const departure = this.data.plannedDeparture;
      const arrival = this.data.estimatedArrival;

      this.crudService
        .checkReceiverAvailability(
          url,
          this.data.id,
          this.selectedReceiverId,
          departure,
          arrival
        )
        .subscribe((response) => {
          if (response.status === 200) {
            if (response.body === true) {
              this.overlappingReceiverMission = null;
            }
          } else if (response.status === 209) {
            const overlappingReceiverMissions = response.body.map(
              (item: any) => item.lineDirection
            );
            this.overlappingReceiverMission = overlappingReceiverMissions;
            const overlappingReceiverMissionsIds = response.body.map(
              (item: any) => item.id
            );
            this.overlappingReceiverMissionId = overlappingReceiverMissionsIds;
          }
        });
    }
  }

  /**
   * Checks the availability of a bus.
   */

  checkBusAvailability(): void {
    this.selectedBusId = this.assignmenetForm.get("bus.id").value;
    const url = OPN_BASE_URL + "/trips-instance/check-bus-availability";

    if (this.selectedBusId) {
      const departure = this.data.plannedDeparture;
      const arrival = this.data.estimatedArrival;

      this.crudService
        .checkBusAvailability(
          url,
          this.data.id,
          this.selectedBusId,
          departure,
          arrival
        )
        .subscribe((response) => {
          if (response.status === 200) {
            if (response.body === true) {
              this.overlappingBusMission = null;
            }
          }
          if (response.status === 209) {
            const overlappingBusMissions = response.body.map(
              (item: any) => item.lineDirection
            );
            this.overlappingBusMission = overlappingBusMissions;
            const overlappingBussMissionIds = response.body.map(
              (item: any) => item.id
            );
            this.overlappingBussMissionId = overlappingBussMissionIds;
          }
        });
    }
  }

  /**
   * Resets the error messages related to receiver availability.
   */

  resetReceiverErrorMessages(): void {
    if (this.overlappingReceiverMission !== null) {
      this.overlappingReceiverMission = null;
    }
  }

  /**
   * Resets the error messages related to bus availability.
   */

  resetBusErrorMessages(): void {
    if (this.overlappingBusMission !== null) {
      this.overlappingBusMission = null;
    }
  }

  /**
   * Resets the error messages related to driver availability.
   */

  resetDriverErrorMessages(): void {
    if (this.overlappingDriverMission !== null) {
      this.overlappingDriverMission = null;
    }
  }

  /**
   * Handles the change event for the driver selection.
   * @param event The event object.
   */

  onDriverChange(selectedItem: any): void {
    if (selectedItem) {
      this.selectedDriverId = Number(selectedItem.id);
      this.selectedDriverOnLeave = this.driverOnLeave.some((driver) => {
        return driver.id === this.selectedDriverId;
      });

      if (!this.selectedDriverOnLeave) {
        this.checkDriverAvailability(this.selectedDriverId);
      }
      const selectedDriver = this.driver.find(
        (driver) => driver.id === this.selectedDriverId
      );

      if (selectedDriver && selectedDriver.defaultVehicleId) {
        const selectedVehicle = this.busList.find(
          (vehicle) => vehicle.id === selectedDriver.defaultVehicleId
        );

        if (selectedVehicle) {
          this.assignmenetForm.get("bus.id")?.setValue(selectedVehicle.id);
          this.checkBusAvailability();
        }
      }

      this.resetDriverErrorMessages();
    }
  }

  /**
   * Handles the change event for the receiver selection.
   * @param event The event object.
   */

  onReceiverChange(selectedItem: any): void {
    if (selectedItem) {
      this.selectedReceiverId = Number(selectedItem.id);
      this.selectedReceiverOnLeave = this.receiverOnLeave.some(
        (receiver) => receiver.id === this.selectedReceiverId
      );
      this.checkReceiverAvailability();
      this.resetReceiverErrorMessages();
    } else {
      this.selectedReceiverId = null;
      this.selectedReceiverOnLeave = false;
    }
  }

  /**
   * Handles the change event for the bus selection.
   * @param event The event object.
   */

  onBusChange(selectedItem: any): void {
    if (selectedItem) {
      this.selectedBusId = Number(selectedItem.id);
    }

    this.checkBusAvailability();
    this.resetBusErrorMessages();
  }

  /**
   * Cancels the mission instance using CRUD service.
   * @param msg The reason for cancelling the mission.
   */

  cancelMission(msg: string): void {
    this.crudService
      .cancelMission(OPN_BASE_URL + "/trips-instance/cancel", this.data.id, msg)
      .subscribe(
        (response) => {
          this.activeModal.close();
          this.itemCanceled.emit();
          this.notyService.displayNotification(
            "Trip Canceled successfully",
            "success"
          );
        },
        (error) => {
          console.error("Error Canceling trip:", error);
          this.notyService.displayNotification("Failed cancel", "error");
        }
      );
  }

  /**
   * Shows a confirmation dialog for deleting a mission instance.
   * Prompts the user to enter a reason and proceeds to cancel the mission if confirmed.
   */

  ConfirmText() {
    swal
      .fire({
        title: "Are you sure you want to canel this trip?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2F8BE6",
        cancelButtonColor: "#F55252",
        confirmButtonText: "Cancel Trip",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
        html: `
        <input type="text" id="input-field" class="swal2-input" placeholder="Enter a reason please">
      `,
        didOpen: () => {
          const inputField = document.getElementById(
            "input-field"
          ) as HTMLInputElement;
          const confirmButton = swal.getConfirmButton();
          confirmButton.disabled = true;

          inputField.addEventListener("input", () => {
            confirmButton.disabled = !inputField.value.trim();
          });
        },
        preConfirm: () => {
          const inputValue = (
            document.getElementById("input-field") as HTMLInputElement
          ).value;
          return { inputValue };
        },
      })
      .then((result) => {
        if (result.value) {
          const inputValue = result.value.inputValue;
          this.cancelMission(inputValue);
          swal.fire({
            icon: "success",
            title: "Canceled!",
            text: `Your trip has been canceled successfully`,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
  }

  /**
   * Cancels the editing process by resetting editing flags and overlapping mission states.
   */

  cancelEdit() {
    this.isEditing = false;
    this.overlappingDriverMission = null;
    this.selectedDriverOnLeave = false;
    this.overlappingReceiverMission = null;
    this.selectedReceiverOnLeave = false;
    this.overlappingBusMission = null;
  }

  closeModal() {
    this.activeModal.close();
    this.itemClosed.emit();
  }

  openReportModal(data: any) {
    const modalRef = this.modalService.open(TripReporterModalComponent, {
      size: "xl",
    });

    modalRef.componentInstance.data = data;

    modalRef.componentInstance.itemUpdated.subscribe((res) => {
      // Extract time from the date-time string
      const plannedDepartureDate = new Date(res.plannedDeparture);
      const time: NgbTimeStruct = {
        hour: plannedDepartureDate.getHours(),
        minute: plannedDepartureDate.getMinutes(),
        second: plannedDepartureDate.getSeconds(),
      };

      // Update the form with the extracted time
      this.assignmenetForm.patchValue({
        plannedDeparture: time,
      });
    });
  }

  fetchMissionLogs() {
    this.crudService
      .getAll<MissionLog[]>(
        `${OPN_BASE_URL}/mission-logs/mission/${this.data.id}`
      )
      .subscribe(
        (logs) => {
          this.missionLogs = logs.sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          );
        },
        (error) => {
          console.error("Error fetching mission logs:", error);
        }
      );
  }

  getInitials(name: string): string {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
  }

  getResponsableInitials(responsableName: string): string {
    if (responsableName.toLowerCase() === "system") {
      return "Sys";
    }
    const names = responsableName.split(" ");
    return (
      names.length > 1 ? names[0][0] + names[1][0] : names[0][0]
    ).toUpperCase();
  }

  getActionDescription(log: MissionLog): string {
    switch (log.actionType) {
      case ActionType.CREATION:
        return `a créé ce voyage`;
      case ActionType.AFFECTATION:
        return `a affecté ${log.fieldName}`;
      case ActionType.REPORTER:
        return `a reporté le voyage`;
      case ActionType.CANCELLED:
        return `a annulé le voyage`;
      case ActionType.INPROGRESS:
        return `a marqué le voyage comme en cours`;
      case ActionType.FINISHED:
        return `a terminé le voyage`;
      case ActionType.MODIFICATION:
        return `a modifié ${log.fieldName}`; // Removed oldValue and newValue here
      default:
        return `a effectué une action sur ${log.fieldName}`;
    }
  }

  formatDateTime(dateTime: string): string {
    const date = new Date(dateTime);
    return (
      date.toLocaleDateString("fr-FR", {
        day: "numeric",
        month: "long",
        year: "numeric",
      }) +
      " à " +
      date.toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })
    );
  }
  // formatDateTime(dateTime: string): string {
  //   const date = new Date(dateTime);

  //   // Get language preference from local storage
  //   const language = localStorage.getItem('langue') || 'en';

  //   // Determine the locale and format options based on the language
  //   const locale = language === 'fr' ? 'fr-FR' : 'en-US';
  //   const timeFormat = language === 'fr' ? ' à ' : ' at ';

  //   return date.toLocaleDateString(locale, { day: 'numeric', month: 'long', year: 'numeric' }) +
  //          timeFormat +
  //          date.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' });
  // }

  initMap() {
    this.crudService
      .getOne(OPN_BASE_URL + "/trip", this.data.idTrip)
      .subscribe((data: any) => {
        const trip = data;
        const itineraryId = trip.itineraryId;
        this.crudService
          .getOne(OPN_BASE_URL + "/itinerary", itineraryId)
          .subscribe((itinerary: any) => {
            if (itinerary) {
              this.itinerary = itinerary;
              this.stations = itinerary.itineraryStations;
            }
          });
      });
  }
}
