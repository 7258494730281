import { Directive,ElementRef } from '@angular/core';


@Directive({
  selector: '[appRefresh]'
})
export class RefreshDirectiveDirective {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.el.nativeElement.style.display = 'none';
      this.el.nativeElement.offsetHeight;
      this.el.nativeElement.style.display = 'block';
    }, 0);

    console.log('view refresh completed successfully');
  }
}
