import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { switchMap, map, mergeMap, filter } from 'rxjs/operators';
import { UsersService } from './users.service';
import { NOTIF_API, OPN_BASE_URL } from '../global/var';

interface FilterTags {
  centers: { id: number; name: string }[];
  agencies: { id: number; name: string }[];
}

export interface Inscription {
  id: number;
  sms: boolean;
  email: boolean;
  notification: boolean;
  user: any; 
  alert: {
    alertId: number;
    category: string;
    libelle: string;
    description: string;
  };
  filterTags: string;
}

interface WebSocketData {
  tripInstance_id: number;
  route_id: number;
  agency_id: number;
  center_id: number;
  station_id: number;
  bus_id: number;
  temps_retard: number;
  data: {
    vitesse: number;
    date: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private socket!: WebSocket;
  private inscriptions$: Subject<Inscription[]> = new Subject<Inscription[]>();
  private readonly websocketUrl = NOTIF_API;
  private messageSubject: Subject<string> = new Subject<string>();
  private isConnected = false;
  private lastConnectionTime: number | null = null;
  private readonly RECONNECTION_THRESHOLD = 3000000; 

  constructor(private http: HttpClient, private userservice: UsersService) {}

  public initializeConnection(username: string): void {
    const currentTime = new Date().getTime();
    
    if (!this.isConnected || (this.lastConnectionTime && (currentTime - this.lastConnectionTime > this.RECONNECTION_THRESHOLD))) {
      this.isConnected = true;
      this.lastConnectionTime = currentTime;
      this.connect(username).subscribe(message => {
        if (message) {
          this.messageSubject.next(message);
        }
      });
    }
  }

  public onMessage(): Observable<string> {
    return this.messageSubject.asObservable();
  }

  private connect(username: string): Observable<string | null> {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.close();
    }

    this.socket = new WebSocket(this.websocketUrl);
    const messageObservable = new Observable<MessageEvent>(observer => {
      this.socket.onmessage = observer.next.bind(observer);
      this.socket.onerror = observer.error.bind(observer);
      this.socket.onclose = observer.complete.bind(observer);
      return this.socket.close.bind(this.socket);
    });

    return this.getUserInscription(username).pipe(
      switchMap(inscriptions => {
        if (inscriptions.length === 0) {
          console.error('Aucune inscription trouvée pour utilisateur:', username);
          return of(null);
        }

        const inscription = inscriptions[0];
        const filterTags = this.parseFilterTags(inscription.filterTags);

        const centerIds = new Set(filterTags.centers.map(center => center.id.toString()));
        const agencyIds = new Set(filterTags.agencies.map(agency => agency.id.toString()));

        return messageObservable.pipe(
          map(event => this.parseWebSocketMessage(event.data)),
          filter(data => this.isRelevantMessage(data, centerIds, agencyIds)),
          mergeMap(data => this.formatMessage(data))
        );
      })
    );
  }

  private parseWebSocketMessage(message: string): WebSocketData | null {
    try {
      const cleanedData = this.cleanWebSocketMessage(message);
      return JSON.parse(cleanedData) as WebSocketData;
    } catch (e) {
      console.error("Erreur lors du parsing JSON :", e);
      return null;
    }
  }

  private isRelevantMessage(data: WebSocketData | null, centerIds: Set<string>, agencyIds: Set<string>): boolean {
    return data !== null && 
           data.center_id !== undefined && 
           data.agency_id !== undefined &&
           centerIds.has(data.center_id.toString()) && 
           agencyIds.has(data.agency_id.toString());
  }


   public disconnect(): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.close();
    }
    this.isConnected = false;
    this.lastConnectionTime = null;
  }
  
  private cleanWebSocketMessage(message: string): string {
      const firstBraceIndex = message.indexOf('{');
      const lastBraceIndex = message.lastIndexOf('}');
    
      if (firstBraceIndex !== -1 && lastBraceIndex !== -1) {
        const cleaned = message.substring(firstBraceIndex, lastBraceIndex + 1).trim();
    
        try {
          JSON.parse(cleaned); 
          return cleaned; 
        } catch (e) {
          console.error("Erreur lors de la validation du JSON :", e);
          return '{}';
        }
      }
    
      console.warn("Aucun JSON trouvé, retourne un objet vide");
      return '{}';
    }
    

  private parseFilterTags(filterTagsString: string): FilterTags {
    try {
      return JSON.parse(filterTagsString);
    } catch (e) {
      console.error("Erreur lors du parsing de filterTags:", e);
      return { centers: [], agencies: [] };
    }
  }

  private formatMessage(data: WebSocketData): Observable<string> {
    return this.http.get<any>(`${OPN_BASE_URL}/trips-instance/${data.tripInstance_id}`).pipe(
      mergeMap(tripInstance => {
        // Vérifiez si tripInstance est null
        const tripDirection = tripInstance ? tripInstance.lineDirection : 'inconnu';
        const tripRoute = tripInstance ? tripInstance.tripRoute : 'inconnu';
  
        return this.http.get<any>(`${OPN_BASE_URL}/stations/${data.station_id}`).pipe(
          mergeMap(station => {
            // Vérifiez si station est null
            const stationName = station ? station.name : 'inconnu';
  
            return this.http.get<any>(`${OPN_BASE_URL}/agency/${data.agency_id}`).pipe(
              map(agency => {
                const agencyName = agency ? agency.name : 'inconnu';
                return `Il y a un retard de ${data.temps_retard} minutes sur la ligne ${tripDirection} pour la route (${tripRoute}) à la station ${stationName}. Ce trajet appartient à l'agence ${agencyName}.`;
              })
            );
          })
        );
      })
    );
  }
   public send(data: Object): void {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(data));
    }
  }

  private getUserInscription(username: string): Observable<Inscription[]> {
    return this.userservice.getLocalUserInscriptionByUsername(username);
  }
}