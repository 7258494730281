<section class="cd-horizontal-timeline" [ngClass]="{'loaded': loaded}">
  <div class="timeline">
    <div class="events-wrapper">
      <div class="events" #eventsWrapper [style.width.px]="eventsWrapperWidth">
        <ol>
          <li *ngFor="let item of timelineElements; let index = index">
            <a #timelineEvents >{{item.title}}<br/>{{item.date_diff}}</a>
            <span></span>
          </li>
        </ol>
        <span class="filling-line" aria-hidden="true" #fillingLine></span>
      </div>
    </div>

    <ul class="cd-timeline-navigation">
      <li>
        <a href="#" (click)="onScrollClick($event, false)" class="prev" [ngClass]="{'inactive':prevLinkInactive}">Prev</a>
      </li>
      <li>
        <a href="#" (click)="onScrollClick($event, true)" class="next" [ngClass]="{'inactive':nextLinkInactive}">Next</a>
      </li>
    </ul>
  </div>

  <!-- <div class="events-content" *ngIf="showContent">
    <ol>
      <li *ngFor="let item of timelineElements; let index = index" [@contentState]="item.selected ? 'active' : (index < selectedIndex ? 'left' : 'right')">
        <h1 class="text-bold-700 mb-2">{{item.title}}</h1>
        <h4 class="mb-2">{{item.date | date:dateFormat}}</h4>
        <p>{{item.content}}</p>
      </li>
    </ol>
  </div> -->
</section>
