import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss', "../../../../assets/sass/libs/datepicker.scss"]
})
export class DateFilterComponent implements OnInit {

  @Output() dateSelected: EventEmitter<{ selectedDate: string }> = new EventEmitter();

  selectedDate = moment().format("YYYY-MM-DD");
  showCalendar: boolean = false;
  hoveredDate: NgbDateStruct;
  pickedDate: NgbDateStruct;
  model: NgbDateStruct;
  date: { year: number; month: number };
  displayMonths = 1;
  navigation = "select";
  disabled = true;

  constructor() {
    this.pickedDate = this.toNgbDate(new Date());
  }
  


  ngOnInit() {
    this.dateSelected.emit({
      selectedDate: moment().format("YYYY-MM-DD"),
    });
  }
  
  areDatesEqual(date1: NgbDateStruct, date2: NgbDateStruct): boolean {
    return date1 && date2 && date1.year === date2.year && date1.month === date2.month && date1.day === date2.day;
  }

  isDisabled(date: NgbDateStruct, current: { month: number }) {
    return date.month !== current.month;
  }

  toNgbDate(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  formatDate(date: NgbDateStruct): string {
    if (!date) {
      return "";
    }
    const formattedDate = moment({
      year: date.year,
      month: date.month - 1,
      day: date.day,
    }).format("MM/DD/YYYY");

    return formattedDate;
  }

  onDateChange(date: NgbDateStruct) {
    this.pickedDate = date;
    const formattedPickedDate = this.pickedDate
      ? moment({
          year: this.pickedDate.year,
          month: this.pickedDate.month - 1,
          day: this.pickedDate.day,
        }).format("YYYY-MM-DD")
      : "";

    if (formattedPickedDate != null) {
      this.dateSelected.emit({
        selectedDate: formattedPickedDate,
      });
    }
  }

  isHovered = (date) =>
  this.pickedDate && this.hoveredDate && this.areDatesEqual(this.hoveredDate, date);
  isFrom = (date) => this.pickedDate && this.areDatesEqual(date, this.pickedDate);
  isTo = (date) => this.hoveredDate && this.areDatesEqual(date, this.hoveredDate);


  openCalendar() {
    this.showCalendar = true;
  }

  selectToday() {
    this.model = {
      year: moment().year(),
      month: moment().month() + 1,
      day: moment().date(),
    };
  }
}
