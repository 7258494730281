import { Component, Input, OnInit } from "@angular/core";
import { Station } from "app/modules/operating-networks/stations-management/models/stations.model";
import { OPN_BASE_URL } from "app/shared/global/var";
import { CrudService } from "app/shared/services/crud.service";

@Component({
  selector: "app-trip-deviation",
  templateUrl: "./trip-deviation.component.html",
  styleUrls: ["./trip-deviation.component.scss"],
})
export class TripDeviationComponent implements OnInit {
  @Input() itinerary: any;
  @Input() trip: any;

  stations: Array<Station> = [];

  constructor(private crudService: CrudService) {}

  ngOnInit(): void {
    if (this.itinerary) {
      this.stations = this.itinerary.itineraryStations.map((station: any) => {
        return {
          id: station.station.id,
          name: station.station.name,
          distance: station.distance,
          lat: +station.station.lat,
          lon: +station.station.lon,
          duration: station.duration,
          stoppingTime: station.stoppingTime,
          stationOrder: station.stationOrder,
          visited: false,
        };
      });

      // Order stations by stationOrder
      this.stations.sort((a, b) => a.distance - b.distance);
      // each station distance is the sum of all previous station distances
      this.stations.forEach((station, index) => {
        station.distance = this.stations
          .slice(0, index + 1)
          .reduce((acc, s) => acc + s.distance, 0);
      });
      this.stations[0].visited = true;
      this.getStopTime();
    }
  }

  getStopTime(): any {
    this.crudService
      .getAll(OPN_BASE_URL + `/stop-times/trips-instance/${this.trip.id}`)
      .subscribe((res: any) => {
        this.stations.forEach((station) => {
          const itinereyStation = this.itinerary.itineraryStations.find(
            (itineraryStation: { station: { id: number } }) =>
              itineraryStation.station.id === station.id
          ).id;
          this.crudService
            .getAll(
              OPN_BASE_URL +
                `/stop-times/itinerary-station/${itinereyStation}/trips-instance/${this.trip.id}`
            )
            .subscribe((r: any) => {
              const stopTime = r;
              if (stopTime) {
                station.visited = stopTime.rtArrivalTime !== null;
                station.rtArrivalTime = stopTime.rtArrivalTime;
                station.estimatedDepartureTime = stopTime.departureTime;
                station.estimatedArrivalTime = stopTime.arrivalTime;
                station.rtDepartureTime = stopTime.rtDepartureTime;
                if (station.visited) {
                  station.deviation =
                    Math.round(
                      new Date(station.rtArrivalTime).getTime() -
                        new Date(station.estimatedArrivalTime).getTime()
                    ) / 60000;
                }
              }
            });
        });
      });
  }
}
