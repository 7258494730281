<div class="modal-header">
  <h4 class="modal-title">Ajouter permission à "{{ resource }}"</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="permissionForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="roleName">Nom de la permission *</label>
      <input type="text" class="form-control" id="roleName" formControlName="roleName">
    </div>
    <div class="form-group">
      <label for="roleCode">CODE</label>
      <input type="text" class="form-control" id="roleCode" formControlName="roleCode" readonly>
    </div>
    <div class="form-group">
      <label for="roleRoute">route (api)</label>
      <input type="text" class="form-control" id="roleRoute" formControlName="roleRoute" readonly>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Annuler</button>
      <button type="submit" class="btn btn-success">Ajouter</button>
    </div>
  </form>
</div>
