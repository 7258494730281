import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RealTimeTrackingRoutingModule } from "./real-time-tracking-routing.module";
import { VehiclesListTrackingComponent } from "./vehicles-list-tracking/vehicles-list-tracking.component";
import { FormsModule } from "@angular/forms";
import { VehicleTrackingComponent } from "./vehicle-tracking/vehicle-tracking.component";
import { RealTimeMapComponent } from "./real-time-map/real-time-map.component";
import { SearchPipe } from "./vehicles-list-tracking/search.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { TripProgressComponent } from "./trip-progress/trip-progress.component";
import { TripDeviationComponent } from "./trip-deviation/trip-deviation.component";
import { NzTableModule } from "ng-zorro-antd/table";
import { PipeModule } from "app/shared/pipes/pipe.module";

@NgModule({
  declarations: [
    VehiclesListTrackingComponent,
    VehicleTrackingComponent,
    RealTimeMapComponent,
    SearchPipe,
    TripProgressComponent,
    TripDeviationComponent,
  ],
  exports: [
    RealTimeMapComponent,
    TripProgressComponent,
    TripDeviationComponent,
  ],
  imports: [
    CommonModule,
    RealTimeTrackingRoutingModule,
    FormsModule,
    PipeModule,
    TranslateModule,
    NzTableModule,
  ],
})
export class RealTimeTrackingModule {}
