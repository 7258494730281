import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { BASE_WS_MAINTENANCE } from "../global/var";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private socket$: WebSocketSubject<any>;
  private messageSubject: Subject<string> = new Subject<string>();

  constructor() {}

  connect(): void {
    const wsUrl = BASE_WS_MAINTENANCE;
    this.socket$ = new WebSocketSubject(wsUrl);

    this.socket$.subscribe(
      (message) => this.onMessage(message),
      (err) => this.onError(err),
      () => this.onClose()
    );
  }

  getMessages(): Observable<any> {
    console.log;
    return this.messageSubject.asObservable();
  }

  sendMessage(message: string): void {
    if (this.socket$) {
      this.socket$.next(message);
    }
  }

  closeConnection(): void {
    if (this.socket$) {
      this.socket$.complete();
    }
  }

  private onMessage(message: any): void {
    this.messageSubject.next(message); // Emit the message to the component
  }

  private onError(err: any): void {
    console.error("WebSocket error:", err);
  }

  private onClose(): void {
    console.log("WebSocket connection closed");
  }
}
