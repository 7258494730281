<div class="modal-header">
    <h4 class="modal-title">{{"EXPORT-AFFECTATION"|translate}}</h4>
</div>
<div class="modal-body">
    <form [formGroup]="exportForm">
        <ng-container *ngIf="viewState === 'form'">
            <div class="row">
                <div class="col-xl-3 col-lg-6 col-12">
                    <div class="card card-inverse color bg-warning bg-lighten-1">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="media">
                                    <div class="media-body text-left">
                                        <h3 class="card-text">{{nbrOfTrips}}</h3>
                                        <span>{{'TRIPS'|translate}}</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-12">
                    <div class="card card-inverse bg-success bg-lighten-1">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="media">
                                    <div class="media-body text-left">
                                        <h3 class="card-text">{{nbrOfDays}}</h3>
                                        <span>{{'DAYS'|translate}}</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-12">
                    <div class="card card-inverse bg-danger bg-lighten-1">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="media">
                                    <div class="media-body text-left">
                                        <h3 class="card-text">{{nbrOfDrivers}}</h3>
                                        <span>{{'CHAUFFEUR'|translate}}</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-12">
                    <div class="card card-inverse bg-primary bg-lighten-1">
                        <div class="card-content">
                            <div class="card-body">
                                <div class="media">
                                    <div class="media-body text-left">
                                        <h3 class="card-text">{{nbrOfLines}}</h3>
                                        <span>{{'LIGNE'|translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="ml-1 row text-bold-600">{{"EXPORT-FROM"| translate}} </div>
            <div class="row col-8 mt-3">


                <label for="validationTooltip03" class="col-3">{{"FROM" | translate}}</label>
                <div class="input-group col-5">
                    <input class="form-control" id="validationTooltip03" placeholder="yyyy-mm-dd" ngbDatepicker
                        #d="ngbDatepicker" formControlName="startDate">
                    <div class="input-group-append">
                        <div class="input-group-text" (click)="d.toggle()">
                            <em class="fa fa-calendar" style="cursor: pointer;"></em>
                        </div>
                    </div>


                </div>
                <span class="card-text mt-1">{{nbrOfDays}}<span>{{'DAYS'| translate}}</span></span>

            </div>



            <div class="mt-3 alert alert-light d-flex align-items-center">
                <i *ngIf="sameSchedule == true" class="fa-solid fa-check text-success font-weight-bold mr-2"></i>
                <i *ngIf="sameSchedule == false" class="fa-solid fa-x text-danger font-weight-bold mr-2"></i>
                <p class="text-bold-600 mb-0">{{ "SAME-SCHEDULE" | translate }}</p>
            </div>

            <div *ngIf="missingInstances">
            <div *ngIf="!sameSchedule && missingInstances.length > 0" class="alert alert-light mr-1 ml-1">
                <span>{{ "MISSING_INSTANCES_MSG" | translate }}</span>
                <ul>
                    <li *ngFor="let instance of missingInstances" class="text-danger">
                        {{ instance.lineDirection }}
                    </li>
                </ul>
            </div>
        </div>

        </ng-container>


        <ng-container *ngIf="viewState === 'review'">

            <div *ngFor="let group of groupedInstances; let i = index">


                <div (click)="toggleCollapse(i)">
                    <span class="d-flex align-items-center text-dark font-weight-bold">
                        <i class="fa"
                            [ngClass]="{'fa-chevron-down': !group.isCollapsed, 'fa-chevron-right': group.isCollapsed}"></i>
                        <span class="ml-2">{{ getDayOfWeek(group.date) }} {{ group.date }} ({{ group.instances.length }}
                            voyages)</span>
                    </span>
                </div>
                <div [ngbCollapse]="group.isCollapsed">
                    <div class="card-body">
                        <div class="bg-grey bg-lighten-4 rounded p-3 mb-3">
                            <div class="row">

                                <span class="col-1 text-bold-600"># {{ 'LINE' | translate }}</span>
                                <span class="col-2 text-bold-600">{{ 'TRIP' | translate }}</span>
                                <span class="col-2 text-bold-600">{{ 'DEPARTURE' | translate }}</span>
                                <span class="col-2 text-bold-600">{{ 'DRIVER-NUM' | translate }}</span>
                                <span class="col-2 text-bold-600">{{ 'BUS-NUM' | translate }}</span>
                                <span class="col-2 text-bold-600">{{ 'RECEIVER-NUM' | translate }}</span>
                            </div>
                        </div>

                        <div class="row" *ngFor="let trip of group.instances">
                            <!-- Le contenu existant pour chaque voyage -->
                            <div class="col-md-12 col-12 mb-3 mb-md-0">
                                <ul class="list-group">
                                    <li class="list-group-item">

                                        <div class="row">
                                            <span class=" col-1">{{ trip.lineNumber }}</span>
                                            <span class="col-2">{{ trip.lineDirection }}</span>
                                            <span class=" col-2">{{ trip.plannedDeparture }}</span>

                                            <span class="col-2">
                                                {{ trip.driver?.matricule ? trip.driver.matricule : '-' }}
                                                <i *ngIf="trip.driver?.matricule && !trip.removedDriver"
                                                    class="fa fa-trash text-danger ml-2"
                                                    (click)="removeDriver(trip)"></i>
                                                <i *ngIf="trip.removedDriver" class="fa fa-undo text-success ml-2"
                                                    (click)="undoRemoveDriver(trip)"></i>
                                            </span>

                                            <!-- Bus -->
                                            <span class="col-2">
                                                {{ trip.bus?.vehicleNumber ? trip.bus.vehicleNumber : '-' }}
                                                <i *ngIf="trip.bus?.vehicleNumber && !trip.removedBus"
                                                    class="fa fa-trash text-danger ml-2" (click)="removeBus(trip)"></i>
                                                <i *ngIf="trip.removedBus" class="fa fa-undo text-success ml-2"
                                                    (click)="undoRemoveBus(trip)"></i>
                                            </span>

                                            <!-- Receiver -->
                                            <span class="col-2">
                                                {{ trip.reciever?.matricule ? trip.reciever.matricule : '-' }}
                                                <i *ngIf="trip.reciever?.matricule && !trip.removedReceiver"
                                                    class="fa fa-trash text-danger ml-2"
                                                    (click)="removeReceiver(trip)"></i>
                                                <i *ngIf="trip.removedReceiver" class="fa fa-undo text-success ml-2"
                                                    (click)="undoRemoveReceiver(trip)"></i>
                                            </span>
                                        </div>

                                    </li>
                                </ul>
                            </div>
                            <!-- ... -->
                        </div>
                    </div>
                </div>

            </div>
        </ng-container>


        <ng-container *ngIf="viewState === 'export'">
            <div *ngIf="isLoading" class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div *ngIf="!isLoading">
                <div *ngFor="let group of groupedInstances; let i = index">

                    <div (click)="toggleCollapse(i)">
                        <span class="d-flex align-items-center text-dark font-weight-bold">
                            <i class="fa"
                                [ngClass]="{'fa-chevron-down': !group.isCollapsed, 'fa-chevron-right': group.isCollapsed}"></i>
                            <span class="ml-2">{{ getDayOfWeek(group.date) }} {{ group.date }} ({{
                                group.instances.length }} {{ 'TRIP' | translate }})</span>
                        </span>
                    </div>
                    <div [ngbCollapse]="group.isCollapsed">
                        <div class="card-body">
                            <div class="row m-0">
                                <div *ngFor="let trip of group.instances" class="col-md-3 p-1">
                                    <div class="mission-item-w border border-1 bg-lighten-4 rounded">
                                        <div class="row m-0">
                                            <div class="col-4 p-1 d-flex align-content-center flex-wrap">
                                                <span class="badge badge-pill bg-success mr-3">{{ trip.lineDirection
                                                    }}</span>
                                            </div>
                                            <div class="col-8 p-1 d-flex align-content-center flex-wrap">
                                                <div class="text-bold-600 text-secondary">{{ trip.plannedDeparture |
                                                    slice:11:16 }}</div>
                                                <div class="text-secondary">{{ trip.tripRoute }}</div>
                                            </div>
                                        </div>
                                        <div class="row m-0 bg-dark rounded-bottom">
                                            <div class="col p-1">
                                                <div class="badge badge-secondary p-1 mr-1" [ngClass]="{
                                                    'bg-danger': trip.driverConflict,
                                                    'bg-success': !trip.driverConflict && !trip.driverDayOff,
                                                    'bg-warning': !trip.driverConflict && trip.driverDayOff
                                                }">
                                                    <i *ngIf="!trip.driverConflict" class="fa-solid fa-check"></i>
                                                    <i *ngIf="trip.driverConflict" class="fa-solid fa-x"></i>
                                                    <img src="./assets/img/icons/driver.png" class="mx-1 icon-small">
                                                    <div class="ml-1 row">
                                                        <input *ngIf="trip.driverConflict" class="col-auto"
                                                            type="checkbox" id="export-driver"
                                                            [checked]="trip.overrideDriver"
                                                            (change)="toggleOverrideDriver(trip, $event.target.checked)">
                                                    </div>
                                                    <div class="ml-1 row">
                                                        <input *ngIf="!trip.driverConflict && trip.driverDayOff"
                                                            class="col-auto" type="checkbox" id="dayOff-driver"
                                                            [checked]="trip.overrideDriverDayOff"
                                                            (change)="toggleOverrideDriverDayOff(trip, $event.target.checked)">
                                                    </div>
                                                </div>

                                                <!-- Receiver Section -->
                                                <div class="badge badge-secondary p-1 mr-1" [ngClass]="{
                                                    'bg-danger': trip.receiverConflict,
                                                    'bg-success': !trip.receiverConflict && !trip.receiverDayOff,
                                                    'bg-warning': !trip.receiverConflict && trip.receiverDayOff
                                                }">
                                                    <i *ngIf="!trip.receiverConflict" class="fa-solid fa-check"></i>
                                                    <i *ngIf="trip.receiverConflict" class="fa-solid fa-x"></i>
                                                    <img src="./assets/img/icons/cashier.png" class="mx-1 icon-small">
                                                    <input *ngIf="trip.receiverConflict" class="col-auto"
                                                        type="checkbox" id="export-receiver"
                                                        [checked]="trip.overrideReceiver"
                                                        (change)="toggleOverrideReceiver(trip, $event.target.checked)">
                                                    <input *ngIf="!trip.receiverConflict && trip.receiverDayOff"
                                                        class="col-auto" type="checkbox" id="dayOff-receiver"
                                                        [checked]="trip.overrideReceiverDayOff"
                                                        (change)="toggleOverrideReceiverDayOff(trip, $event.target.checked)">
                                                </div>

                                                <!-- Bus Section (unchanged) -->
                                                <div class="badge badge-secondary p-1 mr-1" [ngClass]="{
                                                    'bg-danger': trip.busConflict,
                                                    'bg-success': !trip.busConflict
                                                }">
                                                    <div>
                                                        <i *ngIf="!trip.busConflict" class="fa-solid fa-check"></i>
                                                        <i *ngIf="trip.busConflict" class="fa-solid fa-x"></i>
                                                        <img src="./assets/img/icons/bus.png" class="mx-1 icon-small">
                                                        <input *ngIf="trip.busConflict" class="col-auto" type="checkbox"
                                                            id="export-bus" [checked]="trip.overrideBus"
                                                            (change)="toggleOverrideBus(trip, $event.target.checked)">
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

    </form>
    <div class="modal-footer">


        <!-- Bouton RETURN pour retourner de 'export' à 'review' -->
        <button *ngIf="viewState === 'export'" type="button" class="btn btn-secondary" (click)="returnToPrevious()">
            {{ 'RETURN' | translate }}
        </button>

        <!-- Bouton RETURN pour retourner de 'review' à 'form' -->
        <button *ngIf="viewState === 'review'" type="button" class="btn btn-secondary" (click)="returnToPrevious()">
            {{ 'RETURN' | translate }}
        </button>

        <button *ngIf="viewState === 'form' " type="button" class="btn btn-primary" (click)="onNextClicked()"
            [disabled]="isNextDisabled">
            {{ 'NEXT' | translate }}
        </button>

        <!-- Bouton NEXT pour passer de 'review' à 'export' -->
        <button *ngIf="viewState === 'review'" type="button" class="btn btn-primary" (click)="onNextClicked()">
            {{ 'NEXT' | translate }}
        </button>


        <button (click)="onSave()" *ngIf="viewState === 'export'" class="btn btn-primary">Save</button>
        <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">{{"Close" |
            translate}}</button>
    </div>